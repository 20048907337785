import React from 'react';

import Footer from '../../components/templates/homePageSections/footer/Footer';
import RegistrationAppTemplate from '../../components/templates/registrationAppTemplate/RegistrationAppTemplate';
import HeaderSection from '../../components/organism/headerSection/HeaderSection';
import useDetectScrolling from '../../app/hooks/useDetectSection';

function RegistrationApp() {
  return (
    <>
      <HeaderSection isGreen />
      <RegistrationAppTemplate />
      <Footer />
    </>
  );
}

export default RegistrationApp;
