import React, { useEffect, useState } from 'react';
import styles from './VerticalSlider.module.scss';
import Avatar from '../../atoms/avatar/Avatar';
import Text from '../../atoms/text/Text';
import { useTranslation } from 'react-i18next';

const VerticalSlider = ({
  slides,
  components,
  currentSlide,
  onSlideChange,
  isCustom,
  scrollEnabled,
  titleClassname,
}) => {
  const [currentSlideIndex, setInternalSlideIndex] = useState(0);
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const [canScroll, setCanScroll] = useState(true);
  const [touchStartY, setTouchStartY] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  useEffect(() => {
    if (currentSlide !== undefined) {
      setInternalSlideIndex(currentSlide);
    }
  }, [currentSlide]);

  const handleAvatarClick = (index) => {
    setInternalSlideIndex(index);
    onSlideChange && onSlideChange(index);
  };

  const handleTouchStart = (e) => {
    if (!isSwiping) {
      setTouchStartY(e.touches[0].clientY);
      setIsSwiping(true);
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
  };

  const handleTouchEnd = (e) => {
    if (!isSwiping) return;

    const touchEndY = e.changedTouches[0].clientY;
    const deltaY = touchStartY - touchEndY;

    if (Math.abs(deltaY) > 50) {
      const newIndex =
        deltaY > 0
          ? Math.min(currentSlideIndex + 1, components.length - 1)
          : Math.max(currentSlideIndex - 1, 0);

      setInternalSlideIndex(newIndex);
      onSlideChange && onSlideChange(newIndex);

      setTimeout(() => setIsSwiping(false), 1000);
    } else {
      setIsSwiping(false);
    }
  };

  useEffect(() => {
    const handleWheel = (e) => {
      if (!scrollEnabled || !canScroll || window.disableScroll || isSwiping)
        return;

      e.preventDefault();
      const { deltaY } = e;
      setCanScroll(false);
      setTimeout(() => {
        setCanScroll(true);
        setIsSwiping(false);
      }, 1000);
      const newIndex =
        deltaY > 0
          ? Math.min(currentSlideIndex + 1, components.length - 1)
          : Math.max(currentSlideIndex - 1, 0);

      setInternalSlideIndex(newIndex);
      onSlideChange && onSlideChange(newIndex);
    };

    if (scrollEnabled) {
      window.addEventListener('wheel', handleWheel, { passive: false });
      window.addEventListener('touchstart', handleTouchStart, {
        passive: false,
      });
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [
    scrollEnabled,
    currentSlideIndex,
    components.length,
    onSlideChange,
    canScroll,
    isSwiping,
  ]);

  return (
    <div className={styles['slider-container']}>
      <div
        className={`${styles['controls']} ${isRtl ? styles['avatar-rtl'] : ''}`}
      >
        {!isCustom ? (
          <Avatar
            images={slides ?? []}
            onAvatarClick={handleAvatarClick}
            currentSlideIndex={currentSlideIndex}
          />
        ) : null}
      </div>
      <div
        className={`${styles['title-container']} ${
          isRtl
            ? `${styles['title-container-rtl']} rtl`
            : `${titleClassname ?? ''}`
        }`}
      >
        {!isCustom ? (
          <Text
            title="home.testimonial"
            variant="text-bold-italic-50"
            style={{
              textTransform: 'uppercase',
            }}
            className={styles.title}
            isLineBreak
          />
        ) : null}
      </div>
      <div className={styles['slider']}>
        <div
          className={styles['slider-track']}
          style={{
            transform: `translateY(-${currentSlideIndex * 100}vh)`,
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {components?.map((Component, index) => (
            <div
              key={index}
              className={`${styles['slider-slide']} ${
                index === currentSlideIndex ? styles['active'] : ''
              }`}
            >
              {Component}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalSlider;
