import React, { useState, useEffect, useLayoutEffect } from 'react';
import styles from './BottomNavigationMobile.module.scss';
import NavigationButton from '../../../atoms/navigationButton/NavigationButton';
import Button from '../../../atoms/button/Button';
import { setApplyNowState } from '../../../../app/globals/slice/applyNowModalSlice/ApplyNowSlice';
import { useDispatch } from 'react-redux';
import Text from '../../../atoms/text/Text';
import navArrow from '../../../../assets/icons/navArrowRotated.svg';
import { useTranslation } from 'react-i18next';

const BottomNavigationMobile = ({
  isApplyNow,
  data,
  onClick,
  active,
  isHomepage,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [activeButton, setActiveButton] = useState(active);
  const [expanded, setExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const dispatch = useDispatch();

  const handleButtonClick = (id) => {
    if (onClick) {
      setActiveButton(id);
      onClick(id);
      setExpanded(false);
    }
  };

  const handleApplyNowClick = () => {
    dispatch(setApplyNowState(true));
  };

  const toggleMenu = () => {
    setExpanded(!expanded);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={`${styles['container-mobile']} ${
        expanded ? styles['expanded'] : ''
      } ${isVisible ? 'visible' : ''}`}
    >
      <div className={styles.header}>
        <div
          className={`${styles['header-button']} ${
            currentLanguage === 'ar' ? `${styles.marginRight} rtl` : ''
          }`}
          onClick={toggleMenu}
        >
          <img src={navArrow} className={expanded ? styles.rotate : ''} />
          <Text
            title="common.menu"
            variant="text-bold-italic-30"
            style={{
              textTransform: 'uppercase',
              marginTop: '10px',
            }}
          />
        </div>
      </div>

      <div className={styles['navigations-container']}>
        <div className={styles['navigations']}>
          {data?.map((item, index) => (
            <NavigationButton
              index={index}
              key={index}
              item={item}
              id={item?.id}
              label={item.label}
              isbordered={item.isbordered}
              to={item.route ?? ''}
              variant="text-bold-italic-30"
              onClick={handleButtonClick}
              activeButton={activeButton}
              capitalised
              withRightBorder
              isMobile={true}
              isHomepage={isHomepage}
            />
          ))}
        </div>

        {isApplyNow ? (
          <div className={styles['button-container']}>
            <Button
              title="common.Apply_Now"
              className={styles['button']}
              variant="text-bold-italic-25"
              onClick={() => {
                window.open(
                  'https://dubaipolice.ac.ae/dpacademy/ar/std_login.jsp',
                  '_blank'
                );
              }}
              styleType="primary"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BottomNavigationMobile;
