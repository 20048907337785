import { createSlice } from '@reduxjs/toolkit';

const sideModalSlice = createSlice({
  name: 'sideModal',
  initialState: {
    navigationBar: false,
    access: false,
    search: false,
    filter: false,
  },
  reducers: {
    toggleNavigationBar: (state) => {
      state.navigationBar = !state.navigationBar;
      state.access = false;
      state.search = false;
      state.filter = false;
    },
    toggleAccessModal: (state) => {
      state.access = !state.access;
      state.navigationBar = false;
      state.search = false;
      state.filter = false;
    },
    toggleSearchModal: (state) => {
      state.search = !state.search;
      state.navigationBar = false;
      state.access = false;
      state.filter = false;
    },

    toggleFilterModal: (state) => {
      state.filter = !state.filter;
      state.navigationBar = false;
      state.access = false;
      state.search = false;
    },

    closeAllModals: (state) => {
      state.navigationBar = false;
      state.access = false;
      state.search = false;
      state.filter = false;
    },
  },
});
export const isAnyModalOpen = (state) => {
  return (
    state.sideModal.navigationBar ||
    state.sideModal.access ||
    state.sideModal.search ||
    state.sideModal.filter
  );
};
export const {
  toggleNavigationBar,
  toggleAccessModal,
  toggleSearchModal,
  toggleFilterModal,
  closeAllModals,
} = sideModalSlice.actions;
export default sideModalSlice.reducer;
