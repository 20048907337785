import React, { useEffect, useState } from 'react';
import styles from './ImageCarousel.module.scss';
import Carousel from '../../molecule/carousel/Carousel';
import { useGetQuery } from '../../../services/apiCall';
import endpoints from '../../../api/endpoints';
import { useTranslation } from 'react-i18next';
import ProfileOne from '../../templates/aboutTheAcademyTemplate/outlet/overviewTemplate/Profile/Profile';
import TestimonialCard from '../../molecule/testimonialCard/TestimonialCard';
import defaultImage from './image.png';
function ImageCarousel({
  dotsClassname,
  carrouselClassname,
  slideClassName,
  keyApi,
  localData,
  type = 1,
  showData = true,
  activeDotClass,
  reversed,
}) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const apiUrl = type === 1 ? endpoints.testimonials.get : endpoints.speech.get;

  const {
    data: fetchedData,
    error,
    isLoading,
    refetch,
  } = useGetQuery(
    {
      apiUrl: apiUrl,
      params: [keyApi],
    },
    { skip: !!localData }
  );

  const carouselData = localData || fetchedData;

  const carouselComponents = carouselData?.map((item, index) => (
    // <ProfileOne
    //   key={item.testimonialId}
    //   title={currentLanguage === 'en' ? item.fullName_EN : item.fullName_AR}
    //   description={
    //     currentLanguage === 'en' ? item.testimonial_En : item.testimonial_AR
    //   }
    //   isImageBackground={true}
    //   bgImage={item.url + item.testimonialImage}
    //   isTestimonial={item.isTestimonial}
    //   footerText={currentLanguage === 'en' ? item.messageEN : item.messageAR}
    //   currentLanguage={currentLanguage}
    //   date={item.testimonialDate}
    //   isRtl={isRtl}
    //   showData={showData}
    // />
    <TestimonialCard
      key={item.testimonialId}
      title={currentLanguage === 'en' ? item.fullName_EN : item.fullName_AR}
      description={
        currentLanguage === 'en' ? item.testimonial_En : item.testimonial_AR
      }
      isImageBackground={true}
      bgImage={
        item.testimonialImage ? item.url + item.testimonialImage : defaultImage
      }
      isTestimonial={false}
      footerText={currentLanguage === 'en' ? item.messageEN : item.messageAR}
      currentLanguage={currentLanguage}
      date={item.testimonialDate}
      isRtl={isRtl}
      showData={showData}
      reversed={reversed}
    />
  ));

  if (carouselData?.length) {
    return (
      <div className={styles['profile-carousel']}>
        <Carousel
          className={`${styles.carousel}${carrouselClassname ?? ''}`}
          dots={dotsClassname}
          style={{ height: '100%', direction: isRtl ? 'ltr' : 'ltr' }}
          components={carouselComponents}
          hasArrows={false}
          startUpIndex={isRtl ? carouselData?.length - 1 : 0}
          slideClassName={slideClassName ?? styles.slides}
          activeDotClass={activeDotClass}
        />
      </div>
    );
  }

  return null;
}

export default ImageCarousel;
