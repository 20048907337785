import React from 'react';
import styles from './ImageWithTitles.module.scss';
import Text from '../../atoms/text/Text';
import dummy from '../../../assets/images/campusLandscape.png';
import InnerButton from '../../atoms/innerButton/innerButton';
import ImageWithBackdrop from '../imageWithBackdrop/ImageWithBackdrop';
import { useNavigate } from 'react-router-dom';

const ImageWithTitles = ({ imageUrl, title, subtitle, url }) => {
  const navigate = useNavigate();
  return (
    <div className={styles['image-with-titles']}>
      <Text
        title="Admission.SCHOLARSHIP_AND_FINANCIAL_AID_title"
        variant="text-bold-italic-25-green"
        isLineBreak
      />
      <div className={styles['container']}>
        <ImageWithBackdrop
          src={imageUrl ?? dummy}
          alt="Contact"
          backdropStyle={{ zIndex: -2, borderRadius: '10px' }}
          imageStyle={{ borderRadius: '10px' }}
        />
        <div className={styles['title-container']}>
          <div className={styles.titles}>
            <Text
              title={title}
              variant="text-bold-italic-16"
              className={styles['text-title']}
            />

            <Text
              title={subtitle}
              variant="text-bold-italic-15"
              className={styles['subtitle']}
            />
          </div>
          <div>
            <InnerButton
              title="Major.Read_More"
              className={styles['button-text']}
              buttonClassName={styles['button']}
              onClick={() => navigate(url ?? '')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithTitles;
