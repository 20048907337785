import React, { useEffect, useState } from 'react';
import styles from './UniversalMap.module.scss';
import map from '../../../../assets/images/map.png';
import Text from '../../../atoms/text/Text';
import Numbers from '../../../atoms/numbers/Numbers';
import AvatarGroup from '../../../atoms/avatarGroup/AvatarGroup';
import avatar from '../../../../assets/images/avatar.png';
import avatarSlide from '../../../../assets/images/avatarSlide.png';
import flagPlaceholder from '../../../../assets/icons/flag-placeholder/location flag.svg';
import endpoints from '../../../../api/endpoints';
import { useGetQuery } from '../../../../services/apiCall';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const images = [avatar, avatarSlide, avatar];
const UniversalMap = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const apiUrl = endpoints.universalMap.GetGraduationStatistics;

  const { data, error, isLoading, refetch } = useGetQuery({
    apiUrl: apiUrl,
  });
  const [selectedCountry, setSelectedCountry] = useState();
  useEffect(() => {
    if (data && data.length > 0) {
      const aeCountry = data.find((country) => country?.flagURL === 'AE');
      if (aeCountry) {
        setSelectedCountry(aeCountry);
      }
    }
  }, [data]);

  const processedData = data?.map((item) => ({
    country: item.flagURL,
    position: { x: item.positionX, y: item.positionY },
    bachelorCount: item.bachelorCount,
    mastersCount: item.mastersCount,
    phdCount: item.phdCount,
    flagClass: `flag-icon-${item.flagURL.toLowerCase()}`,
    countryName_EN: item.countryName_EN,
    countryName_AR: item.countryName_AR,
  }));

  const handleCountryClick = (country) => {
    setSelectedCountry(country);
  };

  const ImageWebp = ({ src, alt, ...props }) => {
    return (
      <picture>
        <source srcSet={src} type="image/webp" />
        <img src={src} alt={alt} {...props} />
      </picture>
    );
  };

  return (
    <div className={styles['universal-map']}>
      <div className={styles['container']}>
        <div className={styles['map']}>
          <ImageWebp
            src={map}
            // fallbackSrc={mapFallback}
            alt="dubai police global map"
            className={styles['map-image']}
          />
          {processedData?.map((dot, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: `calc(${dot.position.x}%`,
                top: `calc(${dot.position.y}%`,
                transform: 'translate(-50%, -50%)',
              }}
              className={`${styles['country-dot']} ${selectedCountry?.country === dot.country ? styles['active'] : ''}`}
            >
              <img
                src={flagPlaceholder}
                className={styles['flag-placeholder']}
              />
              <span
                onClick={() => handleCountryClick(dot)}
                className={`flag-icon ${dot.flagClass} ${styles.flagClass}`}
                style={{ zIndex: 9 }}
              ></span>

              <span className={styles['flag-circle']}></span>
            </div>
          ))}
        </div>
        <div
          className={styles['heading-cotainer']}
          style={{ textAlign: isRtl ? 'right' : '' }}
        >
          <Text
            title="common.from"
            variant="text-bold-italic-70"
            style={{ textTransform: 'uppercase' }}
          />
          <Text
            title={
              currentLanguage === 'en'
                ? selectedCountry?.countryName_EN
                : selectedCountry?.countryName_AR
            }
            variant="text-bold-italic-40"
            style={{ textTransform: 'uppercase' }}
          />
          <Numbers data={selectedCountry} />
        </div>
        <div
          className={styles['alumni']}
          onClick={() => navigate('/students-affairs/alumni')}
        >
          <AvatarGroup images={images} />
          <Text
            title="alumni.Alumni"
            variant="text-bold-italic-40"
            style={{ textTransform: 'uppercase', cursor: 'pointer' }}
          />
        </div>
        <div className={styles['alumni-students']}>
          <Text
            title="8000+"
            variant="text-bold-italic-40"
            style={{ textTransform: 'uppercase', textAlign: 'end' }}
          />
          <Text
            title="alumni.alumni_students"
            variant="text-bold-italic-20"
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>
    </div>
  );
};

export default UniversalMap;
