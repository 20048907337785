import React, { useRef, useState } from 'react';
import styles from './RegistrationAppTemplate.module.scss';
import Container from '../../hoc/container/Container';
import Stepper from '../../organism/stepper/Stepper';
import { useDispatch, useSelector } from 'react-redux';
import StepOne from './steps/stepOne/StepOne';
import InnerButton from '../../atoms/innerButton/innerButton';
import StepTwo from './steps/stepTwo/StepTwo';
import StepThree from './steps/stepThree/StepThree';
import StepFour from './steps/stepFour/StepFour';
import Text from '../../atoms/text/Text';
import warningSign from '../../../assets/icons/warning-sign.svg';
import StepFive from './steps/stepFive/StepFive';
import StepSix from './steps/stepSix/StepSix';
import { useEffect } from 'react';
import { updateStep } from '../../../app/globals/slice/formSteps/FormSteps';
import determineActiveStep from '../../../app/helpers/DetermineActiveStep';
import PageHeader from '../pageHeader/PageHeader';
import imgBackground from '../../../assets/images/aboutTheAcademy.png';
import { useTranslation } from 'react-i18next';
import { usePostMutation } from '../../../services/apiCall';
import endpoints from '../../../api/endpoints';
import { errorMessage } from '../../atoms/toast/Toast';
import Loader from '../../atoms/loader/Loader';

const RegistrationAppTemplate = () => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const step_One_FormRef = useRef();
  const step_Two_FormRef = useRef();
  const step_Three_FormRef = useRef();
  const step_Four_FormRef = useRef();
  const step_Five_FormRef = useRef();
  const step_Six_FormRef = useRef();
  const [post, { isLoading, isSuccess, isError, error }] = usePostMutation();
  const fontSize = useSelector((state) => state.fontSize);
  const formSteps = useSelector((state) => state.steps);
  const [activeStep, setActiveStep] = useState(determineActiveStep(formSteps));
  const [loading, setLoading] = useState(false);

  const steps = [
    {
      step: 1,
      title: 'registrationForm.Stepper.StepOne_Title',
      content: <StepOne stepRef={step_One_FormRef} activeStep={activeStep} />,
      ref: step_One_FormRef,
    },
    {
      step: 2,
      title: 'registrationForm.Stepper.StepTwo_Title',
      content: <StepTwo stepRef={step_Two_FormRef} activeStep={activeStep} />,
      ref: step_Two_FormRef,
    },
    {
      step: 3,
      title: 'registrationForm.Stepper.StepThree_TItle',
      content: (
        <StepThree stepRef={step_Three_FormRef} activeStep={activeStep} />
      ),
      ref: step_Three_FormRef,
    },
    {
      step: 4,
      title: 'registrationForm.Stepper.StepFour_TItle',
      content: <StepFour stepRef={step_Four_FormRef} activeStep={activeStep} />,
      ref: step_Four_FormRef,
    },
    {
      step: 5,
      title: 'registrationForm.Stepper.StepFive_Title',
      content: <StepFive stepRef={step_Five_FormRef} />,
      ref: step_Five_FormRef,
    },
    {
      step: 6,
      title: 'registrationForm.Stepper.StepSix_Title',
      content: <StepSix />,
      ref: step_Six_FormRef,
    },
  ];

  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;

  const handleStepSubmitting = () => {
    steps[activeStep].ref.current?.submitForm();
  };

  const objectToFormData = (obj, test) => {
    const formData = new FormData();

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item, index) => {
          if (item instanceof File) {
            formData.append(`${key}[${index}]`, item);
          } else {
            Object.entries(item).forEach(([subKey, subValue]) => {
              if (subValue instanceof File && !subValue.fileName) {
                formData.append(`${key}[${index}].${subKey}`, subValue);
              } else if (
                subValue !== '' &&
                typeof subValue !== 'undefined' &&
                !subValue.fileName
              ) {
                formData.append(`${key}[${index}].${subKey}`, subValue);
              }
            });
          }
        });
      } else {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (value instanceof File && !value.fileName) {
            formData.append(key, value);
          } else if (
            value !== '' &&
            typeof value !== 'undefined' &&
            !value.fileName
          ) {
            formData.append(key, value);
          }
        }
      }
    }

    const applicantId = localStorage.getItem('applicantId');
    const applicationId = localStorage.getItem('applicationId');

    if (applicantId && applicationId && !test) {
      formData.append('ApplicantId', applicantId);
      formData.append('ApplicationId', applicationId);
    }
    return formData;
  };
  const handleStep4Submission = async (data) => {
    // work is here
    const {
      ApplicantId,
      ApplicationId,
      SATDocument,
      SATScore,
      TestFiles,
      ReferanceFile1,
      ReferanceFile2,
      PersonalStatement,
      PersonalStatementText,
      personalResearch,
      AcademicInformation,
    } = data;

    try {
      const payload1 = {
        ApplicantId: ApplicantId || '',
        ApplicationId: ApplicationId || '',
        SATDocument: SATDocument || '',
        SATScore: SATScore || '',
        // TestFiles: TestFiles || [],
        ReferanceFile1: ReferanceFile1 || '',
        ReferanceFile2: ReferanceFile2 || '',
        PersonalStatement: PersonalStatement || '',
        PersonalStatementText: PersonalStatementText || '',
        // personalResearch: personalResearch || [],
      };
      const apiUrl1 = 'Applicant/PostStage4';
      const result1 = await post({
        apiUrl: apiUrl1,
        formData: objectToFormData(payload1),
        type: 'formData',
      }).unwrap();
      const test = true;
      const applicantId =
        result1?.applicantId || localStorage.getItem('applicantId');
      const applicantionId = localStorage.getItem('applicationId');

      if (TestFiles && TestFiles.length > 0) {
        TestFiles.forEach(async (degree, index) => {
          const apiUrl4 = 'Applicant/PostStage4_ApplicantTestFiles';
          const testFilePayload = {
            TestType: degree.testType || '',
            AcademicDocument: degree.academicDocument || '',
            DateTaken: degree.dateTaken || new Date().toISOString(),
            RegistrationNumber: degree.graduationYear || '',
            TotalScore: degree.totalScore || '',
            ApplicantId: applicantId || '',
            ApplicationId: applicantionId || '',
          };

          const result2 = await post({
            apiUrl: apiUrl4,
            formData: objectToFormData(testFilePayload, test),
            type: 'formData',
          }).unwrap();
        });
      }

      if (personalResearch && personalResearch.length > 0) {
        personalResearch.forEach(async (degree, index) => {
          const apiUrl3 = 'Applicant/PostStage4_ApplicantPersonalResearch';
          const researchPayload = {
            PersonalResearchFile: degree.personalResearchFile,
            PersonalResearchText: degree.personalResearchText,

            ApplicantId: applicantId,
            ApplicationId: applicantionId,
            ResearchIndex: index,
          };
          await post({
            apiUrl: apiUrl3,
            formData: objectToFormData(researchPayload, test),
            type: 'formData',
          }).unwrap();
        });
      }

      if (AcademicInformation && AcademicInformation.length > 0) {
        AcademicInformation.forEach(async (degree, index) => {
          const apiUrl2 = 'Applicant/PostStage4_AcademicInformation';

          const degreePayload = {
            countryId: degree.countryId || '',
            educationalInstitution: degree.EducationalInstitution || '',
            degreeId: degree.degreeId || '',
            graduationYear: degree.graduationYear || '',
            advancedCourses: degree.advancedCourses || '',
            lastActivities: degree.lastActivities || '',
            academicDegreeFile: degree.academicDegreeFile || {},
            applicantId: applicantId || '',
            applicationId: applicantionId || '',
          };

          await post({
            apiUrl: apiUrl2,
            formData: objectToFormData(degreePayload, test),
            type: 'formData',
          }).unwrap();
        });
      }
      return true;
    } catch (error) {
      console.error('API error:', error);
      errorMessage('common.something_wrong');
      return false;
    }
  };

  const saveData = async () => {
    setLoading(true);
    const formValues = steps[activeStep]?.ref.current?.values;
    delete formValues.NationalService_Files;
    const formData = objectToFormData(formValues);

    if (activeStep === 3) {
      const isSuccessful = await handleStep4Submission(formValues);
      if (isSuccessful) {
        setActiveStep((prevStep) => prevStep + 1);
      }
      setLoading(false);
      return;
    }
    const stageName = `postStage${activeStep + 1}`;
    const apiUrl = endpoints.registrationForm[stageName];
    let payload = { apiUrl: apiUrl, formData: formData, type: 'formData' };

    try {
      const result = await post(payload).unwrap();
      if (activeStep === 0 && result) {
        localStorage.setItem('applicantId', result?.applicantId);
        localStorage.setItem('applicationId', result?.applicationId);
      }
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      errorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  const handleStepChange = (isNextStep) => {
    if (isNextStep) handleStepSubmitting(activeStep);
    setTimeout(() => {
      if (isNextStep) {
        if (steps[activeStep].ref.current?.isValid) {
          saveData();
        } else {
          errorMessage('registrationForm.required_fields');
          window.scrollTo(0, 0);
        }
      } else {
        setActiveStep(activeStep - 1);
      }
    }, 100);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const data = {
    img: imgBackground,
    title: 'Application',
    subtext: 'Application Form',
  };

  return (
    <div className={styles['registration-app']}>
      <div className={styles['header']}>
        <div className={`${styles['title']} ${isRtl ? styles.titleRtl : ''}`}>
          <Text
            title="registrationForm.Application"
            variant="text-regular-bold-40"
            fontSize={fontSize}
            style={{ textTransform: 'uppercase' }}
          />
          <Text
            title="registrationForm.Application_form"
            variant="text-bold-20"
            fontSize={fontSize}
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>
      <Container className={styles.containerClass}>
        <Text
          title="registrationForm.Fill_Required_Fields"
          variant="text-bold-italic-20-black"
          fontSize={fontSize}
          svg={warningSign}
          style={{ marginBottom: '1rem' }}
        />
        <Stepper
          activeStep={activeStep}
          steps={steps}
          isLastStep={isLastStep}
        />
        {loading && <Loader fullPage />}
        <div
          className={
            activeStep === 0
              ? styles['submit-button-flex']
              : styles['submit-button']
          }
        >
          {!isFirstStep && (
            <InnerButton
              title="registrationPage.Previous_Button"
              onClick={() => handleStepChange(false)}
            />
          )}
          {!isLastStep && (
            <InnerButton
              type="submit"
              title="registrationPage.Continue_Button"
              onClick={() => handleStepChange(true)}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default RegistrationAppTemplate;
