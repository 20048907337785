import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { usePostMutation } from '../../../services/apiCall';
import { initialValues, validationSchema } from './data';
import endpoints from '../../../api/endpoints';
import {
  errorMessage,
  successMessage,
} from '../../../components/atoms/toast/Toast';
import { useNavigate } from 'react-router-dom';

const apiUrl = endpoints.showInterest.showInterest;
const useRegisterForm = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState(1);

  const [post, { isLoading }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // values.degree = Constant.DEFAULT_ID;
    let payload = { apiUrl, formData: values };

    try {
      await post(payload).unwrap();
      successMessage();
      resetForm();
      navigate('/');
    } catch (error) {
      errorMessage();
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  const degree = formik.values.degree;

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
    // formik.setFieldValue('program', null);
  }, [formik.values.degree]);

  return { isLoading, handleSubmit, formik, degree, key };
};

export default useRegisterForm;
