import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'slideTo',
  initialState: {
    currentSection: 0,
  },
  reducers: {
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
  },
});

export const { setCurrentSection } = navigationSlice.actions;
export default navigationSlice.reducer;
