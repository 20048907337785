import { createSlice } from '@reduxjs/toolkit';

const scrollControlSlice = createSlice({
  name: 'scrollControl',
  initialState: {
    isScrollEnabled: true,
  },
  reducers: {
    enableScroll: (state) => {
      state.isScrollEnabled = true;
    },
    disableScroll: (state) => {
      state.isScrollEnabled = false;
    },
  },
});

export const { enableScroll, disableScroll } = scrollControlSlice.actions;
export default scrollControlSlice.reducer;
