import React, { useEffect } from 'react';
import styles from './Register.module.scss';
import logo from '../../assets/icons/logo.svg';
import Text from '../../components/atoms/text/Text';
import Input from '../../components/atoms/input/Input';
import Button from '../../components/atoms/button/Button';
import Separator from '../../components/atoms/separator/Separator';
import RadioButton from '../../components/atoms/radioButton/RadioButton';
import Select from '../../components/atoms/select/Select';
import { DatePickerFormField } from '../../components/atoms/datepicker/DatePicker';
import useRegisterForm from './factory/factory';
import useDegreeOptions from '../../app/hooks/degrees/useDegreeData';
import { useDegreeData } from '../../components/molecule/applyScolarshipModal/factory/data';
import { useTranslation } from 'react-i18next';
import MobileInput from '../../components/atoms/mobileInput/MobileInput';
import { useLocation } from 'react-router-dom';
const optionsArray = [
  {
    label: 'registrationPage.Male_Label',
    value: 0,
  },
  {
    label: 'registrationPage.Female_Label',
    value: 1,
  },
];

const Register = () => {
  const { isLoading, formik, degree, key } = useRegisterForm();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const options = useDegreeOptions(degree);
  const degreeOptions = useDegreeData(i18n.language);
  const location = useLocation();
  const type = location.state;

  const filteredDegreeOptions =
    type === 1
      ? degreeOptions.filter(
          (option) => option.value === 1 || option.value === 2
        )
      : degreeOptions.filter((option) => option.value === 0);

  return (
    <>
      <div className={styles['register-container']}>
        <div className={styles['form-container']}>
          <div className={styles['left-side']}>
            <img src={logo} />

            <div className={styles['heading']}>
              <Text
                title="registrationPage.welcome_to_dubai_police_academy"
                variant="text-bold-25-black"
                style={{ textTransform: 'uppercase' }}
              />
              <div style={{ textAlign: isRtl ? 'end' : '' }}>
                <Text
                  title="registrationPage.you_are_few_steps_away"
                  variant="text-regular-20"
                  className={isRtl ? styles['text'] : styles['text-ar']}
                />
              </div>
            </div>
          </div>

          <Separator direction="vertical" />

          <div className={styles['register-form']}>
            <form onSubmit={formik.handleSubmit} className={styles['form']}>
              <Text
                title="registrationPage.Interest_Form"
                variant="text-bold-italic-30-black"
                style={{
                  textTransform: 'uppercase',
                  marginBottom: '2rem',
                }}
              />
              <Input
                styleType="secondary"
                placeholder="registrationPage.First_Name_Label"
                required
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                error={formik.touched.firstName && formik.errors.firstName}
                changes
              />
              <Input
                styleType="secondary"
                placeholder="registrationPage.Last_Name_Label"
                required
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                error={formik.touched.lastName && formik.errors.lastName}
                changes
              />
              <MobileInput
                placeholder="registrationPage.Phone_Label"
                name="phoneNumber"
                onChange={(fieldName, selectedValue) => {
                  formik.setFieldValue(fieldName, selectedValue);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                styleType="secondary"
                error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                style={{ marginTop: '10px' }}
              />
              <Input
                styleType="secondary"
                placeholder="registrationPage.Email_Label"
                required
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email}
                changes
              />
              {/* <Input
                styleType="secondary"
                placeholder="registrationPage.Degree_Label"
                required
                name="degree"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.degree}
                error={formik.touched.degree && formik.errors.degree}
                changes
              />
              <Input
                styleType="secondary"
                placeholder="registrationPage.Program_Label"
                required
                name="program"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.program}
                error={formik.touched.program && formik.errors.program}
                changes
              /> */}

              {/* <Select
                // label="Degree"
                name="degree"
                placeholder="common.select_a_degree"
                options={filteredDegreeOptions}
                onChange={(name, selectedOption) => {
                  formik.setFieldValue(name, selectedOption);
                  formik.setFieldValue('program', null);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.degree}
                error={formik.touched.degree && formik.errors.degree}
                opacity={0.6}
              />
              <Select
                label=""
                key={key}
                name="program"
                placeholder="common.select_a_program"
                options={options}
                onChange={(name, selectedOption) =>
                  formik.setFieldValue(name, selectedOption)
                }
                onBlur={formik.handleBlur}
                value={formik.values.program}
                error={formik.touched.program && formik.errors.program}
                isDisabled={degree === null ? true : false}
                opacity={0.6}
              /> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  marginTop: '1rem',
                }}
              >
                <DatePickerFormField
                  name="dob"
                  type="date"
                  label={'registrationPage.Date_Of_Birth_Label'}
                  value={formik.values.dob}
                  onChange={(name, value) => {
                    formik.setFieldValue(name, value);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.dob && formik.errors.dob}
                  lableStyle={{ opacity: '0.2' }}
                  disableFuture={true}
                />
                <div className={styles['gender']}>
                  <Input
                    styleType="secondary"
                    placeholder="registrationPage.I_Am_Label"
                    required
                    disabled={true}
                    style={{ width: '100px' }}
                  />
                  <RadioButton
                    name="gender"
                    value={formik.values.gender}
                    onOptionChange={(name, newOption) =>
                      formik.setFieldValue(name, newOption)
                    }
                    direction="row"
                    style={{ opacity: '0.4' }}
                    options={optionsArray}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>
                {formik.touched.gender && formik.errors.gender && (
                  <Text
                    title={formik.errors?.gender}
                    variant="text-regular-15"
                    className={styles['text']}
                    style={{ color: 'red' }}
                  />
                )}
              </div>
            </form>

            <div className={styles['account-already']}>
              <Button
                styleType="secondary"
                title="registrationPage.Register"
                variant="text-bold-regular-25"
                toUppercase
                onClick={formik.handleSubmit}
                type="Submit"
                isLoading={isLoading}
              />
              {/* <div className={styles['texts']}>
              <Text
                title="Do you Already have an account?"
                
                variant="text-regular-18-black"
              />
              <Text
                title="Login Here"
                
                variant="text-regular-18-black"
                style={{
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
                color="green"
              />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
