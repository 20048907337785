import React, { useState } from 'react';
import styles from './MediaGallery.module.scss';
import useScrollToTop from '../../../../app/hooks/useScrollToTop';
import BottomNavigation from '../../../molecule/bottomNavigation/BottomNavigation';
import spaceVideo from '../../../../assets/video/spaceVideo.mp4';
import LazyVideo from '../../../atoms/lazyVideo/LazyVideo';
import Gallery from './slides/gallery/Gallery';
import GalleryInfo from './slides/galleryInfo/GalleryInfo';
import VerticalSlider from '../../../molecule/verticalSlider/VerticalSlider';
import { commmonLinks } from '../commonLinks/data';
import Messaging from '../../../atoms/messaging/Messaging';
import { useGetQuery } from '../../../../services/apiCall';
import endpoints from '../../../../api/endpoints';
import Loader from '../../../atoms/loader/Loader';
import { useNavigate } from 'react-router-dom';

const MediaGallery = () => {
  const apiUrl = endpoints.news.mediaGallery;
  const navigate = useNavigate();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);

  const { data, error, isLoading, refetch } = useGetQuery({
    apiUrl: apiUrl,
  });

  const findGalleryById = (id) => {
    const gallery = data?.find((g) => g.mediaGalleryId === id);
    if (gallery) {
      setGalleryImages(gallery || []);
    } else {
      setGalleryImages([]);
    }
  };

  const handleSlideChange = (newIndex, id) => {
    setCurrentSlideIndex(newIndex);
    findGalleryById(id);
  };
  useScrollToTop();
  if (error) {
    navigate('/error');
    return null;
  }
  return (
    <>
      <LazyVideo src={spaceVideo} />
      <div className={styles['media-gallery']}>
        {isLoading ? (
          <Loader fullPage />
        ) : (
          <div className={styles.slide}>
            <VerticalSlider
              components={[
                <Gallery data={data} handleSlideChange={handleSlideChange} />,
                <GalleryInfo
                  data={galleryImages}
                  handleSlideChange={handleSlideChange}
                />,
              ]}
              style={{ height: '100%' }}
              dots={styles.homeDots}
              hasArrows={false}
              currentSlide={currentSlideIndex}
              onSlideChange={handleSlideChange}
              isCustom
            />
          </div>
        )}

        <BottomNavigation data={commmonLinks} variant="text-bold-20" />
        <Messaging
          disableScrollToTop
          handleSlideTopSection={() => setCurrentSlideIndex(0)}
        />
      </div>
    </>
  );
};

export default MediaGallery;
