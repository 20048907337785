import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stepOne: {},
  stepTwo: {},
  stepThree: {},
  stepFour: {},
  stepFive: {},
  stepSix: {},
};

const StepSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    updateStep: (state, action) => {
      state[action.payload.stepName] = action.payload.stepData;
    },
  },
});

export const { updateStep } = StepSlice.actions;
export default StepSlice.reducer;
