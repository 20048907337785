import React from 'react';
import styles from './CommingSoon.module.scss';
import commingSoonImg from '../../../assets/images/CommingSoon/ComingSoonImage.svg';
import comingSoonArabic from '../../../assets/icons/calendar/cominsoonArabic.svg';

import HeaderSection from '../../organism/headerSection/HeaderSection';
import { useTranslation } from 'react-i18next';
import Footer from '../homePageSections/footer/Footer';
import useScrollToTop from '../../../app/hooks/useScrollToTop';

const CommingSoonPage = () => {
  useScrollToTop();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  return (
    <div className={styles.background}>
      <HeaderSection />
      <div className={styles.commingSoonContainer}>
        <div className={styles.subContainer}>
          {isRtl ? (
            <img src={comingSoonArabic} alt="commingSoon" />
          ) : (
            <img src={commingSoonImg} alt="commingSoon" />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CommingSoonPage;
