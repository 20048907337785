import React, { useState } from 'react';
import styles from './CalendarTabs.module.scss';
import Text from '../../atoms/text/Text';

const CalendarTabs = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const setActiveTab = (index) => {
    setSelectedTab(index);
  };
  const handleTouchStart = (event) => {
    event.stopPropagation();
  };

  const handleTouchMove = (event) => {
    event.stopPropagation();
  };
  return (
    <div
      className={styles['calendar-tabs']}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className={styles['tab-headers']}>
        {data?.map((tab, index) => (
          <div
            className={`${styles['button']} ${
              selectedTab === index ? styles['active'] : ''
            }`}
            onClick={() => setActiveTab(index)}
            key={index}
          >
            <Text
              title={tab.title}
              style={{ textTransform: 'uppercase' }}
              variant="text-bold-italic-20-black"
              className={selectedTab === index ? styles['active'] : ''}
            />
          </div>
        ))}
      </div>
      <div
        className={styles['tab-content']}
        onMouseEnter={() => (window.isHoveringCalendar = true)}
        onMouseLeave={() => (window.isHoveringCalendar = false)}
      >
        {data[selectedTab].isNull ? (
          <div className={styles.noData}>
            <img
              src={data[selectedTab].contentImage}
              alt={data[selectedTab].title}
            />
          </div>
        ) : (
          <img
            src={data[selectedTab].contentImage}
            alt={data[selectedTab].title}
            className={`${data[selectedTab].isNull ? styles.noData : ''}`}
          />
        )}
      </div>
    </div>
  );
};

export default CalendarTabs;
