import React from 'react';
import styles from './Container.module.scss';
import { useTranslation } from 'react-i18next';

function Container({ children, className }) {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  return (
    <div
      className={`${styles['container']} ${className ?? ''} ${
        isRtl ? 'rtl' : ''
      }`}
    >
      {children}
    </div>
  );
}

export default Container;
