import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GlobalErrorHandler = () => {
  const navigate = useNavigate();
  const errorPath = useSelector((state) => state.navigation.errorPath);

  useEffect(() => {
    if (errorPath) {
      navigate('/error');
    }
  }, [errorPath, navigate]);

  return null;
};

export default GlobalErrorHandler;
