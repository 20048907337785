import React, { useMemo } from 'react';
import styles from './SlideOne.module.scss';
import doubleQuoteLeft from '../../../../../../assets/icons/doubleQuoteLeft.svg';
import doubleQuoteRight from '../../../../../../assets/icons/doubleQuoteRight.svg';
import headerVideo from '../../../../../../assets/video/headerVideo.mp4';
import LazyVideo from '../../../../../atoms/lazyVideo/LazyVideo';
import Backdrop from '../../../../../atoms/backdrop/Backdrop';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../atoms/text/Text';

const SECTION_ID = 0;

const SlideOne = ({ currentSection, slideIndex }) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const lazyVideoElement = useMemo(() => {
    return (
      <LazyVideo
        src={headerVideo}
        isPaused={currentSection !== SECTION_ID || slideIndex !== 0}
      />
    );
  }, [currentSection === SECTION_ID, slideIndex]);

  return (
    <div className={styles['slide-one']}>
      <Backdrop />
      {lazyVideoElement}
      <div className={styles['content']}>
        {/* <AcademyTour /> */}
        <div className={styles['container']}>
          <div className={`${styles['students-info']}`}>
            <img
              className={styles['left-quotes']}
              src={doubleQuoteLeft}
              alt="dubai police"
              loading="lazy"
            />
            <img
              className={styles['right-quotes']}
              src={doubleQuoteRight}
              alt="dubai police"
              loading="lazy"
            />
            <div className={styles['right-border']}></div>
            <div className={styles['left-border']}></div>
            <div
              className={styles.textContainer}
              style={{ textAlign: isRtl ? 'right' : 'left' }}
            >
              <Text
                title="home.graduate_proudly"
                variant="text-bold-italic-40"
                className={styles.text}
              />
              <Text
                title="home.join_proudly"
                variant="text-bold-italic-40"
                className={styles.text}
              />
              <Text
                title="home.strive_proudly"
                variant="text-bold-italic-40"
                className={styles.text}
              />
              <Text
                title="home.inspire_proudly"
                variant="text-bold-italic-40"
                className={styles.text}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideOne;
