import defaultImg from '../../../../../assets/images/history/1966/one_intro.png';
import firstBack from '../../../../../assets/images/history/1966/one_main.png';

//1966
import id3_1966 from '../../../../../assets/images/history/1966/id3_1966.png';
import id4_1966_front from '../../../../../assets/images/history/1966/id4_1966_front.png';
import law_1966 from '../../../../../assets/images/history/1966/law.png';
import law_2005 from '../../../../../assets/images/history/2005/Law2005.png';
import law_1987 from '../../../../../assets/images/history/1978/Law1978.png';

//mobile

import law_1966_backBGMobile from '../../../../../assets/images/history/1966/mobile/1968-m-1.png';
//1970
import id5_1970_background from '../../../../../assets/images/history/1970/id5_1970_background.png';
import id6_1970_front from '../../../../../assets/images/history/1970/id6_1970_front.png';
//mobile
import law_1970_backBG from '../../../../../assets/images/history/1970/mobile/1970-bg.png';

//1978
import id7_1978_background from '../../../../../assets/images/history/1978/id7_1978_background.png';
import id9_1978_front from '../../../../../assets/images/history/1978/id9_1978_front.png';
import id9_1978_backgroumd from '../../../../../assets/images/history/1978/id9_1978_background.png';
import mobile_1978_background from '../../../../../assets/images/history/1978/mobile/1978-bg-3.png';
import mobile_1978_front from '../../../../../assets/images/history/mobile/1978-m-1.png';

//1980
import id10_1980_background from '../../../../../assets/images/history/1980/id10_1980_background.png';
import id11_1980_front from '../../../../../assets/images/history/1980/id11_1980_front.png';
import id11_1980_background from '../../../../../assets/images/history/1980//id11_1980_background.png';
import id11_1980_front_tablet from '../../../../../assets/images/history/mobile/1978-2.png';

//mobile
import mobile_1980_background from '../../../../../assets/images/history/1980/mobile/1980-bg.png';
import mobile_1980_background3 from '../../../../../assets/images/history/1980/mobile/1978-bg-2.png';

//1988
import id12_1988_background from '../../../../../assets/images/history/1988/id12_1988_background.png';
import id14_1988_front from '../../../../../assets/images/history/1988/id14_1988_front.png';
import id14_1988_background from '../../../../../assets/images/history/1988/id14_1988_background.png';
import id15_1988_front from '../../../../../assets/images/history/1988/id15_1988_front.png';
import id15_1988_background from '../../../../../assets/images/history/1988/id15_1988_background.png';
import id16_1988_front from '../../../../../assets/images/history/1988/id16_1988_front.png';
import id16_1988_background from '../../../../../assets/images/history/1988/id16_1988_background.png';
import lotti1988 from '../../../../../assets/images/history/1988/History_61.png';

//mobile
import mobile_1988_background from '../../../../../assets/images/history/1988/mobile/1988-bg.png';
import mobile_1988_background3 from '../../../../../assets/images/history/1988/mobile/1988-bg-2.png';

//1991
import id17_1991_background from '../../../../../assets/images/history/1991/id17_1991_background.png';
import id18_1991_front from '../../../../../assets/images/history/1991/id18_1991_front.png';

//2005
import id19_2005_background from '../../../../../assets/images/history/2005/id19_2005_background.png';
import id19_2005_front from '../../../../../assets/images/history/2005/id19_2005_front.png';
import id20_2005_background from '../../../../../assets/images/history/2005/id20_2005_background.png';
import id21_2005_background from '../../../../../assets/images/history/2005/id21_2005_background.png';
import id21_2005_front from '../../../../../assets/images/history/2005/id21_2005_front.png';
import id22_2005_background from '../../../../../assets/images/history/2005/twelve_bg.png';
import id22_2005_front from '../../../../../assets/images/history/2005/id22_2005_front.png';
import thirteen_bg from '../../../../../assets/images/history/2010/seventeen_bg.png';
import thirteen_front from '../../../../../assets/images/history/2010/seventeen.png';

import thirteen_intro from '../../../../../assets/images/history/2010/seventeen_intro.png';
import firstBackground2005 from '../../../../../assets/images/history/2005/firstBackground2005.png';
import firstFront2005 from '../../../../../assets/images/history/2005/firstFront2005.png';
import VideoSnap2005Image from '../../../../../assets/images/history/2005/2005VideoSnapImage.png';

//mobile
import mobile_2005_background1 from '../../../../../assets/images/history/2005/mobile/2005-bg.png';

//2010
import id28_2010_front from '../../../../../assets/images/history/2010/id28_2010_front.png';
import id28_2010_background from '../../../../../assets/images/history/2010/id28_2010_background.png';
import id28_2010_front_tablet from '../../../../../assets/images/history/mobile/1988-3.png';

//mobile
import mobile_2010_background1 from '../../../../../assets/images/history/2010/mobile/2010-bg.png';

//2018
import id29_2018_front from '../../../../../assets/images/history/2018/id29_2018_front.png';
import id29_2018_background from '../../../../../assets/images/history/2018/id29_2018_background.png';
import id30_2018_background from '../../../../../assets/images/history/2018/id30_2018_background.png';
import id30_2018_front from '../../../../../assets/images/history/2018/id30_2018_front.png';
import id30_2018_front_tablet from '../../../../../assets/images/history/mobile/1991-1.png';
import id30_2018_front_tablet_bg from '../../../../../assets/images/history/mobile/1991-bg.png';

import id31_2018_BG from '../../../../../assets/images/history/2018/fifteen_bg.png';
import eighteen_intro from '../../../../../assets/images/history/2018/eighteen_intro.png';
import eighteen_front from '../../../../../assets/images/history/2018/eighteensadsss.png';
import nineteen_bg from '../../../../../assets/images/history/2018/nineteen_bg.png';
import nineteen from '../../../../../assets/images/history/2018/nineteen.png';

//mobile
import mobile_2018_background2 from '../../../../../assets/images/history/2018/mobile/2018-bg.png';
import mobile_2018_background3 from '../../../../../assets/images/history/2018/mobile/2018-2.png';
import mobile_2018_background4 from '../../../../../assets/images/history/2018/mobile/2018-bg-2.png';

//2022
import introImage_2022 from '../../../../../assets/images/history/2022/twenty_intro.png';
import videoBackground2022 from '../../../../../assets/images/history/2022/videoBackground2022.png';
import frontImage2022 from '../../../../../assets/images/history/2022/frontImage2022.png';

//mobile
import mobile_2022_background2 from '../../../../../assets/images/history/2022/mobile/2022-bg.png';
import mobile_2022_background3 from '../../../../../assets/images/history/mobile/2022-m-1.png';

//

import defaultInner from '../../../../../assets/images/history/1966/one.png';
import AcademySection from '../sections/academySection/AcademySection';
import StepperSection from '../sections/stepperSection/StepperSection';
import Section from '../sections/accreditation/section';
import imageBackground from '../../../../../assets/images/aboutTheAcademyHistory.png';
import PageHeader from '../../../../../components/templates/pageHeader/PageHeader';
import OurGraduation from '../../../../../components/templates/aboutTheAcademyTemplate/outlet/graduation/OurGraduation/OurGraduation';
import historyVideo from '../../../../../assets/video/history-Montage.mp4';
import { navigationLinks } from '../../../factory/data';

// import video2005 from '../../../../../assets/video/history2005.mp4';
// import video2022 from '../../../../../assets/video/history2022.mp4';

import img1968m2 from '../../../../../assets/images/history/mobile/1968-m-2.png';
import img1968mbg1 from '../../../../../assets/images/history/mobile/1968-m-bg-1.png';
import img1968mbg2 from '../../../../../assets/images/history/mobile/1968-m-bg-2.png';
// import img1970m1 from '../../../../../assets/images/history/mobile/1970-m-1.png';
import img1970mbg1 from '../../../../../assets/images/history/mobile/1970-m-bg-1.png';
import img1980mbg1 from '../../../../../assets/images/history/mobile/1980-front.png';

// import img1978m1 from '../../../../../assets/images/history/mobile/1978-m-1.png';
import img1978mbg2 from '../../../../../assets/images/history/mobile/1978-m-bg-2.png';
import img1978mbg3 from '../../../../../assets/images/history/mobile/1978-m-bg-3.png';
import img1980m1 from '../../../../../assets/images/history/mobile/1980-m-1.png';
import img1980m2 from '../../../../../assets/images/history/mobile/1980-m-2.png';

import img1980m5 from '../../../../../assets/images/history/mobile/1978-m-1.png';
import img1978m1 from '../../../../../assets/images/history/mobile/1978-m-3.png';
import img1979m1 from '../../../../../assets/images/history/mobile/1979-m-1.png';
import img1979m2 from '../../../../../assets/images/history/mobile/1979-m-2.png';

import img1988m1 from '../../../../../assets/images/history/mobile/1988-m-1.png';
import img1988m2 from '../../../../../assets/images/history/mobile/1988-m-2.png';
import img1988m3 from '../../../../../assets/images/history/mobile/1988-m-3.png';
import img1988m4 from '../../../../../assets/images/history/mobile/1988-m-4.png';
import img1991m2 from '../../../../../assets/images/history/mobile/1991-m-bg-1.png';
import img1991m3 from '../../../../../assets/images/history/mobile/2018-m-2.png';

import img2018m1 from '../../../../../assets/images/history/mobile/2018-m-1.png';
// import img2018m2 from '../../../../../assets/images/history/mobile/2018-m-2.png';
import sheikh from '../../../../../assets/images/history/mobile/sheikh.png';

import sheikh2 from '../../../../../assets/images/history/mobile/1979-1-tablet.png';
import CardsSections from '../sections/cardsSection/CardsSections';

const data = {
  img: imageBackground,
  title: 'About_The_Academy.title',
  subtext: 'History.History_subtitle',
};
const years = [
  {
    year: 1968,
    barSize: false,
  },
  { year: 1970, barSize: true },
  { year: 1978, barSize: true, hasVideo: true },
  { year: 1979, barSize: false },
  { year: 1980, barSize: true },
  { year: 1988, barSize: true, hasVideo: true },
  { year: 1991, barSize: false },
  { year: 2005, barSize: true, hasVideo: true },
  { year: 2010, barSize: true },
  { year: 2018, barSize: false },
  // { year: 2021, barSize: true },
  { year: 2022, barSize: true, hasVideo: true },
];

/*
 * Note regarding the 'id' field in the array elements:
 * Ignore the id here, the id is generated from the index of the array
 */
const parallaxSections = [
  {
    id: 0,
    component: PageHeader,
    data: data,
    navigationData: navigationLinks,
    backdrop: true,
  },
  {
    id: 1,
    component: OurGraduation,
    videoUrl: historyVideo,
    isAnimated: true,
    title: 'common.our_history',
    singleWord: true,
  },
  {
    backgroundImage: defaultImg,
    text: 'History.intro_speech',
    backgroundColor: 'black',
    id: 2,
  },
  ///
  {
    year: 1968,
    backgroundImage: defaultImg,
    mobileImage: img1968mbg1,
    text: 'History.1968Text',
    id: 2,
  },

  {
    year: 1968,
    backgroundImage: firstBack,
    foregroundImage: defaultInner,
    isLottie: true,
    mobileImage: law_1966_backBGMobile,
    lottieCLassname: 'defaultInner',
    sideModal: {
      text: 'History.1966Text',
      buttonText: 'History.law1966',
      image: law_1966,
    },
    id: 2,
  },
  {
    year: 1968,
    backgroundImage: id3_1966,
    mobileImage: img1970mbg1,
    sectionClass: 'twoIntro',
    text: 'History.twoIntro',
    id: 1,
  },

  {
    year: 1968,
    backgroundImage: id3_1966,
    foregroundImage: id4_1966_front,
    mobileImage: img1968m2,
    classname: 'img1988m4',

    isLottie: true,
    id: 4,
  },

  //// END OF 1968

  {
    year: 1970,
    backgroundImage: id5_1970_background,
    mobileImage: law_1970_backBG,

    text: 'History.threeIntro',
    id: 3,
  },
  {
    year: 1970,
    backgroundImage: id5_1970_background,
    foregroundImage: id6_1970_front,
    mobileImage: img1980mbg1,

    isLottie: true,
    id: 4,
  },
  //// END OF 1970

  {
    year: 1978,
    backgroundImage: id7_1978_background,
    mobileImage: mobile_1978_background,
    classname: 'img1988m4',

    hasVideo:
      'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/1978.mp4',
    id: 5,
  },
  {
    year: 1978,
    backgroundImage: id9_1978_backgroumd,
    mobileImage: id9_1978_backgroumd,
    text: 'History.fourIntro',
    classname: 'img1988m4',

    id: 6,
  },
  {
    year: 1978,
    backgroundImage: id9_1978_backgroumd,
    foregroundImage: id9_1978_front,
    mobileImage: mobile_1978_front,
    id: 7,
  },

  {
    year: 1978,
    backgroundImage: id10_1980_background,
    text: 'History.fiveIntro',
    mobileImage: img1978mbg2,
    classname: 'img1988m4',

    id: 8,
  },
  {
    year: 1978,
    backgroundImage: id11_1980_background,
    foregroundImage: id11_1980_front,
    mobileImage: sheikh,
    // tabletImage: id11_1980_front_tablet,
    isLottie: true,
    id: 9,
  },
  {
    year: 1978,
    backgroundImage: id12_1988_background,
    mobileImage: mobile_1978_background,
    text: 'History.sixIntro',
    classname: 'img1988m4',

    id: 10,
  },
  {
    year: 1978,
    backgroundImage: id14_1988_background,
    foregroundImage: id14_1988_front,
    mobileImage: img1978m1,
    isLottie: true,
    classname: 'img1978m1',

    id: 9,
  },
  {
    year: 1979,
    backgroundImage: id15_1988_background,
    foregroundImage: id15_1988_front,
    mobileImage: img1979m1,
    tabletImage: sheikh2,
    onTabletClassname: 'reCenterImageOneTablet',
    classname: 'reCenterImageOneMobile',
    id: 9,
  },
  {
    year: 1979,
    backgroundImage: id16_1988_background,
    foregroundImage: id16_1988_front,
    mobileImage: img1979m2,
    id: 9,
  },

  //// END OF 1978

  {
    year: 1980,
    backgroundImage: id17_1991_background,
    mobileImage: mobile_1980_background,
    text: 'History.nineSection',
    classname: 'img1988m4',

    id: 11,
  },
  {
    year: 1980,
    backgroundImage: id17_1991_background,
    foregroundImage: id18_1991_front,
    mobileImage: img1980m1,
    // classname: 'img1988m4',

    id: 12,
  },
  // {
  //   year: 1980,
  //   backgroundImage: id19_2005_background,
  //   // foregroundImage: id19_2005_front,
  //   mobileImage: mobile_1980_background3,
  //   classname: 'img1988m4',

  //   id: 13,
  // },
  {
    year: 1980,
    backgroundImage: id19_2005_background,
    foregroundImage: id19_2005_front,
    mobileImage: img1980m2,

    id: 14,
  },
  //// END OF 1980
  {
    year: 1988,
    backgroundImage: id12_1988_background,
    mobileImage: mobile_1978_background,
    classname: 'img1988m4',

    hasVideo:
      'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/1988.mp4',
    id: 15,
  },
  // {
  //   year: 1988,
  //   backgroundImage: id19_2005_background,
  //   foregroundImage: id19_2005_front,
  //   id: 19,
  // },
  {
    year: 1988,
    backgroundImage: id20_2005_background,
    text: 'History.elevenSection',
    // name: 'History.elevenSection_name',
    mobileImage: mobile_1988_background,
    classname: 'img1988m4',

    id: 16,
  },

  {
    year: 1988,
    backgroundImage: id21_2005_background,
    foregroundImage: id21_2005_front,
    classname: 'img1988m4',
    mobileImage: img1988m1,
    lottieImage: lotti1988,
    isLottie: true,
    id: 17,
  },
  {
    year: 1988,
    backgroundImage: id22_2005_background,
    text: 'History.twelveIntro',
    mobileImage: mobile_1988_background3,
    classname: 'img1988m4',
    isBackdrop: true,

    id: 18,
  },
  {
    year: 1988,
    backgroundImage: id22_2005_background,
    foregroundImage: id22_2005_front,
    isBackdrop: true,
    classname: 'img1988m2',
    mobileImage: img1988m2,
    isLottie: true,
    id: 19,
  },
  // {
  //   id: 20,
  //   letterArray: ['A', 'B', 'C'],
  //   activeID: 1,
  //   identifiyer: 1,
  //   year: 1988,
  //   component: StepperSection,
  // },
  // {
  //   id: 21,
  //   letterArray: ['A', 'B', 'C'],
  //   activeID: 2,
  //   year: 1988,
  //   component: StepperSection,
  // },
  // {
  //   id: 22,
  //   letterArray: ['A', 'B', 'C'],
  //   activeID: 3,
  //   year: 1988,
  //   component: StepperSection,
  // },
  // {
  //   id: 23,
  //   letterArray: ['D', 'E', 'F'],
  //   activeID: 1,
  //   identifiyer: 2,
  //   year: 1988,
  //   component: StepperSection,
  // },
  {
    id: 20,
    year: 1988,
    component: CardsSections,
  },
  // {
  //   id: 24,
  //   letterArray: ['D', 'E', 'F'],
  //   activeID: 2,
  //   year: 1988,
  //   component: StepperSection,
  // },
  // {
  //   id: 25,
  //   letterArray: ['D', 'E', 'F'],
  //   activeID: 3,
  //   year: 1988,
  //   component: StepperSection,
  // },
  {
    year: 1988,
    backgroundImage: id28_2010_background,
    foregroundImage: id28_2010_front,
    mobileImage: img1988m3,
    tabletImage: id28_2010_front_tablet,
    uniqueId: 'reShapeImageOneMobile',
    id: 26,
  },
  {
    year: 1988,
    backgroundImage: id29_2018_background,
    foregroundImage: id29_2018_front,
    mobileImage: img1988m4,
    classname: 'img1988m4',
    sideModal: {
      text: 'History.forteenSection',
      buttonText: 'History.law1987',
      image: law_1987,
    },
    id: 27,
  },
  //// END OF 1988
  {
    year: 1991,
    backgroundImage: id30_2018_background,
    mobileImage: img1991m2,
    classname: 'img1988m4',
    tabletImage: id30_2018_front_tablet_bg,
    text: 'History.seventeenIntro',
    id: 28,
  },
  {
    year: 1991,
    backgroundImage: id31_2018_BG,
    foregroundImage: id30_2018_front,
    mobileImage: img1991m2,
    tabletImage: id30_2018_front_tablet,
    classname: 'img1988m4',

    id: 29,
  },
  // {
  //   year: 1991,
  //   backgroundImage: id16_1988_background,
  //   foregroundImage: id16_1988_front,
  //   id: 30,
  // },

  //// END OF 1991 eighteenIntro
  {
    year: 2005,
    backgroundImage: VideoSnap2005Image,
    mobileImage: VideoSnap2005Image,
    hasVideo:
      'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/2005 1.mp4',
    // isFullScreen: true,
    id: 32,
    classname: 'img1988m4',
  },
  {
    year: 2005,
    backgroundImage: firstBackground2005,
    text: 'History.sixteenIntro',
    innerLaw: {
      text: 'History.law2005',
      image: law_2005,
    },
    mobileImage: mobile_2005_background1,
    id: 30,
    classname: 'img1988m4',
  },
  {
    year: 2005,
    backgroundImage: firstBackground2005,
    text: 'History.sixteenIntro2',
    mobileImage: mobile_2005_background1,
    id: 30,
  },
  {
    year: 2005,
    backgroundImage: firstBackground2005,
    foregroundImage: firstFront2005,
    mobileImage: img1991m3,
    id: 30,
  },

  // {
  //   year: 2005,
  //   backgroundImage: thirteen_bg,
  //   text: 'History.forteenSection',
  //   id: 33,
  // },
  // {
  //   year: 2005,
  //   backgroundImage: thirteen_bg,
  //   text: 'History.sixteenIntro2',
  //   id: 32,
  // },
  // {
  //   year: 2005,
  //   backgroundImage: thirteen_bg,
  //   foregroundImage: id16_1988_front,
  //   id: 34,
  // },

  //// END OF 2005
  {
    year: 2010,
    backgroundImage: thirteen_intro,
    text: 'History.twelveIntro',
    mobileImage: img1968mbg2,

    id: 35,
  },
  {
    year: 2010,
    backgroundImage: thirteen_bg,
    foregroundImage: thirteen_front,
    // text: 'History.twelveIntro',
    mobileImage: mobile_2010_background1,
    // hideOnMobile: true,
    classname: 'mobile_2010_background1',
    isBackdrop: true,
    id: 36,
  },
  // {
  //   year: 2010,
  //   backgroundImage: thirteen_bg,
  //   foregroundImage: thirteen,
  //   mobileImage: img2010mbg1,
  //   backgroundImageClassname: 'adjustOnMobile',
  //   id: 37,
  // },

  {
    year: 2018,
    backgroundImage: eighteen_intro,
    text: 'History.eighteenIntro',
    mobileImage: mobile_2018_background2,
    classname: 'img1988m4',

    id: 38,
  },
  {
    year: 2018,
    backgroundImage: eighteen_intro,
    foregroundImage: eighteen_front,
    mobileImage: img2018m1,
    classname: 'img2018m1',

    id: 39,
  },
  {
    year: 2018,
    backgroundImage: nineteen_bg,
    text: 'History.nineteenIntro',
    mobileImage: mobile_2018_background4,
    classname: 'img1988m4',

    id: 40,
  },
  {
    year: 2018,
    backgroundImage: nineteen_bg,
    foregroundImage: nineteen,
    mobileImage: mobile_2018_background3,
    classname: 'img1988m4',

    id: 41,
  },

  //// END OF 2018

  {
    backgroundImage: videoBackground2022,

    year: 2022,
    hasVideo:
      'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/2022.mp4',
    // isFullScreen: true,
    mobileImage: mobile_2022_background2,
    classname: 'img1988m4',

    id: 42,
  },
  // {
  //   year: 2022,
  //   backgroundImage: introImage_2022,
  //   id: 43,
  // },
  {
    year: 2022,
    backgroundImage: introImage_2022,
    text: 'History.twentyIntro',
    mobileImage: mobile_2022_background2,
    classname: 'img1988m4',

    id: 44,
  },
  {
    year: 2022,
    backgroundImage: introImage_2022,
    foregroundImage: frontImage2022,
    mobileImage: mobile_2022_background3,
    classname: 'mobile_2022_background3',
    id: 44,
  },

  // {
  //   year: 2022,
  //   backgroundImage: id7_1978_background,
  //   hasVideo:
  //     'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/2022.mp4',
  //   id: 46,
  // },
  {
    id: 47,
    component: AcademySection,
    // year: 2022,
  },
  {
    id: 45,
    component: Section,
    // year: 2022,
  },
];

const sections2010 = [];
export { parallaxSections, years, sections2010 };
