import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useDegreeOptions = (degreeId) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const degreeData = useSelector((state) => state?.degreeData?.degreeData);

  const options = useMemo(() => {
    if (!degreeData?.items) return [];

    let filteredItems = [];
    switch (degreeId) {
      case 0:
        filteredItems = degreeData.items.filter(
          (item) => item.degreeType === 0
        );
        break;
      case 1:
        filteredItems = degreeData.items.filter(
          (item) => item.degreeType === 1
        );
        break;
      case 2:
        filteredItems = degreeData.items.filter(
          (item) => item.degreeType === 2
        );
        break;
      default:
        return [];
    }

    return filteredItems.map((item) => ({
      value: item.degreeId,
      label: currentLanguage === 'en' ? item.degreeNameEn : item.degreeNameAr,
    }));
  }, [degreeData, degreeId, currentLanguage]);

  return options;
};

export default useDegreeOptions;
