import { useEffect } from 'react';

const animateIn = (refs, styles) =>
  refs.forEach((ref) => {
    ref.current?.classList.add(styles['animate-in']);
    ref.current?.classList.remove(styles['animate-out']);
  });

const animateOut = (refs, styles) =>
  refs.forEach((ref) => {
    ref.current?.classList.remove(styles['animate-in']);
    ref.current?.classList.add(styles['animate-out']);
  });

const useAnimation = (currentSection, sectionIndex, refs, styles) => {
  useEffect(() => {
    if (currentSection === sectionIndex) {
      setTimeout(() => animateIn(refs, styles), 300);
    } else {
      animateOut(refs, styles);
    }
  }, [currentSection, sectionIndex, refs, styles]);
};

export default useAnimation;
