/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styles from './CardsSections.module.scss';
import Text from '../../../../../../components/atoms/text/Text';
import facilities from './letterWrapper.svg';
import { useTranslation } from 'react-i18next';
const cardText = [
  {
    letter: '1',
    en: "By virtue of this law, a college named 'Police College' shall be established in the Emirate of Dubai. Its purpose is to instill principles of education and military training for the preparation, qualification, and training of students to become officers in the police force, holding a Bachelor's degree in Law and Police.",
    ar: "بموجب هذا القانون، سيتم إنشاء كلية تُسمى 'كلية الشرطة' في إمارة دبي. هدفها هو غرس مبادئ التعليم والتدريب العسكري لإعداد وتأهيل وتدريب الطلاب ليصبحوا ضباطاً في قوة الشرطة، ويحملون درجة البكالوريوس في القانون والشرطة.",
  },
  {
    letter: '2',
    en: "Preparing and qualifying the members, including officers, non-commissioned officers, and individuals, to obtain a Bachelor's degree in Law.",
    ar: 'إعداد وتأهيل الأعضاء، بما في ذلك الضباط وضباط الصف والأفراد، للحصول على درجة البكالوريوس في القانون.',
  },
  {
    letter: '3',
    en: 'Preparing and qualifying holders of university qualifications from various specified disciplines, as determined by the General Commander, to obtain a Diploma in Police Sciences.',
    ar: 'إعداد وتأهيل حاملي المؤهلات الجامعية من مختلف التخصصات المحددة، وفقاً لما يقرره القائد العام، للحصول على دبلوم في العلوم الشرطية.',
  },
  {
    letter: '4',
    en: 'Preparing, qualifying, and training newly appointed non-commissioned officers and police personnel according to the latest curricula, introducing them to laws, regulations, systems, and general duties.',
    ar: 'إعداد وتأهيل وتدريب ضباط الصف والأفراد المعينين حديثاً وفقاً لأحدث المناهج، وتعريفهم بالقوانين والأنظمة والواجبات العامة.',
  },
  {
    letter: '5',
    en: 'Preparing, qualifying, and training individuals selected to work as trainers in the college or training schools affiliated with the force.',
    ar: 'إعداد وتأهيل وتدريب الأفراد المختارين للعمل كمدربين في الكلية أو المدارس التدريبية التابعة للقوة.',
  },
  {
    letter: '6',
    en: 'Conducting various types of training courses for the members of the force, as determined by the regulations of the college.',
    ar: 'إجراء دورات تدريبية متنوعة لأعضاء القوة، وفقاً لما تحدده لوائح الكلية.',
  },
];

const CardsSections = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const isRtl = language === 'ar';
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const toggleExpand = (index) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getShortText = (text) => {
    const maxLength = 50;
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  return (
    <div
      className={styles['cards-section']}
      style={{ direction: isRtl ? 'rtl' : 'ltr' }}
    >
      <div className={styles['cards-grid']}>
        {cardText.map((card, index) => (
          <div className={styles.card} key={index}>
            <div className={styles.letter}>
              <Text
                title={card.letter}
                variant="text-bold-italic-20"
                color="green"
                className={styles.text}
              />
              <img src={facilities} alt="" className={styles.image} />
            </div>
            <div className={styles.footer}>
              <Text
                title={
                  expandedCardIndex === index || !isMobile
                    ? card[language]
                    : getShortText(card[language])
                }
                variant="text-regular-20"
              />

              {isMobile && expandedCardIndex !== index ? (
                <Text
                  title={'See More'}
                  variant="text-bold-italic-20"
                  className={styles.seeMore}
                  onClick={() => toggleExpand(index)}
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardsSections;
