import DOMPurify from 'dompurify';

export const sanitizeAndValidateUrl = (url) => {
  try {
    const sanitizedUrl = DOMPurify.sanitize(url);
    return sanitizedUrl;
  } catch (e) {
    console.warn('Error sanitizing URL:', e);
    return '';
  }
};
