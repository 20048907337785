import React from 'react';
import styles from './SlideTwo.module.scss';
import AdmissionsCard from '../../../../../molecule/admissionsCard/AdmissionsCard';
import backgroundImage from '../../../../../../assets/images/slideThree.png';
import Text from '../../../../../atoms/text/Text';
import ImageWithBackdrop from '../../../../../molecule/imageWithBackdrop/ImageWithBackdrop';
import { useGetQuery } from '../../../../../../services/apiCall';
import endpoints from '../../../../../../api/endpoints';
import Loader from '../../../../../atoms/loader/Loader';

const SlideTwo = () => {
  const apiUrl = endpoints.degrees.getAdmissionsDegrees;

  const { data, error, isLoading } = useGetQuery({
    apiUrl: apiUrl,
  });

  const admissionsData = [
    {
      id: 1,
      title: 'home.bachelor_degree',
      subtitles: data?.undergraduate.map((degree) => degree.degreeNameEn) || [],
      subtitlesAr:
        data?.undergraduate.map((degree) => degree.degreeNameAr) || [],
      startDate: data?.undergraduateAdmissionStart || '',
      endDate: data?.undergraduateAdmissionEnd || '',
    },
    {
      id: 2,
      title: 'home.master_degree',
      subtitles: data?.masters.map((degree) => degree.degreeNameEn) || [],
      subtitlesAr: data?.masters.map((degree) => degree.degreeNameAr) || [],
      startDate: data?.mastersAdmissionStart || '',
      endDate: data?.mastersAdmissionEnd || '',
    },
    {
      id: 3,
      title: 'home.phd_degree',
      subtitles: data?.phd.map((degree) => degree.degreeNameEn) || [],
      subtitlesAr: data?.phd.map((degree) => degree.degreeNameAr) || [],
      startDate: data?.phdAdmissionStart || '',
      endDate: data?.phdAdmissionEnd || '',
    },
  ];

  return (
    <div className={styles['slide-two']}>
      <ImageWithBackdrop
        src={backgroundImage}
        alt="degrees"
        backdropStyle={{ zIndex: -1 }}
      />
      <div className={styles['slide']}>
        <div className={styles['title']} style={{ alignItems: 'center' }}>
          <Text
            title="common.admissions"
            variant="text-bold-italic-50"
            style={{ textTransform: 'uppercase' }}
          />
          <Text
            title="home.are_now_open"
            variant="text-bold-italic-20"
            style={{ textTransform: 'uppercase' }}
          />
        </div>
        <div className={styles['content-grid']}>
          {isLoading ? (
            <>
              <div></div>
              <div className={styles.loader}>
                <div>
                  <Loader />
                </div>
              </div>
            </>
          ) : (
            admissionsData.map((data, index) => (
              <AdmissionsCard key={index} {...data} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SlideTwo;
