import React from 'react';
import styles from './Card.module.scss';
import Text from '../../atoms/text/Text';
import defaultImage from '../../../assets/images/defaultNews.png';
import calendar from '../../../assets/icons/calendar.svg';
import clock from '../../../assets/icons/clock.svg';
import arowDown from '../../../assets/icons/downArrow.svg';
import location from '../../../assets/icons/location-pin.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import isRtl from '../../../app/helpers/isRtl';
import defaultNews from '../../../assets/images/defaultNews.png';
import ReadMoreModal from '../../organism/readMoreModal/ReadMoreModal';
import HtmlTag from '../htmlTag/HtmlTag';
import { sanitizeAndValidateUrl } from '../../../app/helpers/sanitizeAndValidateUrl';

const Card = ({
  title,
  subtitle,
  date,
  imageUrl = defaultImage,
  className,
  color,
  whiteBackground,
  fill,
  style,
  imageStyle,
  description,
  descMaxLine,
}) => {
  const cardClassName = `${styles.card} ${className || ''} ${
    whiteBackground ? styles['white-background'] : ''
  }`.trim();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language !== 'en';
  const handleError = (e) => {
    e.target.src = defaultImage;
  };
  const sanitizedImageUrl = sanitizeAndValidateUrl(imageUrl);

  const [externalShowMore, setExternalShowMore] = useState(false);
  return (
    <div className={cardClassName} style={style}>
      <div className={styles.imageWrapper}>
        <img
          src={imageUrl || defaultNews}
          alt={title}
          className={styles.image}
          style={{
            borderRadius: currentLanguage ? '0px 10px 10px 0px' : '',
            ...imageStyle,
          }}
          onError={handleError}
          loading="lazy"
        />
      </div>
      <div className={styles['content-container']}>
        <div className={`${styles.content} ${currentLanguage ? 'rtl' : ''}`}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <HtmlTag
              title={subtitle}
              variant="text-bold-italic-16"
              className={styles.text}
              maxLines={2}
              color={color}
            />
            {/* <Text
              title={title}
              variant="text-bold-italic-18"
              maxLines={1}
              color={color}
              style={{ textTransform: 'uppercase' }}
            /> */}
            <Text
              title={date}
              variant="text-regular-14"
              maxLines={1}
              color={color}
            />
          </div>
          {/* <Text
            title={subtitle}
            variant="text-bold-italic-16"
            className={styles.text}
            maxLines={4}
            color={color}
          /> */}
          {/* <HtmlTag
            title={subtitle}
            variant="text-bold-italic-18"
            className={styles.text}
            maxLines={2}
            color={color}
          /> */}
          <Text
            title={title}
            variant="text-bold-italic-15"
            maxLines={1}
            color={color}
          />
          {description ? (
            <HtmlTag
              title={description}
              variant="text-regular-14"
              maxLines={descMaxLine ?? 2}
              color={color}
            />
          ) : null}
        </div>
        <div onClick={() => setExternalShowMore(true)}>
          <Text
            title="common.read_more"
            variant="text-regular-14"
            className={styles.viewMore}
            svgPosition="right"
            svg={arowDown}
            // style={{ textTransform: 'uppercase' }}
            color={color}
            fill={fill}
            onClick={() => setExternalShowMore(true)}
          />
        </div>
      </div>
      <ReadMoreModal
        isOpen={externalShowMore}
        closeModal={() => setExternalShowMore(false)}
        // image={`${url}${imgUrl2}`}
        title={title}
        // description={subtitle}
        subtitle={subtitle}
        description={description}
        date={date}
        // time={time}
        // categoryName={subtitle}
        // locationLink={locationLink}
        isDate
        image={[sanitizedImageUrl]}
      />
    </div>
  );
};

const HorizontalCard = ({
  title,
  date,
  subtitle,
  description,
  imageUrl,
  className,
  contentClass,
  imageClass,
  color,
  whiteBackground,
  fill,
  style,
  isGrid,
  descMaxLine,
  imageStyle,
  titleMaxLine,
  isDateFirst,
  titleSize,
  hideImageOnPhone,
}) => {
  const sanitizedImageUrl = sanitizeAndValidateUrl(imageUrl);

  const horizontalCardClassName = `${styles['horizontal-card']} ${
    className || ''
  } ${whiteBackground ? styles['white-background'] : ''}`.trim();
  const content = `${styles['content-container']} ${contentClass ?? ''}`.trim();
  const imageClassName = `${styles['imageWrapper']} ${imageClass ?? ''} ${
    hideImageOnPhone ? styles.hide : ''
  }`.trim();
  const [externalShowMore, setExternalShowMore] = useState(false);
  const handleError = (e) => {
    e.target.src = defaultImage;
  };
  return (
    <div className={horizontalCardClassName} style={style}>
      <div className={imageClassName}>
        <img
          src={sanitizedImageUrl}
          alt={title}
          style={imageStyle}
          onError={handleError}
          loading="lazy"
        />
      </div>
      <div className={content}>
        <div className={`${styles.content}  ${isRtl() ? 'rtl' : ''}`}>
          {isDateFirst ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '55px',
                gap: '1rem',
              }}
            >
              <Text
                svg={calendar}
                svgPosition="left"
                fill={fill}
                title={date}
                variant={`text-regular-${titleSize ?? '14'}`}
                maxLines={1}
                color={color}
              />
              <Text
                title={title}
                variant="text-bold-italic-15"
                maxLines={titleMaxLine ?? 1}
                color={color}
                style={{ textTransform: 'uppercase' }}
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: '55px',
              }}
            >
              {subtitle ? (
                <HtmlTag
                  title={subtitle}
                  variant="text-bold-italic-16"
                  className={isGrid ? styles.text : styles.none}
                  maxLines={descMaxLine ?? 2}
                  color={color}
                />
              ) : null}
              {/* <Text
                title={title}
                variant="text-bold-italic-16"
                maxLines={titleMaxLine ?? 1}
                color={color}
                style={{ textTransform: 'uppercase' }}
              /> */}
              <Text
                title={date}
                variant="text-regular-14"
                maxLines={1}
                color={color}
              />
            </div>
          )}
          {/* {subtitle ? (
            <HtmlTag
              title={subtitle}
              variant="text-bold-italic-15"
              className={isGrid ? styles.text : styles.none}
              maxLines={descMaxLine ?? 2}
              color={color}
            />
          ) : null} */}
          <Text
            title={title}
            variant="text-bold-italic-15"
            maxLines={titleMaxLine ?? 1}
            color={color}
          />
          {description ? (
            <HtmlTag
              title={description}
              variant="text-regular-14"
              className={isGrid ? styles.text : styles.none}
              maxLines={descMaxLine ?? 4}
              color={color}
            />
          ) : null}
        </div>

        <Text
          title="common.read_more"
          variant="text-regular-14"
          svgPosition="right"
          className={styles.viewMore}
          svg={arowDown}
          color={color}
          fill={fill}
          onClick={() => setExternalShowMore(true)}
        />
      </div>

      <ReadMoreModal
        isOpen={externalShowMore}
        closeModal={() => setExternalShowMore(false)}
        // image={`${url}${imgUrl2}`}
        title={title}
        description={description}
        subtitle={subtitle}
        date={date}
        // time={time}
        // categoryName={subtitle}
        // locationLink={locationLink}
        isDate
        image={[sanitizedImageUrl]}
      />
    </div>
  );
};

export { Card, HorizontalCard };
