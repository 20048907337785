import React from 'react';
import Header from '../../../components/templates/header/Header';
import Messaging from '../../../components/atoms/messaging/Messaging';
import SocialMedia from '../../../components/atoms/socialMedia/SocialMedia';
import useDetectScrolling from '../../../app/hooks/useDetectSection';

const HeaderSection = ({ isGreen, fixed, isNotAnimated, isHistoryPage }) => {
  let isScrolling = useDetectScrolling();

  return (
    <>
      <Header section={0} fixed={fixed} />
      <SocialMedia
        section={isNotAnimated ? 0 : isGreen ? 1 : isScrolling ? 1 : 0}
      />
      {!isHistoryPage ? <Messaging section={isNotAnimated ? 0 : 1} /> : null}
    </>
  );
};

export default HeaderSection;
