import React, { useState } from 'react';
import styles from './CheckBox.module.scss';
import Text from '../text/Text';
import checkMark from '../../../assets/icons/checkmark.svg';

const Checkbox = ({
  label,
  checked,
  onCheckboxChange,
  style,
  color = '',
  direction = 'row',
  textVariant,
  textComponent,
  textLink,
  textColorReverse,
}) => {
  // const [checked, setChecked] = useState(propChecked);

  // const handleCheckboxChange = () => {
  //   const newChecked = !checked;
  //   setChecked(newChecked);
  //   onCheckboxChange(newChecked);
  // };

  return (
    <div className={styles['checkbox-container']} style={style}>
      <div
        className={`${styles['labels']} ${
          direction === 'row' ? styles['row'] : styles['column']
        }`}
      >
        <label>
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) => onCheckboxChange(e.target.checked)}
          />
          <span className={styles['custom-checkbox']}>
            <img
              className={`${checked ? styles['checked'] : styles['image']}`}
              src={checkMark}
              alt="Checkmark"
            />
          </span>
        </label>
        <Text
          title={label}
          link={textLink}
          variant={textVariant ?? 'text-bold-italic-16-black'}
          style={{ color: color }}
          component={textComponent}
          colorReverse={textColorReverse}
          className={styles.text}
        />
      </div>
    </div>
  );
};

export default Checkbox;
