import React from 'react';
import Loader from '../loader/Loader';
import styles from './Button.module.scss';
import Text from '../text/Text';

const Button = ({
  fontSize,
  title,
  style,
  variant,
  className,
  onClick,
  styleType,
  color,
  type,
  svg,
  toUppercase,
  textStyling,
  svgPosition,
  isLoading,
}) => {
  const buttonClassName = [
    styles.button,
    className,
    styleType !== 'noOutltine' && styles[styleType],
    isLoading && styles.secondaryLoader,
    isLoading && styles.buttonDisabled,
  ]
    .join(' ')
    .trim();
  const buttonStyle = styleType && { border: `1px solid ${color}`, color };
  const textStyles = {
    textTransform: toUppercase === true ? 'uppercase' : '',
    cursor: 'pointer',
    ...textStyling,
  };
  const loaderStyle = {
    height: '100%',
    width: '100%',
  };
  return (
    <button
      onClick={onClick}
      className={buttonClassName}
      style={style || buttonStyle}
      type={type}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className={styles.buttonLoader}>
          {/* <span>Loading</span> */}
          <Loader style={loaderStyle} />
        </div>
      ) : (
        <Text
          title={title}
          fontSize={fontSize}
          variant={variant}
          style={textStyles}
          svg={svg}
          svgPosition={svgPosition}
        />
      )}
    </button>
  );
};

export default Button;
