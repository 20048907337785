export default {
  baseUrl: process.env.REACT_APP_URL,
  blobUrl:
    'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container',
  calendar: {
    getCalendarByYear: 'Calendar/GetCalendar/',
  },
  degrees: {
    getDegrees: 'Degree/GetDegrees/', // params = ${pageSize}/${pageNumber}
    getDegreesById: 'Degree/GetDegreeById/', // params = {$DegreeId}
    getAdmissionsDegrees: 'Degree/GetAdmissionDegrees',
  },
  news: {
    getNews: 'News/GetNews/', //  params = ${pageSize}/${pageNumber}
    getEvents: 'News/GetEvents/', //  params = ${pageSize}/${pageNumber}
    getNewsletter: 'News/GetNewsLetter/', //  params = ${pageSize}/${pageNumber}
    mediaGallery: 'News/GetMediaGallery/', // params = ${searchString}
    getEventsCountdown: 'News/GetEventCountDown/',
  },
  universalMap: {
    GetGraduationStatistics: 'Graduation/GetGraduationStatistics/',
  },

  dropdown: {
    getDropdownByType: 'Settings/GetDrowDown/', // params = ${type}
    getDropDownFromParent: 'Settings/GetDrowDownFromParent/', //params = ${type}/$(parentId),
    selectedTerms: 'Settings/GetAcadmicTerms/',
    degree: 'Degree/GetDegrees/20/1',
  },
  registrationForm: {
    postStage1: 'Applicant/PostStage1',
    postStage2: 'Applicant/PostStage2',
    postStage3: 'Applicant/PostStage3',
    postStage4: 'Applicant/PostStage4',
    postStage5: 'Applicant/PostStage5',
    postStage6: 'Applicant/PostPayment',
    getStage1: 'Applicant/GetApplicantStage/Stage1/',
    getStage2: 'Applicant/GetApplicantStage/Stage2/',
    getStage3: 'Applicant/GetApplicantStage/Stage3/',
    getStage4: 'Applicant/GetApplicantStage/Stage4/',
  },
  happinessMeter: {
    recordHappiness: 'HappinessMeter/RecordHappiness/', // params=${id}
  },
  magazine: {
    getMagazines: 'EchoMagazine/GetEchoMagazine/',
  },
  teams: {
    getTeams: 'Settings/GetTeams/', //  params = ${pageSize}/${pageNumber}
  },
  admission: {
    scolarship: 'Scholarship/Scholarship',
  },
  testimonials: { get: 'Testimonial/GetTestimonials/' }, // params = ${key}
  speech: { get: 'Testimonial/GetSpeech/' }, // params = ${key}

  SubscribeNEwsLetter: {
    subscribe: 'News/SubscribeNewleter/', // params = ${email}
  },
  settings: {
    getAcademicYears: 'Settings/GetAcademicYears',
    getAcademicYearsNews: 'News/GetNewsYears',
    getAcademicYearsNewsLetter: 'News/GetNewsletterYears',
    getAcademicYearsEcho: 'EchoMagazine/GetEchoMagazineYears',
  },
  showInterest: {
    showInterest: 'Applicant/ShowingInterest',
  },
  degree: {
    getDegrees: 'Degree/GetDegrees/', // params = ${pageSize}/${pageNumber}
    getDegreeById: 'Degree/GetDegreeById/', // params = ${degreeId}
  },
  alumni: {
    getAlumni: 'Alumni/GetAlumni', // params = ${degreeId}/${YearId}
    getGraduationYears: 'Alumni/GetGraduationYears',
    getDashboardNumbers: 'Alumni/GetDashboardNumbers/', // params = ${degreeType}/${isGender}
    getAlumniSuccessStories: 'Alumni/GetAlumniSuccessStories',
    getGraduationBatch: 'Alumni/GetGraduationBatch',
    getAlumniAmbassadors: 'Alumni/GetAlumniAmbassadors',
  },
  ecoMagazine: {
    getEcoPdf: 'EchoMagazine/GetEchoMagazine/0/', // params = ${pageSize}/${pageNumber}/${year}
  },
  guideline: {
    getGuilines: 'EchoMagazine/GetEchoMagazine/1/', // params = ${pageSize}/${pageNumber}/${year}
  },
  security: {
    getSecurity: 'EchoMagazine/GetEchoMagazine/2/', // params = ${pageSize}/${pageNumber}/${year}
  },
  publications: {
    getPublications: 'EchoMagazine/GetEchoMagazine/3/', // params = ${pageSize}/${pageNumber}/${year}
  },
  research: {
    getOportunities: '/Research/GetOpportunityTypes',
    getOportunitiesById: 'Research/ResearchOpportunities/', // params = 0 1 2 3 4 5
    postResearch: 'Research/ResearchSubmission',
  },
  council: {
    getCouncilById: '/Council/GetCouncilTeams?CouncilType=', // params = council 0 1 2 3 4
    getCouncilLatestNews: '/Council/GetCouncilNews/', // params = council type 0 1 2 3 4
  },
  facility: {
    getSettings: '/Settings/GetDrowDown/1',
    postBookFacility: '/Facility/BookFacility',
  },
  Quiz: {
    getQuestions: '/Quiz/GetQuizQuestions',
    getAraOfInterest: '/Quiz/GetAreaOfInterest',
    post: '/Quiz/QuizResult',
  },
  downloadBroshure: '/Calendar/GetCaledarPosterByTypeandYear/', // params = ${calendarType}/${academicYearid}
};
