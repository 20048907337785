import React, { useEffect, useState } from 'react';
import styles from './Input.module.scss';
import Text from '../text/Text';
import { useTranslation } from 'react-i18next';
import Label from '../../atoms/lable/Label';
import DOMPurify from 'dompurify';

const Input = ({
  label,
  placeholder,
  styleType,
  required,
  type,
  name,
  value = '',
  onChange,
  onBlur,
  style,
  labelTextStyle,
  error,
  disabled,
  textVariant,
  placeReq,
  isNotInPlaceholder = false,
  onChangeHandler,
  inputClass,
  inputStyle,
  isDashed = false,
  minDate,
}) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const inputClassName = `${styles.input} ${styles[styleType]} ${
    error ? styles['input-error'] : ''
  } ${isRtl ? 'rtl' : ''} ${inputClass ? inputClass : ''}}`;
  const labelColor = styleType === 'formField-white' ? 'white' : null;

  const [initialValue, setInitialValue] = useState(DOMPurify.sanitize(value));

  const formatEmiratesId = (value) => {
    const numericValue = value.replace(/\D/g, '');

    const part1 = numericValue.substring(0, 3);
    const part2 = numericValue.substring(3, 7);
    const part3 = numericValue.substring(7, 14);
    const part4 = numericValue.substring(14, 15);

    let formattedValue = part1;
    if (part2) formattedValue += `-${part2}`;
    if (part3) formattedValue += `-${part3}`;
    if (part4) formattedValue += `-${part4}`;

    return formattedValue;
  };

  const handleChange = (event) => {
    const newValue = event.target.value;

    const formattedValue = isDashed ? formatEmiratesId(newValue) : newValue;

    const sanitizedValue = DOMPurify.sanitize(formattedValue);

    setInitialValue(sanitizedValue);

    if (onChange) {
      onChange(name, sanitizedValue);
    }

    if (onChangeHandler) {
      onChangeHandler(name, sanitizedValue);
    }
  };

  useEffect(() => {
    setInitialValue(
      DOMPurify.sanitize(isDashed ? formatEmiratesId(value) : value)
    );
  }, [value, isDashed]);

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className={styles['input']} style={style}>
      {label ? (
        <Label
          label={label}
          required={required}
          error={error}
          style={labelTextStyle}
          textVariant={textVariant}
          color={labelColor}
        />
      ) : null}
      <input
        name={name}
        type={type ?? 'text'}
        className={inputClassName}
        placeholder={`${t(placeholder) ?? ''} ${
          required &&
          styleType !== 'formField' &&
          !placeReq &&
          !isNotInPlaceholder
            ? ' *'
            : ''
        }`}
        onChange={onChange ? onChange : handleChange}
        onBlur={onBlur}
        value={initialValue}
        disabled={disabled ? true : false}
        style={inputStyle}
        min={type === 'date' ? (minDate ? today : null) : undefined}
      />
      {error ? (
        <Text
          title={error}
          variant="text-regular-15"
          className={styles['text']}
          color={'red'}
        />
      ) : null}
    </div>
  );
};

export default Input;
