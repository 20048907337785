import React from 'react';
import styles from './RadioButton.module.scss';
import Text from '../text/Text';
import { useTranslation } from 'react-i18next';
import isRtl from '../../../app/helpers/isRtl';

const RadioButton = ({
  options,
  value,
  name,
  onOptionChange,
  label,
  style,
  direction = 'row',
  textVariant,
  textStyle,
  faded,
  onBlur,
  labelStyle,
  radioStyle,
  variant = 'default',
  grid,
  color,
  className,
  labelClass,
}) => {
  const textStyling = {
    ...textStyle,
  };

  if (faded) {
    textStyling.opacity = '0.7';
  }

  const handleChange = (name, newValue) => {
    if (name) {
      onOptionChange(name, newValue);
    } else {
      onOptionChange(newValue);
    }
  };
  const variantClass = variant === 'secondary' ? styles.secondary : '';

  const radioContainerClass = `${styles['radio-container']} ${variantClass} ${
    className || ''
  }`;

  return (
    <div className={radioContainerClass} style={style}>
      {label ? (
        <Text
          title={label}
          variant="text-regular-16-black"
          style={labelStyle}
          className={labelClass}
        />
      ) : null}
      <div
        className={`${styles['labels']} ${
          direction === 'row' ? styles['row'] : styles['column']
        } ${variant} ${grid}`}
        style={{ direction: isRtl() ? 'rtl' : '' }}
      >
        {options?.map((option, index) => {
          return (
            <div className={styles['radio-con']} key={index}>
              <input
                type="radio"
                value={option.value}
                name={name}
                checked={value === option.value}
                onChange={() => handleChange(name, option.value)}
                onBlur={onBlur}
                style={radioStyle}
              />
              <Text
                title={option.label}
                variant={textVariant ?? 'text-bold-italic-16-black'}
                style={textStyling}
                className={styles.text}
                color={color}
                onClick={() => handleChange(name, option.value)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButton;
