import React, { useEffect, useRef } from 'react';
import styles from './LazyVideo.module.scss';
import { useSelector } from 'react-redux';

const LazyVideo = ({
  src,
  type,
  isPaused,
  isLooped = true,
  cc = 'video',
  style,
  controls = false,
}) => {
  const videoRef = useRef(null);
  const volumeState = useSelector((state) => state.volume);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const safePlay = async () => {
      try {
        await video.play();
      } catch (error) {
        console.warn('Error playing video:', error);
      }
    };

    if (isPaused) {
      const pauseTimeout = setTimeout(() => video.pause(), 1000);
      return () => clearTimeout(pauseTimeout);
    } else {
      safePlay();
    }
  }, [isPaused]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleMetadata = () => video.removeAttribute('controls');
    video.addEventListener('loadedmetadata', handleMetadata);

    return () => video.removeEventListener('loadedmetadata', handleMetadata);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !video.src) {
            video.src = src;
            observer.unobserve(video);
          }
        });
      },
      {
        rootMargin: '50px',
        threshold: 0.01,
      }
    );

    observer.observe(video);

    return () => observer.disconnect();
  }, [src]);

  return (
    <video
      ref={videoRef}
      className={`${styles[cc]} ${!controls ? styles.videoControlsHidden : ''}`}
      autoPlay
      loop={isLooped}
      muted={volumeState === 'off'}
      playsInline
      style={style}
      controls={controls}
      preload="auto"
    >
      <source src={src} type={type} />
    </video>
  );
};

export default LazyVideo;
