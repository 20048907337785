import React, { useEffect, useMemo, useState } from 'react';
import styles from './StepOne.module.scss';
import Input from '../../../../atoms/input/Input';
import CustomSelect from '../../../../atoms/select/CustomSelect';
import MobileInput from '../../../../atoms/mobileInput/MobileInput';
import RadioButton from '../../../../atoms/radioButton/RadioButton';
import Text from '../../../../atoms/text/Text';
import List from '../../../../molecule/list/List';
import Separator from '../../../../atoms/separator/Separator';
import Checkbox from '../../../../atoms/checkBox/CheckBox';
import { DatePickerFormField } from '../../../../atoms/datepicker/DatePicker';
import { connect, Form, Formik } from 'formik';
import {
  getStepOneInitialValues,
  stepOneValidationSchema,
} from './factory/formFactory';
import { useTranslation } from 'react-i18next';
import endpoints from '../../../../../api/endpoints';
import { useGetQuery } from '../../../../../services/apiCall';
import Loader from '../../../../atoms/loader/Loader';
import { errorMessage } from '../../../../atoms/toast/Toast';

const items = [
  'registrationForm.StepOne.Student_Name',
  'registrationForm.StepOne.Telephone_Number(s)',
  'registrationForm.StepOne.Birth_Date_And_Place',
  'registrationForm.StepOne.Dates_Of_Attendance_And_Credentials-Awarded',
  'registrationForm.StepOne.Address(es)',
  'registrationForm.StepOne.E-Mail_Address(es)',
  'registrationForm.StepOne.Program_Of_Study',
];
function StepOne({ stepRef }) {
  const { t } = useTranslation();
  const [init, setInit] = useState({});
  const [isOther, setIsOther] = useState(false);
  const [isEmployed, setIsEmployed] = useState(false);

  const apiUrl = endpoints.registrationForm.getStage1;
  const applicantId = localStorage.getItem('applicantId');
  const applicationId = localStorage.getItem('applicationId');
  const {
    data,
    error: apiError,
    isLoading,
    refetch,
  } = useGetQuery(
    {
      apiUrl: apiUrl,
      params: [applicantId, applicationId],
    },
    {
      skip: !applicationId || !applicantId,
    }
  );
  useEffect(() => {
    if (applicationId || applicantId) {
      refetch();
    }
  }, []);
  useEffect(() => {
    const initialValues = {
      // TitleId: data?.stage1?.titleId || '',
      Email: data?.stage1?.email,
      // FirstName: data?.stage1?.firstName || '',
      // MiddleName: data?.stage1?.middleName || '',
      // LastName: data?.stage1?.lastName || '',
      FullName_EN: data?.stage1?.fullName_EN,
      FullName_AR: data?.stage1?.fullName_AR,
      PlaceOfBirth: data?.stage1?.placeOfBirth,
      EmiratesId: data?.stage1?.emiratesId,
      StreetName: data?.address?.streetName,
      // Address1: data?.address?.address1 || '',
      // ZipCode: data?.address?.zipCode || '',
      DOB: data?.stage1?.dob?.substring(0, 10),
      Gender: data?.stage1?.gender || '',
      ApplicantTelephone: data?.stage1?.phoneNumber || '',
      Nationality: data?.stage1?.nationalityId || '',
      PreferedWayToCommunicate: data?.stage1?.preferedWayToCommunicate || '',
      HearAboutUsId: data?.stage1?.hearAboutUsId || '',
      IsEmployed: data?.stage1?.isEmployed || false,
      IsPeopleOfDetermination: data?.stage1?.isPeopleOfDetermination || false,
      RequireAssistance: data?.stage1?.requireAssistance || '',
      CityState:
        data?.address?.cityState || '3b0ef415-df1c-4a73-91fc-b9ad564012db',
      Pobox: data?.address?.pobox || '',
      Country: data?.address?.country || 'aabb4357-5ed0-4e4e-8a28-b1aee0ca5598',
      Authorize_FatherName: data?.stage2?.authorize_FatherName || '',
      Authorize_MothersName: data?.stage2?.authorize_MothersName || '',
      Authorize_OthersName: data?.stage2?.authorize_OthersName || '',
      Authorize_Address: data?.stage2?.authorize_Address || '',
      Authorize_Email: data?.stage2?.authorize_Email || '',
      Authorize_Telephone: data?.stage2?.authorize_Telephone || '',
      Mobile: data?.stage1?.mobile || '',
      ApplicantId: data?.application?.applicantId || '',
      ApplicationId: data?.application?.applicationId || '',
      AuthorizeToReleaseRecord: data?.stage2?.authorizeToReleaseRecord || '',
      EmploymentType: data?.stage1?.employmentType || '',
      OtherEmployment: data?.stage1?.otherEmployment || '',
    };
    if (initialValues.IsEmployed) {
      setIsEmployed(initialValues.IsEmployed);
    }
    setInit(initialValues);
  }, [data]);

  const optionsArray = [
    { label: 'registrationForm.Yes', value: true },
    { label: 'registrationForm.No', value: false },
  ];

  const handleSubmit = (values) => {};

  useEffect(() => {
    if (apiError) {
      errorMessage();
    }
  }, []);
  return (
    <div className={styles['Step-one']}>
      {isLoading ? <Loader fullPage /> : null}
      <Formik
        innerRef={stepRef}
        initialValues={init}
        enableReinitialize={true}
        validationSchema={stepOneValidationSchema(isOther, isEmployed)}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, errors, touched, setFieldValue, handleBlur }) => {
          return (
            <Form>
              {console.log('asdasdasdasd', errors)}
              <div className={styles['input-grid']}>
                <div className={styles['inputs']}>
                  {/* <div className={styles['smaller-input']}>
                      <CustomSelect
                        type="1"
                        label={t('registrationForm.StepOne.Title')}
                        name="TitleId"
                        value={values.TitleId}
                        onChange={(name, selectedOption) =>
                          setFieldValue(name, selectedOption)
                        }
                        onBlur={handleBlur}
                        error={touched.TitleId && errors.TitleId}
                        required
                      />
                    </div> */}
                  <Input
                    label={t('registrationForm.StepOne.full_name_ar')}
                    styleType="formField"
                    required
                    name="FullName_AR"
                    error={touched.FullName_AR && errors.FullName_AR}
                    value={values.FullName_AR}
                    onChangeHandler={(name, e) => {
                      setFieldValue(name, e);
                    }}
                    onBlur={handleBlur}
                  />
                  <Input
                    label={t('registrationForm.StepOne.full_name_en')}
                    styleType="formField"
                    required
                    name="FullName_EN"
                    error={touched.FullName_EN && errors.FullName_EN}
                    value={values.FullName_EN}
                    onChangeHandler={(name, e) => {
                      setFieldValue(name, e);
                    }}
                    onBlur={handleBlur}
                  />
                  {/* <Input
                    label={t('registrationForm.StepOne.Last_Name')}
                    styleType="formField"
                    required
                    name="LastName"
                    error={touched.LastName && errors.LastName}
                    value={values.LastName}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                  /> */}
                  <Input
                    label={t('registrationForm.StepOne.Email')}
                    name="Email"
                    styleType="formField"
                    required
                    type="email"
                    error={touched.Email && errors.Email}
                    value={values.Email}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                  />
                  <DatePickerFormField
                    label={t('registrationForm.StepOne.Date_Of_Birth')}
                    styleType="formField"
                    name="DOB"
                    type="date"
                    value={values.DOB}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={touched.DOB && errors.DOB}
                    required
                    disableFuture={true}
                  />
                  <Input
                    label={t('registrationForm.StepOne.place_of_birth')}
                    name="PlaceOfBirth"
                    styleType="formField"
                    required
                    type="email"
                    error={touched.PlaceOfBirth && errors.PlaceOfBirth}
                    value={values.PlaceOfBirth}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                  />
                  <CustomSelect
                    type="2"
                    label={t('registrationForm.StepOne.Gender')}
                    name="Gender"
                    value={values.Gender}
                    onChange={(name, selectedOption) => {
                      setFieldValue(name, selectedOption);
                    }}
                    onBlur={handleBlur}
                    error={touched.Gender && errors.Gender}
                    required
                  />
                  <CustomSelect
                    label={t('registrationForm.StepOne.Nationality')}
                    name="Nationality"
                    type="3"
                    value={values.Nationality}
                    onChange={(name, selectedOption) =>
                      setFieldValue(name, selectedOption)
                    }
                    error={touched.Nationality && errors.Nationality}
                    required
                  />
                  <Input
                    label={t(
                      'registrationForm.StepOne.nationality_emirates_id'
                    )}
                    isDashed
                    name="EmiratesId"
                    styleType="formField"
                    required
                    error={touched.EmiratesId && errors.EmiratesId}
                    value={values.EmiratesId}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                  />{' '}
                  <MobileInput
                    label={t('registrationForm.StepOne.Mobile')}
                    name="Mobile"
                    value={values.Mobile}
                    onChange={(name, value) => setFieldValue(name, value)}
                    onBlur={handleBlur}
                    error={touched.Mobile && errors.Mobile}
                    required
                  />
                  <Input
                    label={t('registrationForm.StepOne.Telephone')}
                    styleType="formField"
                    name="ApplicantTelephone"
                    value={values.ApplicantTelephone}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.ApplicantTelephone && errors.ApplicantTelephone
                    }
                  />
                </div>
                <div
                  className={`${styles['input-row']} ${styles.onResponsivePhone}`}
                >
                  <div className={styles['flexed']}>
                    <CustomSelect
                      label={t(
                        'registrationForm.StepOne.Preferred_Way_To_Communicate'
                      )}
                      type="4"
                      name="PreferedWayToCommunicate"
                      value={values.PreferedWayToCommunicate}
                      onChange={(name, selectedOption) =>
                        setFieldValue(name, selectedOption)
                      }
                      error={
                        touched.PreferedWayToCommunicate &&
                        errors.PreferedWayToCommunicate
                      }
                      required
                    />
                  </div>
                  <CustomSelect
                    label={t(
                      'registrationForm.StepOne.How_did_you_Hear_About_us'
                    )}
                    type="5"
                    name="HearAboutUsId"
                    value={values.HearAboutUsId}
                    onChange={(name, selectedOption) =>
                      setFieldValue(name, selectedOption)
                    }
                    error={touched.HearAboutUsId && errors.HearAboutUsId}
                    required
                  />
                  <div className={styles['extra-div']}></div>
                </div>
                <div>
                  <RadioButton
                    label={t('registrationForm.StepOne.Are_you_Employed')}
                    name="IsEmployed"
                    value={values.IsEmployed}
                    onOptionChange={(name, newOption) => {
                      setFieldValue(name, newOption);
                      if (newOption === false) {
                        setFieldValue('EmploymentType', null);
                        setFieldValue('OtherEmployment', '');
                      }

                      setIsOther(newOption);
                      setIsEmployed(newOption);
                    }}
                    textStyle={{ opacity: 0.7 }}
                    direction="column"
                    options={optionsArray}
                    required
                    onBlur={handleBlur}
                    labelStyle={{ color: '#4C4C4C' }}
                  />

                  {touched?.IsEmployed && errors?.IsEmployed && (
                    <Text
                      title={errors?.IsEmployed}
                      variant="text-regular-15"
                      className={styles['text']}
                      style={{ color: 'red' }}
                    />
                  )}
                  <div className={styles.employment}>
                    {values.IsEmployed === true ? (
                      <CustomSelect
                        label={t('validation.employment_type')}
                        type="17"
                        name="EmploymentType"
                        isEmployed
                        value={values.EmploymentType}
                        onChange={(name, selectedOption) => {
                          setFieldValue(name, selectedOption);
                          if (
                            selectedOption ===
                            'b6ff4090-3ffc-417f-a225-2ac5f0f92925'
                          ) {
                            setIsOther(true);
                          } else {
                            setIsOther(false);
                          }
                        }}
                        error={touched.EmploymentType && errors.EmploymentType}
                        required
                      />
                    ) : null}
                    {values.EmploymentType ===
                    'b6ff4090-3ffc-417f-a225-2ac5f0f92925' ? (
                      <Input
                        label={t('validation.other')}
                        styleType="formField"
                        name="OtherEmployment"
                        value={values.OtherEmployment}
                        onChangeHandler={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.OtherEmployment && errors.OtherEmployment
                        }
                        required
                      />
                    ) : null}
                  </div>
                </div>
                <div>
                  <RadioButton
                    label={t(
                      'registrationForm.StepOne.Are_You_One_Of_The_People_Of_Determination'
                    )}
                    name="IsPeopleOfDetermination"
                    value={values.IsPeopleOfDetermination}
                    onOptionChange={(name, newOption) =>
                      setFieldValue(name, newOption)
                    }
                    textStyle={{ opacity: 0.7 }}
                    direction="column"
                    options={optionsArray}
                    required
                    onBlur={handleBlur}
                    labelStyle={{ color: '#4C4C4C' }}
                  />
                  {touched?.IsPeopleOfDetermination &&
                    errors?.IsPeopleOfDetermination && (
                      <Text
                        title={errors?.IsPeopleOfDetermination}
                        variant="text-regular-15"
                        className={styles['text']}
                        style={{ color: 'red' }}
                      />
                    )}
                </div>
                <CustomSelect
                  label={t('registrationForm.StepOne.Requires_Any_Assistance')}
                  name="RequireAssistance"
                  type="6"
                  textVariant="text-regular-16-black"
                  value={values.RequireAssistance}
                  onChange={(name, selectedOption) =>
                    setFieldValue(name, selectedOption)
                  }
                  error={touched.RequireAssistance && errors.RequireAssistance}
                  // required
                />
                <Separator direction="horizontal" />
                <Text
                  title={t(
                    'registrationForm.StepOne.MAILING_ADDRESS_(TO_BE_USED_FOR_ALL_ADMISSIONS_CORRESPONDENCE)'
                  )}
                  variant="text-regular-18-black"
                />
                <div style={{ display: 'flex', gap: '1rem' }}>
                  {/* <Input
                    label={t('registrationForm.StepOne.Address')}
                    styleType="formField"
                    required
                    name="Address1"
                    value={values.Address1}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={touched.Address1 && errors.Address1}
                  /> */}
                </div>
                <div className={styles['input-row']}>
                  <CustomSelect
                    label={t('registrationForm.StepOne.Country')}
                    name="Country"
                    value={values.Country}
                    type="8"
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                      setFieldValue('CityState', '');
                    }}
                    error={touched.Country && errors.Country}
                    required
                  />

                  <CustomSelect
                    label={t('registrationForm.StepOne.City/State')}
                    name="CityState"
                    value={values.CityState}
                    type="9"
                    parent={values.Country}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    error={touched.CityState && errors.CityState}
                    required
                  />

                  <Input
                    label={t('registrationForm.StepOne.P.O._Box')}
                    styleType="formField"
                    name="Pobox"
                    value={values.Pobox}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={touched.Pobox && errors.Pobox}
                  />
                  {/* <Input
                    label={t('registrationForm.StepOne.Zip_code')}
                    styleType="formField"
                    required
                    name="ZipCode"
                    value={values.ZipCode}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={touched.ZipCode && errors.ZipCode}
                  /> */}
                </div>
                <div className={styles['input-row-1']}>
                  <Input
                    label={t('registrationForm.StepOne.street_name')}
                    styleType="formField"
                    name="StreetName"
                    value={values.StreetName}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={touched.StreetName && errors.StreetName}
                  />
                </div>
                <Separator direction="horizontal" />
                <Text
                  title={t(
                    'registrationForm.StepOne.Consent_To_Release_Education_Records'
                  )}
                  variant="text-regular-18-black"
                  style={{ textTransform: 'uppercase' }}
                />
                <Text
                  title={t(
                    'registrationForm.StepOne.The_Dubai_Police_Academy_Directory_Information_As_The_Following'
                  )}
                  variant="text-bold-italic-16-black"
                  style={{ opacity: 0.7 }}
                />
                <List items={items} itemPaddingStart="20px" color="#4C4C4C" />
                <Text
                  title={t(
                    'registrationForm.StepOne.Any_Other_Education_Record_Is_Classified_As_Non-Directory_Information_And_Can_Not_Be_Disclosed_To_Any_Party_Without_The_Student’s_Consent'
                  )}
                  variant="text-bold-italic-16-black"
                  style={{ opacity: 0.7 }}
                />
                <div className={styles['checkbox-link']}>
                  <Checkbox
                    label="registrationForm.StepOne.Authorize"
                    checked={values.AuthorizeToReleaseRecord}
                    onCheckboxChange={(checked) => {
                      setFieldValue('AuthorizeToReleaseRecord', checked);
                    }}
                    color="red"
                    required
                    error={touched.Authorize && errors.Authorize}
                  />
                  <Text
                    title="registrationForm.StepOne.To_release_my_education_records_(e.g.,_grades,_GPA,_ID,_schedule,_statement_of_fees)_to_the_following"
                    variant="text-bold-italic-16-black"
                    style={{ color: '#1B224C' }}
                  />
                </div>
                {errors?.AuthorizeToReleaseRecord && (
                  <Text
                    title={errors?.AuthorizeToReleaseRecord}
                    variant="text-regular-15"
                    className={styles['text']}
                    style={{ color: 'red' }}
                  />
                )}
                <div className={styles['input-row']}>
                  <Input
                    label="registrationForm.StepOne.Fathers_Name"
                    styleType="formField"
                    required
                    name="Authorize_FatherName"
                    value={values.Authorize_FatherName}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.Authorize_FatherName &&
                      errors.Authorize_FatherName
                    }
                  />
                  <Input
                    label="registrationForm.StepOne.Mothers_Name"
                    styleType="formField"
                    required
                    name="Authorize_MothersName"
                    value={values.Authorize_MothersName}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.Authorize_MothersName &&
                      errors.Authorize_MothersName
                    }
                  />
                  <Input
                    label={t('registrationForm.StepOne.Others_Name')}
                    styleType="formField"
                    // required
                    name="Authorize_OthersName"
                    value={values.Authorize_OthersName}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.Authorize_OthersName &&
                      errors.Authorize_OthersName
                    }
                  />
                </div>
                <div className={styles['input-row']}>
                  <Input
                    label={t('registrationForm.StepOne.Address')}
                    styleType="formField"
                    required
                    name="Authorize_Address"
                    value={values.Authorize_Address}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.Authorize_Address && errors.Authorize_Address
                    }
                  />
                  <Input
                    label={t('registrationForm.StepOne.Email')}
                    styleType="formField"
                    required
                    name="Authorize_Email"
                    value={values.Authorize_Email}
                    onChangeHandler={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={touched.Authorize_Email && errors.Authorize_Email}
                  />

                  <MobileInput
                    label={t('registrationForm.StepOne.Mobile')}
                    name="Authorize_Telephone"
                    value={values.Authorize_Telephone}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.Authorize_Telephone && errors.Authorize_Telephone
                    }
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default StepOne;
