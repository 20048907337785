import React from 'react';
import styles from './Separator.module.scss';

const Separator = ({ direction, style, className, type }) => {
  const separatorClass = `${styles[direction]} ${className || ''}`;
  const containerClass =
    direction === 'horizontal' ? styles['separator-container'] : '';

  return type === 'straight-line' ? (
    <div className={`${styles['straight-line']} ${className || ''}`}></div>
  ) : (
    <div className={containerClass} style={style}>
      <div className={separatorClass}></div>
    </div>
  );
};

export default Separator;
