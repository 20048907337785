import React, { useState } from 'react';
import styles from './Menu.module.scss';
import Text from '../../../../../atoms/text/Text';
import rightArrow from '../../../../../../assets/icons/linkRightArrow.svg';
import leftArrow from '../../../../../../assets/icons/arrow-down-green.svg';

import { useTranslation } from 'react-i18next';
import News from '../components/news/News';

const MenuItem = ({
  title,
  svg,
  onClick,
  isSelected,
  variant,
  mobileContent,
}) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const itemClass = isSelected
    ? `${!isRtl ? styles.text : styles.textRtl} ${styles.selected}`
    : `${!isRtl ? styles.text : styles.textRtl}`;
  return (
    <>
      <Text
        title={title}
        variant={variant ?? 'text-bold-italic-25'}
        className={itemClass}
        svg={svg}
        svgPosition="right"
        onClick={onClick}
        svgStyle={
          isRtl && isSelected
            ? {
                transform: 'rotate(90deg)',
                marginRight: '20px',
              }
            : isRtl && !isSelected
              ? {
                  transform: 'rotate(180deg)',
                  marginRight: '20px',
                }
              : {}
        }
      />
      {mobileContent?.title === title ? (
        <div className={styles.contentsMobile}>{mobileContent?.component}</div>
      ) : null}
    </>
  );
};

const Menu = ({ data, setContent }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedChildIndex, setSelectedChildIndex] = useState(null);
  const [mobileContent, setMobileContent] = useState({
    title: 'home.news',
    component: <News />,
  });
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';

  const handleItemClick = (index, item) => {
    setSelectedItemIndex(index);
    setSelectedChildIndex(null);
    if (item.children) {
      setMobileContent(null);
    } else {
      setContent({
        title: item.title,
        component: item.component,
      });
      setMobileContent({
        title: item.title,
        component: item.component,
      });
    }
  };

  const handleChildItemClick = (child, index) => {
    setSelectedChildIndex(index);
    const newContent = {
      title: child.title,
      component: child.component,
    };

    setContent(newContent);
    setMobileContent(newContent);
  };

  const renderChildren = (children, parentIndex) => (
    <div className={styles.children}>
      {children.map((child, childIndex) => (
        <React.Fragment key={childIndex}>
          <MenuItem
            variant="text-bold-italic-20"
            title={child.title}
            onClick={() => handleChildItemClick(child, childIndex)}
            isSelected={childIndex === selectedChildIndex}
            mobileContent={
              selectedItemIndex === parentIndex &&
              selectedChildIndex === childIndex
                ? mobileContent
                : null
            }
          />
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div
      className={`${styles.navigation} ${isRtl ? 'rtl' : ''} ${
        isRtl ? styles.borders : ''
      }`}
    >
      {data?.map((item, index) => (
        <React.Fragment key={index}>
          <MenuItem
            title={item.title}
            onClick={() => handleItemClick(index, item)}
            svg={item?.children ? rightArrow : null}
            isSelected={index === selectedItemIndex}
            mobileContent={
              index === selectedItemIndex && !item.children
                ? mobileContent
                : null
            }
          />
          {index === selectedItemIndex &&
            item.children &&
            renderChildren(item.children, index)}
        </React.Fragment>
      ))}
    </div>
  );
};

export { Menu, MenuItem };
