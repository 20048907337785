import React from 'react';
import styles from './NavigationButton.module.scss';
import { useTranslation } from 'react-i18next';
import Text from '../text/Text';
import { NavLink, useMatch } from 'react-router-dom';

export function conditionalClassNames(classes) {
  return Object.entries(classes)
    .filter(([className, condition]) => condition)
    .map(([className, condition]) => className)
    .join(' ');
}

const NavigationButton = ({
  index,
  to = '#',
  id,
  activeButton,
  label = '',
  style = {},
  capitalised = false,
  variant,
  onClick,
  lastItem = false,
  ignoreLast,
  isMobile,
  toSection,
  currentSection,
  isHomepage,
  item,
}) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const displayLabel = capitalised ? t(label).toUpperCase() : t(label);
  const match = useMatch(to ?? '');
  const isActive = currentSection === toSection;

  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };
  const onSectionScroll = () => {
    onClick(item);
  };
  if (ignoreLast) {
    lastItem = false;
  }

  const isOdd = index % 2 === 1;
  const linkClass = conditionalClassNames({
    [styles.link]: true,
    [styles['with-right-border']]: !isMobile && !lastItem,
    [styles['with-left-border']]: isRtl && !isMobile && !lastItem,
    [styles['with-right-odd']]: isMobile && isOdd,
    [styles['active']]: to !== '' ? match : id === activeButton,
  });

  return isHomepage ? (
    <div className={styles.container} onClick={onSectionScroll}>
      <div className={linkClass} style={style}>
        <Text
          title={displayLabel}
          variant={variant}
          className={styles['text']}
        />
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      {to !== '' ? (
        <NavLink to={to} className={linkClass} style={style}>
          <Text
            title={displayLabel}
            variant={variant}
            className={styles['text']}
            textContainerEnvStyle={{ textAlign: 'center' }}
            maxLines={2}
          />
        </NavLink>
      ) : (
        <div className={linkClass} style={style} onClick={handleClick}>
          <Text
            title={displayLabel}
            variant={variant}
            className={styles['text']}
          />
        </div>
      )}
    </div>
  );
};

export default NavigationButton;
