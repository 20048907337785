import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from './HomeButtonNav.module.scss';
import ApplyNow from '../../../organism/applyNowModal/ApplyNow';
import { useTranslation } from 'react-i18next';
import Text from '../../../atoms/text/Text';
import BottomNavigationMobile from '../mobile/BottomNavigationMobile';
import Button from '../../../atoms/button/Button';
import { useDispatch } from 'react-redux';
import { setApplyNowState } from '../../../../app/globals/slice/applyNowModalSlice/ApplyNowSlice';
import { conditionalClassNames } from '../../../atoms/navigationButton/NavigationButton';
import { useNavigate } from 'react-router-dom';

const HomeButtonNav = ({
  isApplyNow,
  data,
  variant,
  scrollToSection,
  isMobile,
  active,
  containerClass,
  navigationStyle,
  ignoreLast,
  lastItem = false,
  index,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(active ?? 1);
  const isRtl = i18n.language !== 'en';
  const isDesktop = useMediaQuery({ minWidth: 1199 });

  if (isApplyNow) {
    ignoreLast = true;
  }

  const isOdd = index % 2 === 1;

  useEffect(() => {
    setActiveButton(active);
  }, [active]);

  const handleNavigation = (item) => {
    if (item.route) {
      navigate(item.route);
    } else {
      setActiveButton(item.sectionIndex);
      scrollToSection(item.sectionIndex);
    }
  };

  const handleApplyNowClick = () => {
    localStorage.removeItem('applicantId');
    localStorage.removeItem('applicationId');
    dispatch(setApplyNowState(true));
  };

  const containerClasses = [
    styles.container,
    isRtl && 'rtl',
    containerClass || '',
  ].join(' ');

  const linkClass = conditionalClassNames({
    [styles.link]: true,
    [styles['with-right-border']]: !isMobile && !lastItem,
    [styles['with-left-border']]: isRtl && !isMobile && !lastItem,
    [styles['with-right-odd']]: isMobile && isOdd,
  });
  if (ignoreLast) {
    lastItem = false;
  }

  return (
    <div>
      <div className={containerClasses} style={navigationStyle || {}}>
        <div className={styles.navigationsContainer}>
          {data?.map((item, index) => (
            <div className={styles['container-button']} key={index}>
              <div
                className={`${linkClass} ${
                  activeButton === item.sectionIndex ? styles.active : ''
                }`}
                onClick={() => handleNavigation(item)}
              >
                <Text
                  title={item.label}
                  variant={variant}
                  className={styles['text']}
                />
              </div>
            </div>
          ))}

          {isApplyNow && (
            <div className={styles['button-container']}>
              <Button
                title="common.Apply_Now"
                className={styles['button']}
                variant="text-bold-20"
                onClick={() => {
                  window.open(
                    'https://dubaipolice.ac.ae/dpacademy/ar/std_login.jsp',
                    '_blank'
                  );
                }}
                styleType="primary"
              />
            </div>
          )}
        </div>{' '}
      </div>
      {!isDesktop && (
        <BottomNavigationMobile
          data={data}
          variant="text-bold-italic-25"
          isApplyNow={isApplyNow}
          onClick={handleNavigation}
          active={activeButton}
          isHomepage
        />
      )}
      <ApplyNow />
    </div>
  );
};

export default HomeButtonNav;
