import React from 'react';
import Overview from '../sections/overview/Overview';
import Curiculum from '../sections/curiculum/Curiculum';
import Fees from '../sections/fees&financing/Fees';
import Admissions from '../sections/admissions/Admissions';

const Sections = ({ id = 1, data, currentLanguage }) => {
  switch (id) {
    case 1:
      return (
        <Overview
          key={data}
          data={data?.degree}
          carouselData={data?.degreeTestimonials}
          currentLanguage={currentLanguage}
          outcomeData={data?.degreeOutComes}
          facultyData={data?.degreeFaculties}
        />
      );
    case 2:
      return <Curiculum data={data?.degreeCurriculumDetails} />;
    case 3:
      return (
        <Admissions data={data?.degree} currentLanguage={currentLanguage} />
      );
    case 4:
      return (
        <Fees
          fees={data?.degree?.approvedFees}
          knowledge={data?.degree?.knowledgeInnovation}
        />
      );
  }
};

export default Sections;
