import React, { useState, useRef, useEffect } from 'react';
import styles from './SearchInput.module.scss';
import search from '../../../assets/icons/searchSvg.svg';
import { useTranslation } from 'react-i18next';
import SearchItem from '../searchItem/SearchItem';
import DOMPurify from 'dompurify';

const SearchInputAnimated = ({ isNavigation, data }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const inputRef = useRef(null);
  let filteredData = data?.reduce((acc, item) => {
    const itemTitleMatch = t(item?.title)
      ?.toLowerCase()
      ?.includes(inputValue.toLowerCase());

    const childrenTitleMatch = item?.children?.some((child) =>
      t(child?.title)?.toLowerCase()?.includes(inputValue.toLowerCase())
    );

    if (itemTitleMatch || childrenTitleMatch) {
      acc.push(item);
    }

    return acc;
  }, []);
  const clearInput = (e) => {
    e.stopPropagation();

    setInputValue('');
    setIsActive(true);
    setSearchDropdown(false);
    window.headerSearchIsOpen = false;
  };
  const handleMouseLeave = () => {
    if (!inputRef.current.contains(document.activeElement)) {
      setIsActive(false);
    }
  };

  const handleMouseEnter = () => {
    setIsActive(true);
  };
  const checkIfDropdownIsOpen = () => {
    setIsActive(true);
    setTimeout(() => {
      if (!isSearching) {
        setSearchDropdown(false);
        window.headerSearchIsOpen = false;
      }
    }, 300);
  };
  useEffect(() => {
    if (searchDropdown) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [searchDropdown]);
  const handleInputChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value);
    setInputValue(sanitizedValue);
  };
  return (
    <>
      <div className={styles['input-container']}>
        <div
          className={`${styles['input']} ${
            searchDropdown ? styles['with-dropdown'] : ''
          }`}
          data-active={isActive}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={` ${t('common.search')}`}
            onFocus={() => setIsActive(true)}
            onBlur={checkIfDropdownIsOpen}
            onClick={() => setSearchDropdown(!searchDropdown)}
            style={{ textAlign: isRtl ? 'right' : '' }}
          />
          {isActive && (
            <div
              className={styles['clear-button']}
              onClick={clearInput}
              onMouseEnter={handleMouseEnter}
              onMouseDown={(e) => e.preventDefault()}
              style={{ left: isRtl ? '20px' : '' }}
            >
              X
            </div>
          )}
        </div>
        {!isActive && (
          <div
            className={styles['svg-container']}
            onMouseEnter={handleMouseEnter}
          >
            <img src={search} alt="Search Icon" />
          </div>
        )}

        {searchDropdown && isNavigation && (
          <div
            className={styles['search-dropdown']}
            onMouseEnter={() => (window.isHoveringCalendar = true)}
            onMouseLeave={() => (window.isHoveringCalendar = false)}
          >
            <div className={`${styles['dropdown']} ${isRtl ? 'rtl' : ''}`}>
              {filteredData?.map((val, index) => {
                return (
                  <SearchItem
                    key={index}
                    itemData={val}
                    setIsSearching={setIsSearching}
                    closeNav={setSearchDropdown}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const SearchInput = ({
  inputClassname,
  onChange,
  disabled,
  isNavigation,
  value,
  data,
  hasClear,
  isMobile,
  color,
  onClear,
}) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const inputRef = useRef(null);

  let filteredData = data?.filter((item) => {
    const search = t(item?.title);
    return search?.toLowerCase()?.includes(inputValue.toLowerCase());
  });

  const clearInput = (e) => {
    e.stopPropagation();
    setInputValue('');
    setIsActive(true);
    setSearchDropdown(false);
    setIsSearching(false);
    if (onClear) onClear('');
  };

  const handleMouseLeave = () => {
    if (!inputRef.current.contains(document.activeElement)) {
      setIsActive(false);
    }
  };
  const handleInputChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value);
    setInputValue(sanitizedValue);
    if (onChange) onChange(e);
  };
  const handleMouseEnter = () => {
    setIsActive(true);
  };
  return (
    <div
      className={`${styles['unanimated-search']} ${inputClassname} ${
        isRtl ? 'rtl' : ''
      }`}
      onMouseEnter={() => (window.isHoveringCalendar = true)}
      onMouseLeave={() => (window.isHoveringCalendar = false)}
    >
      <div
        className={`${styles['input']} `}
        data-active={isActive}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <input
          ref={inputRef}
          value={inputValue}
          disabled={disabled}
          onChange={handleInputChange}
          placeholder={`${t('common.search')}`}
          onFocus={() => setIsActive(true)}
          onClick={() => setSearchDropdown(!searchDropdown)}
        />
        {isActive && hasClear && (
          <div
            className={
              isRtl ? styles['clear-button-rtl'] : styles['clear-button']
            }
            onClick={clearInput}
            onMouseEnter={handleMouseEnter}
            onMouseDown={(e) => e.preventDefault()}
          >
            <span style={{ color: color || 'green' }}>X</span>
          </div>
        )}
      </div>
      {(isNavigation && searchDropdown) || isMobile ? (
        <div className={styles['search-dropdown-mobile']}>
          {filteredData?.map((val, index) => (
            <SearchItem
              key={index}
              itemData={val}
              setIsSearching={setIsSearching}
              isMobile={isMobile}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export { SearchInputAnimated, SearchInput };
