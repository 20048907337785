const navigationData = [
  { label: 'common.Overview', id: 1 },
  {
    label: 'Major.Curiculum_title',
    id: 2,
  },
  {
    label: 'Major.admissions_title',
    id: 3,
  },
  {
    label: 'Major.Fees_title',
    id: 4,
  },
];

export { navigationData };
