import React, { useEffect, useState } from 'react';
import calendar from '../../../assets/icons/calendar.svg';
import clock from '../../../assets/icons/clock.svg';
import location from '../../../assets/icons/location-pin.svg';
import styles from './ReadMoreModal.module.scss';
import Modal from '../../hoc/modal/Modal';
import Text from '../../atoms/text/Text';
import Carousel from '../../molecule/carousel/Carousel';
import defaultImage from '../../../assets/images/defaultNews.png';
import HtmlTag from '../../molecule/htmlTag/HtmlTag';
import closeSvg from '../../../assets/icons/close.svg';
import { useTranslation } from 'react-i18next';

const Slide = ({ image }) => {
  const handleError = (e) => {
    e.target.src = defaultImage;
  };

  return (
    <div className={styles['image']}>
      <img
        className={styles['img']}
        src={image}
        alt="read more image"
        onError={handleError}
      />
    </div>
  );
};
const ReadMoreModal = ({
  isOpen,
  closeModal,
  image,
  subtitle,
  title,
  description,
  categoryName,
  date,
  time,
  locationLink,
  isTitled,
  isDate,
  address,
  isAddress,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  useEffect(() => {
    if (isOpen) window.isHoveringCalendar = true;
    return () => {
      window.isHoveringCalendar = false;
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="My Modal Title"
      modalBodyClassname={styles.modalBody}
      dialogClassName={styles.dialogClassName}
    >
      <div className={styles['read-more-modal']}>
        {image?.length > 1 ? (
          <div className={styles.carouselContainer}>
            <Carousel
              components={
                image?.map((item, index) => (
                  <Slide key={index} image={item} />
                )) || []
              }
              style={{ height: '100%' }}
              hasArrows={image?.length > 1 ? true : true}
              hasDots={false}
              nextStyle={styles.nextArrow}
              prevStyle={styles.prevArrow}
              className={styles['carousel-container']}
            />
          </div>
        ) : (
          <Slide image={image} />
        )}

        <div className={styles['content']}>
          {isTitled ? (
            <div className={styles.titles}>
              <Text
                svg={calendar}
                svgPosition="left"
                title={date}
                variant="text-bold-italic-20-black"
                className={styles.text}
                fill="brightness(0) saturate(100%)"
              />
              <Text
                svgPosition={isRtl ? 'right' : 'left'}
                svg={clock}
                title={time}
                variant="text-bold-italic-20-black"
                className={styles.text}
                fill="brightness(0) saturate(100%)"
                style={{ direction: 'ltr' }}
              />
              <Text
                title={categoryName}
                variant="text-bold-italic-20-black"
                className={styles.text}
                fill="brightness(0) saturate(100%)"
              />
            </div>
          ) : null}
          {isAddress ? (
            <Text
              title={address}
              variant="text-bold-italic-20-black"
              className={styles.text}
              fill="brightness(0) saturate(100%)"
            />
          ) : null}
          {locationLink ? (
            <Text
              svg={location}
              svgPosition="left"
              title={locationLink}
              variant="text-bold-italic-20-black"
              className={styles.text}
              fill="brightness(0) saturate(100%)"
            />
          ) : null}
          <div className={styles.desc}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text
                title={subtitle}
                className={styles.text}
                variant="text-bold-italic-16"
                fill="brightness(0) saturate(100%)"
                color="black"
                // maxLines={20}
              />
              {isDate ? (
                <Text
                  // svg={calendar}
                  svgPosition="left"
                  title={date}
                  variant="text-regular-14"
                  className={styles.text}
                  fill="brightness(0) saturate(100%)"
                  color="black"
                />
              ) : null}
            </div>

            <Text
              title={title}
              variant="text-bold-italic-15"
              color="black"
              className={styles.text}
              fill="brightness(0) saturate(100%)"
            />
            {description ? (
              <HtmlTag
                title={description}
                variant="text-regular-16"
                fill="brightness(0) saturate(100%)"
                color="black"
              />
            ) : null}
          </div>
        </div>
        <img src={closeSvg} className={styles.close} onClick={closeModal} />
      </div>
    </Modal>
  );
};

export default ReadMoreModal;
