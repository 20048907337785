import React, { useEffect } from 'react';
import styles from './MajorCard.module.scss';
import Text from '../../atoms/text/Text';
import employee from '../../../assets/icons/employee.svg';
import forAll from '../../../assets/icons/majorCard/forAll.svg';
import { useNavigate } from 'react-router-dom';

const MajorCard = ({
  title,
  titleEN,
  className,
  titleClassName,
  id,
  isCadet,
}) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    const formattedName = titleEN?.replace(/\s+/g, '-').toLowerCase();
    navigate(`/degree-programs/degree/${formattedName}/${id}`);
  };
  return (
    <div
      className={`${styles['major-card']} ${className}`}
      onClick={navigateTo}
    >
      <div className={`${styles['major-title']} ${titleClassName}`}>
        <Text
          title={title}
          variant="text-bold-italic-20"
          style={{ textTransform: 'uppercase', cursor: 'pointer' }}
          textContainerStyle={{ textAlign: 'center' }}
          maxLines={2}
          centered
        />
      </div>
      <div className={styles['major-wrapper']}>
        <div className={styles['major']}>
          <div
            className={`${styles['svg-container']} ${isCadet !== 1 ? styles['emp-image'] : ''}`}
          >
            <img
              className={styles['image']}
              src={isCadet === 1 ? employee : forAll}
              alt=""
            />
            <Text
              title={isCadet === 1 ? 'home.students_cadet' : 'home.forAll'}
              variant="text-bold-italic-16"
              className={styles['text-view']}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MajorCard;
