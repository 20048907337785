import React from 'react';
import styles from './Label.module.scss';
import Text from '../text/Text';
import { useTranslation } from 'react-i18next';

const Label = ({
  label,
  style,
  required,
  error,
  styleType,
  textVariant,
  color,
  type,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  return (
    <div
      className={`${styles['label']} ${isRtl ? styles.labelRtl : ''} ${
        styleType ? styles[styleType] : ''
      }`}
    >
      <Text
        title={label}
        variant={textVariant || 'text-bold-italic-16-black'}
        style={style}
        className={`${styles['text']} ${error ? styles['text-error'] : ''}`}
        color={color}
      />
      {required ? (
        <span className={styles['required']}>
          <span>*</span>
        </span>
      ) : null}
    </div>
  );
};

export default Label;
