import React, { Fragment, useEffect, useMemo } from 'react';
import styles from './CampusLife.module.scss';
import { useSelector } from 'react-redux';
import Text from '../../../atoms/text/Text';
import { ToggleList } from '../../../molecule/ToggleList/ToggleList';
import campusLifeVideo from '../../../../assets/video/360video.mp4';
import ScrollReveal from 'scrollreveal';
import LazyVideo from '../../../atoms/lazyVideo/LazyVideo';
import Backdrop from '../../../atoms/backdrop/Backdrop';
import defaultImage from '../../../../assets/images/defaultNews.png';
const SECTION_ID = 4;
const CampusLife = ({ currentSection }) => {
  useEffect(() => {
    ScrollReveal().reveal('#text', {
      delay: 100,
      duration: 500,
      easing: 'ease-in-out',
      opacity: 0,
      scale: 0.9,
      distance: '20px',
      reset: true,
    });
    ScrollReveal().reveal('#toggle >*', {
      delay: 500,
      duration: 500,
      easing: 'ease-in-out',
      opacity: 0,
      scale: 0.9,
      distance: '20px',
      reset: true,
    });
  }, []);
  const toggleData = [
    {
      id: 1,
      title: 'home.support',
      route: '/academics/academic-support',
      direction: 'left',
    },

    {
      id: 2,
      title: 'home.facilities',
      route: '/students-affairs/facilities',
      direction: 'right',
    },
    // {
    //   id: 3,
    //   title: 'home.council',
    //   route: '/council/overview',
    //   isLink: '/route',

    //   direction: 'right',
    // },
    {
      id: 4,
      title: 'home.research',
      route: '/research',
      direction: 'right',
    },
    // {
    //   id: 5,
    //   title: 'home.lesson_to_learn',
    //   route: '/route',
    //   direction: 'right',
    // },
  ];
  const loadVideo =
    currentSection >= SECTION_ID - 1 && currentSection <= SECTION_ID + 1;

  const lazyVideoElement = useMemo(() => {
    return (
      <LazyVideo
        src={campusLifeVideo}
        isPaused={currentSection !== SECTION_ID}
      />
    );
  }, [currentSection]);

  return (
    <div
      className={styles['campus-life']}
      style={{ backgroundImage: defaultImage }}
    >
      {loadVideo ? lazyVideoElement : null}

      <Backdrop />
      <Text
        id="text"
        title="home.campus_life"
        variant="text-bold-italic-80"
        style={{ textTransform: 'uppercase' }}
        isLineBreak
        className="slide-in"
      />
      <div className={styles['program']}>
        <div id="toggle" className={styles['toggle-container']}>
          <ToggleList data={toggleData} isNavigable />
        </div>
      </div>
    </div>
  );
};

export default CampusLife;
