import React, { useEffect } from 'react';
import styles from './AboutTheAcademy.module.scss';
import { useSelector } from 'react-redux';
import Text from '../../../atoms/text/Text';
import { ToggleList } from '../../../molecule/ToggleList/ToggleList';
//import campusLifeVideo from '../../../../assets/video/360video.mp4';
import backgroundImage from '../../../../assets/images/home/about-the-academy/about-the-academy.jpg';
import ScrollReveal from 'scrollreveal';
import Backdrop from '../../../atoms/backdrop/Backdrop';
import ImageWithBackdrop from '../../../molecule/imageWithBackdrop/ImageWithBackdrop';

const AboutTheAcademy = () => {
  useEffect(() => {
    ScrollReveal().reveal('#text', {
      delay: 100,
      duration: 500,
      easing: 'ease-in-out',
      opacity: 0,
      scale: 0.9,
      distance: '20px',
      reset: true,
    });
    ScrollReveal().reveal('#toggle >*', {
      delay: 500,
      duration: 500,
      easing: 'ease-in-out',
      opacity: 0,
      scale: 0.9,
      distance: '20px',
      reset: true,
    });
  }, []);
  const toggleData = [
    {
      id: 1,
      title: 'home.history',
      route: '/about/history',
    },
    {
      id: 2,
      title: 'home.team',
      route: '/about/team',
    },
    {
      id: 3,
      title: 'home.leadership',
      route: '/about/leadership',
    },
    {
      id: 4,
      title: 'home.graduation',
      route: '/about/graduation',
    },
  ];
  return (
    <div className={styles['about-the-academy']}>
      <ImageWithBackdrop
        src={backgroundImage}
        alt="about the academy"
        backdropStyle={{ zIndex: -1 }}
        isAnimated
      />
      <Text
        id="text"
        title="home.about_the_academy"
        variant="text-bold-italic-80"
        style={{ textTransform: 'uppercase' }}
        isLineBreak
        className="slide-in"
      />
      <div className={styles['program']}>
        <div id="toggle" className={styles['toggle-container']}>
          <ToggleList data={toggleData} isNavigable />
        </div>
      </div>
    </div>
  );
};

export default AboutTheAcademy;
