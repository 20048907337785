import React, { useEffect, useState } from 'react';
import rightArrow from '../../../../assets/icons/linkRightArrow.svg';
import styles from './Factory.module.scss';
import Text from '../../../atoms/text/Text';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeAllModals } from '../../../../app/globals/slice/sideModalSlice/SideModalSlice';
import { useTranslation } from 'react-i18next';

const Menu = ({ data, className, onMouseEnter }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const [activeLink, setActiveLink] = useState(null);

  const navigateTo = (item) => {
    dispatch(closeAllModals());
    setTimeout(() => {
      navigate(item.route, {
        state: item.routeState,
      });
    }, 500);
  };

  const handleMouseEnter = (index) => {
    setActiveLink(index);
    onMouseEnter(index);
  };

  return (
    <div
      className={`${styles.menu} ${className ?? ''} ${
        isRtl ? styles['rtl-menu'] : ''
      } ${activeLink ? styles.borderRight : null}`}
    >
      {data?.map((item, index) => {
        if (item.hidden) return null;
        return (
          <Text
            key={index}
            variant="text-bold-italic-18"
            title={item.title}
            style={{ textTransform: 'uppercase' }}
            svg={item.children ? rightArrow : null}
            svgPosition="right"
            className={`${styles.link} ${activeLink === index ? styles['active-link'] : ''}`}
            onClick={() => navigateTo(item)}
            onMouseEnter={() => handleMouseEnter(index)}
          />
        );
      })}
    </div>
  );
};

const Navigation = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let gridClass = styles['sub-menu'];

  if (data && data[2]?.children) {
    gridClass = `${styles['sub-menu']} ${styles['flexed-row']}`;
  }
  const handleNav = (item, isChild) => {
    dispatch(closeAllModals());

    setTimeout(() => {
      navigate(item.route, {
        state: item.routeState,
        replace: item.shouldReplace,
      });
    }, 500);
  };
  return (
    <div className={styles.navigation}>
      <div className={gridClass}>
        {data?.map((child, index) => {
          if (child?.hidden) return null;
          if (child.children) {
            return (
              <div className={styles['inner-child-container']} key={index}>
                <Text
                  variant="text-bold-italic-18"
                  title={child?.title}
                  className={styles['child-link']}
                  style={{ textTransform: 'uppercase' }}
                  onClick={() => handleNav(child)}
                />
                {child?.children?.map((sub, subIndex) =>
                  sub.title ? (
                    <div
                      key={subIndex}
                      className={styles['inner-subs-container']}
                    >
                      <Text
                        variant="text-regular-14"
                        title={sub?.title}
                        className={styles['child-link']}
                        style={{ textTransform: 'uppercase' }}
                        onClick={() => {
                          handleNav(sub, true, {
                            state: {
                              id: 'baf66628-ff3b-4b40-ac0d-1ac43e089a59',
                            },
                          });
                        }}
                      />
                    </div>
                  ) : null
                )}
              </div>
            );
          } else {
            return (
              <Text
                key={index}
                variant="text-regular-16"
                title={child?.title}
                className={styles['child-link']}
                style={{ textTransform: 'uppercase' }}
                onClick={() => handleNav(child)}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export { Menu, Navigation };
