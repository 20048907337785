import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './SideModal.module.scss';
import RoundedButton from '../../atoms/roudedButton/RoundedButton';
import closeBtn from '../../../assets/icons/close.svg';
import Text from '../../atoms/text/Text';
import { closeAllModals } from '../../../app/globals/slice/sideModalSlice/SideModalSlice';
import { useTranslation } from 'react-i18next';

const SideModal = ({
  isbackdropped,
  children,
  style,
  title,
  isFromTop,
  isOpenModal,
  onClose,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const dispatch = useDispatch();
  const [shouldRender, setShouldRender] = useState(isOpenModal);
  const [isAnimated, setIsAnimated] = useState(false);

  // this use effect is to make sure the SideModal doesn't appear in the DOM, until we open it
  // adding the animation with it
  useEffect(() => {
    let animationTimeoutId;
    let renderTimeoutId;

    if (isOpenModal) {
      setShouldRender(true);
      // window.isHoveringCalendar = true;
      animationTimeoutId = setTimeout(() => {
        setIsAnimated(true);
      }, 100);
      document.body.style.overflow = 'hidden';
    } else {
      setIsAnimated(false);
      // window.isHoveringCalendar = false;
      renderTimeoutId = setTimeout(() => {
        setShouldRender(false);
      }, 500);
    }

    return () => {
      clearTimeout(animationTimeoutId);
      clearTimeout(renderTimeoutId);
      if (isOpenModal) {
        document.body.style.overflow = '';
      }
    };
  }, [isOpenModal]);

  const handleClose = () => {
    onClose && onClose();
    dispatch(closeAllModals());
  };

  const modalClassName = isFromTop
    ? `${styles['side-modal-top']} ${isAnimated ? styles.open : ''}`
    : `${styles['side-modal']} ${isAnimated ? styles.open : ''}`;

  const backdropClassName = isOpenModal
    ? `${styles.backdrop} ${styles.open}`
    : styles.backdrop;

  if (!shouldRender) return null;

  return (
    <>
      <div
        style={style}
        className={modalClassName}
        onMouseEnter={() => (window.isHoveringCalendar = true)}
        onMouseLeave={() => (window.isHoveringCalendar = false)}
      >
        <div
          className={`${styles['modal-content']} ${isRtl ? styles.rtl : null}`}
        >
          <div className={`${styles['modal-header']} ${isRtl ? 'rtl' : null}`}>
            <Text
              variant="text-bold-italic-20"
              title={title}
              style={{ textTransform: 'uppercase' }}
              className={styles.title}
            />
            <div className={styles['modal-close']}>
              <RoundedButton
                svg={closeBtn}
                onClick={handleClose}
                classname={styles.closeSvg}
              />
            </div>
          </div>
          {children}
        </div>
      </div>
      {isOpenModal && isbackdropped ? (
        <div className={backdropClassName} onClick={handleClose}></div>
      ) : null}
    </>
  );
};

export default SideModal;
