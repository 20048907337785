const determineActiveStep = (state) => {
  const steps = [
    'stepOne',
    'stepTwo',
    'stepThree',
    'stepFour',
    'stepFive',
    'stepSix',
  ];
  for (let i = steps?.length - 1; i >= 0; i--) {
    if (state[steps[i]] && Object.keys(state[steps[i]]).length > 0) {
      return i + 1;
    }
  }
  return 0;
};

export default determineActiveStep;
