import React from 'react';
import styles from './StudentsInfo.module.scss';
import Text from '../../atoms/text/Text';
import Backdrop from '../../atoms/backdrop/Backdrop';
import HtmlTag from '../htmlTag/HtmlTag';
import { useMediaQuery } from 'react-responsive';

const StudentsInfo = ({
  title,
  description,
  fontSize,
  doubleQuoteLeft,
  doubleQuoteRight,
  containerClass,
  footerText,
  date,
  isTestimonial,
  showData = true,
}) => {
  const containerClasses = `${styles['students-info']} ${containerClass || ''}`;
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <div
      className={`${containerClasses} ${
        isTestimonial ? styles['testimonial'] : ''
      }`}
    >
      <img
        className={styles['left-quotes']}
        src={doubleQuoteLeft}
        alt="Left Quote"
      />
      <img
        className={styles['right-quotes']}
        src={doubleQuoteRight}
        alt="Right Quote"
      />
      <div className={styles['right-border']}></div>
      <div className={styles['left-border']}></div>
      <Text
        title={title}
        fontSize={fontSize}
        variant="text-bold-italic-20"
        style={{ textTransform: 'uppercase' }}
      />
      <div className={styles['description']}>
        <HtmlTag
          title={description}
          fontSize={fontSize}
          variant="text-regular-18"
          externalLineHeight={isTablet ? 1.2 : 2}
          maxLines={isMobile ? 16 : isTablet ? 20 : null}
        />
      </div>
      {showData && (
        <div className={styles['footer']}>
          <Text
            title={footerText}
            fontSize={fontSize}
            variant="text-regular-16"
          />
          {isTestimonial && (
            <Text title={date} fontSize={fontSize} variant="text-regular-14" />
          )}
          {!isTestimonial && <br />}
        </div>
      )}
    </div>
  );
};

export default StudentsInfo;
