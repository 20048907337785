import { createSlice } from '@reduxjs/toolkit';

const CustomerFaceSlice = createSlice({
  name: 'customerFace',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setCustomerFaceState: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setCustomerFaceState } = CustomerFaceSlice.actions;
export default CustomerFaceSlice.reducer;
