import { createSlice } from '@reduxjs/toolkit';

const ApplyScolarshipSlice = createSlice({
  name: 'applyNow',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setApplyScolarshipState: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setApplyScolarshipState } = ApplyScolarshipSlice.actions;
export default ApplyScolarshipSlice.reducer;
