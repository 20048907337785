import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorPath: null,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    navigateToError(state, action) {
      state.errorPath = action.payload;
    },
    resetErrorPath(state) {
      state.errorPath = null;
    },
  },
});

export const { navigateToError, resetErrorPath } = navigationSlice.actions;
export default navigationSlice.reducer;
