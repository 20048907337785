import React from 'react';
import styles from './Stepper.module.scss';
import logo from '../../../assets/icons/logo.svg';
import Text from '../../atoms/text/Text';
import checked from '../../../assets/icons/justsvg.svg';

const Stepper = ({ activeStep, steps, isLastStep }) => {
  return (
    <div className={styles['stepper-container']}>
      <div className={styles['header']}>
        <div className={styles['logo']}>
          <img src={logo} alt="Logo" />
        </div>
        <div className={styles['stepper']}>
          {steps.map((step, index) => (
            <div className={styles['step-container']} key={index}>
              {activeStep + 1 > step.step ? (
                <img className={styles['checked']} src={checked} alt="alt" />
              ) : (
                <div
                  className={`${styles['step']} ${
                    index === activeStep ? styles['active'] : styles['inactive']
                  }`}
                >
                  <Text
                    title={step.step}
                    variant="text-bold-regular-25"
                    color="black"
                  />
                </div>
              )}

              <Text
                title={step.title}
                variant="text-regular-16"
                className={
                  index !== activeStep
                    ? `${styles['text']} ${styles.hide}`
                    : styles['text']
                }
                color={index === activeStep ? 'white' : ''}
              />
              <div className={!isLastStep ? styles['separator'] : ''}></div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles['body-container']}>
        <div className={styles['body']}> {steps[activeStep]?.content} </div>
        {/* {activeStep === 2 ? (
          <div className={styles['footer']}>
            <Text
              title="registrationForm.did_you_find_program"
              link="registrationForm.CLICK_HERE"
              variant="text-bold-italic-16-black"
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

export default Stepper;
