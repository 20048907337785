import React, { useState } from 'react';
import playSvg from '../../../../../assets/icons/video/play-ic.svg';
import Text from '../../../../../components/atoms/text/Text';

function Dash({ year, isShort, isActive, onClick, hasVideo }) {
  const [isClickable, setIsClickable] = useState(true);
  const barClass = isActive
    ? 'year_bar_active'
    : isShort
      ? 'year_bar_short'
      : 'year_bar_long';

  const handleClick = () => {
    if (!isClickable) return;
    onClick();
    setIsClickable(false);
    setTimeout(() => {
      setIsClickable(true);
    }, 500);
  };

  return (
    <div className="year_li" onClick={handleClick}>
      <li className="year_li">
        <Text
          title={year}
          variant="text-bold-italic-20"
          style={{ opacity: isActive ? 1 : 0 }}
        />

        <div className={barClass}>
          {hasVideo ? <img src={playSvg} className="dashes-image" /> : null}
        </div>
      </li>
    </div>
  );
}

export default Dash;
