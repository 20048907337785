import React, { useState } from 'react';
import styles from './ApplyNow.module.scss';
import Modal from '../../hoc/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setApplyNowState } from '../../../app/globals/slice/applyNowModalSlice/ApplyNowSlice';
import logo from '../../../assets/icons/logo.svg';
import Text from '../../atoms/text/Text';
import Button from '../../atoms/button/Button';
import { useNavigate } from 'react-router-dom';
import RadioButton from '../../atoms/radioButton/RadioButton';
import { useTranslation } from 'react-i18next';
const optionsArray = [
  { label: 'ApplyNow.Show_Interest', value: 'ApplyNow.Show_Interest' },
  { label: 'ApplyNow.Apply_Now', value: 'ApplyNow.Apply_Now' },
];

const ApplyNow = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const navigate = useNavigate();
  const isOpen = useSelector((state) => state.applyNow.isOpen);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setApplyNowState(false));
  };

  const [selectedOption, setSelectedOption] = useState('ApplyNow.Apply_Now');
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const navigateTo = (type) => {
    closeModal();
    let routeState = type === 'grad' ? 1 : null;
    setTimeout(() => {
      const route =
        selectedOption === 'ApplyNow.Show_Interest'
          ? '/register'
          : type === 'grad'
            ? '/application/graduate'
            : '/application/undegraduate';
      navigate(route, { state: routeState ?? 0 });
    }, 400);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      dialogClassName={styles.modalClass}
    >
      <div className={styles['apply-now']}>
        <div className={styles['logo']}>
          <img src={logo} alt="Logo" />
        </div>
        <div className={styles['actions']}>
          <Text
            title="ApplyNow.selected_Degree"
            variant="text-bold-italic-20-black"
            style={{
              textTransform: 'uppercase',
              textAlign: isRtl ? 'right' : '',
            }}
          />
          <div style={{ textAlign: isRtl ? 'right' : '' }}>
            <Text
              title="ApplyNow.Sending_As"
              variant="text-bold-italic-20-black"
            />
            <RadioButton
              style={{ marginTop: '10px' }}
              options={optionsArray}
              textVariant={'text-bold-italic-16-black'}
              value={selectedOption}
              onOptionChange={(selectedOption) =>
                handleOptionChange(selectedOption)
              }
              direction="row"
            />
          </div>

          {/* <div className={styles['radio-container']}>
            <label>
              <input
                type="radio"
                name="sendingOption"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={() => handleOptionChange('option1')}
              />
              <Text
                title="Sending As :"
                
                variant="text-regular-16-black"
              />
            </label>
            <label>
              <input
                type="radio"
                name="sendingOption"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={() => handleOptionChange('option2')}
              />
              <Text
                title="Apply Now"
                
                variant="text-regular-16-black"
              />
            </label>
          </div> */}
          <div className={styles['buttons-container']}>
            <Button
              title="ApplyNow.UnderGraduate_Application"
              className={styles['button']}
              variant="text-regular-16"
              styleType="secondary"
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigateTo('undergrad')}
            />
            <Button
              title="ApplyNow.Graduate_Application"
              className={styles['button']}
              variant="text-regular-16"
              styleType="secondary"
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigateTo('grad')}
            />
          </div>
          {/* <div className={styles['text-container']}>
            <Text
              title="Do you Already have an account?"
              
              variant="text-regular-18-black"
            />
            <Text
              title="Login Here"
              
              variant="text-regular-18-black"
              style={{ textTransform: 'uppercase', color: 'red' }}
              className={styles['text']}
            />
          </div> */}
        </div>
        <div className={styles['footer']}></div>
      </div>
    </Modal>
  );
};

export default ApplyNow;
