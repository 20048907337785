import React, { useEffect, useState } from 'react';
import styles from './TextArea.module.scss';
import Text from '../text/Text';
import Label from '../lable/Label';
import DOMPurify from 'dompurify';
function TextArea({
  label,
  required,
  styleType,
  name,
  value,
  onChange,
  onBlur,
  style,
  error,
  placeholder,
  labelStyle,
  rows = 4,
  textAreaStyle,
}) {
  const inputClassName = `${styles.input} ${styles[styleType]} ${
    error ? styles['input-error'] : ''
  }`;
  const [initialValue, setInitialValue] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const sanitizedValue = DOMPurify.sanitize(newValue);

    setInitialValue(sanitizedValue);
    if (name) {
      if (onChange) {
        onChange(name, sanitizedValue);
      }
    } else {
      if (onChange) {
        onChange(sanitizedValue);
      }
    }
  };

  useEffect(() => {
    setInitialValue(value); // Update local state if external value changes
  }, [value]);

  return (
    <div className={styles['text-area']} style={style}>
      {label && (
        <Label
          label={label}
          required={required}
          error={error}
          style={labelStyle}
          styleType={styleType}
        />
      )}
      <textarea
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        value={initialValue}
        rows={rows}
        style={textAreaStyle}
      />
      {error && (
        <Text
          title={error}
          variant="text-regular-15"
          className={styles['text']}
          style={{ color: 'red' }}
        />
      )}
    </div>
  );
}

export default TextArea;
