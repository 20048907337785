import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import styles from './CustomSelect.module.scss';
import Label from '../lable/Label';
import selectArrow from '../../../assets/icons/selectArrow.svg';
import Text from '../text/Text';
import { useTranslation } from 'react-i18next';
import endpoints from '../../../api/endpoints';
import { useGetQuery } from '../../../services/apiCall';

const CustomSelect = ({
  label,
  type,
  isSkiped,
  required,
  style,
  error,
  name,
  value,
  onChange,
  onBlur,
  degreeTypes,
  className,
  isEmployed,
  isAcademicYear,
  parent,
  isAcademic,
  isDegree,
  backgroundColor = 'white',
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  //For dropdown
  const apiUrl = endpoints.dropdown.getDropdownByType;
  const params = [type];
  //for parent dropdown
  const apiUrlParent = endpoints.dropdown.getDropDownFromParent;
  const paramsPArent = [type, parent];
  const controlRef = useRef(null);
  //for academics
  const apiUrlAcademics = endpoints.dropdown.selectedTerms;
  //degree
  const apiUrlDegree = endpoints.dropdown.degree;

  const apiUrlAcademicYear = endpoints.settings.getAcademicYears;
  //bolean data
  const boleanOptions = [
    { value: true, text_EN: 'Yes', text_AR: 'نعم' },
    { value: false, text_EN: 'No', text_AR: 'كلا' },
  ];
  ///fetched dropdown data by type
  const {
    data: data,
    error: apiError,
    isLoading,
    refetch,
  } = useGetQuery(
    {
      apiUrl: apiUrl,
      params: params,
    },
    { skip: isSkiped || parent || isAcademic || isDegree }
  );
  ///fetched dropdown data by type and parent
  const { data: parentData, refetch: refetchParentData } = useGetQuery(
    {
      apiUrl: apiUrlParent,
      params: paramsPArent,
    },
    { skip: !parent }
  );

  // fetched dropdown academics
  const { data: academicData } = useGetQuery(
    {
      apiUrl: apiUrlAcademics,
    },
    {
      skip: !isAcademic,
    }
  );
  // fetched dropdown academics years
  const { data: academicYearsData } = useGetQuery(
    {
      apiUrl: apiUrlAcademicYear,
    },
    {
      skip: !isAcademicYear,
    }
  );

  //fetched dropdown degree
  const { data: degreeData } = useGetQuery(
    {
      apiUrl: apiUrlDegree,
    },
    {
      skip: !isDegree,
    }
  );
  ///formatted options
  const formattedData = data?.map((item) => ({
    value: item.value,
    label: currentLanguage === 'en' ? item.text_EN : item.text_AR,
  }));

  const formattedBoleanData = boleanOptions?.map((item) => ({
    value: item.value,
    label: currentLanguage === 'en' ? item.text_EN : item.text_AR,
  }));

  const formattedParentData = parentData?.map((item) => ({
    value: item.value,
    label: currentLanguage === 'en' ? item.text_EN : item.text_AR,
  }));

  const formattedAcademicData = academicData?.map((item) => ({
    value: item.academicTermId,
    label: item.shortTermName,
  }));

  const formattedDegreeData = degreeData?.items?.map((item) => ({
    value: item.degreeId,
    label: currentLanguage === 'en' ? item?.degreeNameEn : item?.degreeNameAr,
    degreeType: item.degreeType,
  }));
  const formattedAcademicYearData = academicYearsData?.map((item) => ({
    value: item.acadamicYearId,
    label: currentLanguage === 'en' ? item?.academicName : item?.academicYearAR,
    degreeType: item.degreeType,
  }));
  const formattedisEmployedData = data?.map((item) => ({
    value: item.value,
    label: currentLanguage === 'en' ? item.text_EN : item.text_AR,
  }));

  let optionsSelected = isSkiped
    ? formattedBoleanData
    : parent
      ? formattedParentData
      : isAcademic
        ? formattedAcademicData
        : isDegree
          ? formattedDegreeData
          : isAcademicYear
            ? formattedAcademicYearData
            : isEmployed
              ? formattedisEmployedData
              : formattedData;

  const openSelect = () => {
    if (controlRef.current) {
      controlRef.current.focus();
    }
  };
  if (formattedDegreeData && degreeTypes) {
    optionsSelected = formattedDegreeData.filter((item) =>
      degreeTypes.includes(item.degreeType)
    );
    console.log('sadasdsadsasaddddddd', optionsSelected);
  }
  const CustomDropdownArrow = () => {
    return (
      <div className={styles['indicator']}>
        <img src={selectArrow} />
      </div>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      opacity: 0.8,
      borderColor: error && !state.isSelected ? 'red' : provided.borderColor,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'grey' : backgroundColor,
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'grey',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
  };

  return (
    <div
      className={`${styles['select-container']} ${className ?? ''}`}
      style={style}
    >
      <div className={styles['select']}>
        <Label label={label} required={required} />
        <Select
          options={optionsSelected}
          styles={customStyles}
          placeholder=""
          ref={controlRef}
          name={name}
          value={optionsSelected?.find((option) => option.value == value)}
          onChange={(selectedOption) => {
            if (onChange) {
              onChange(name, selectedOption.value);
            }
          }}
          components={{
            DropdownIndicator: CustomDropdownArrow,
          }}
          onBlur={onBlur}
        />
        {/* <div className={styles['indicator']} onClick={openSelect}>
          <img src={selectArrow} alt="Select" />
        </div> */}
      </div>
      {error && (
        <Text
          title={error}
          variant="text-regular-15"
          className={styles['text']}
          style={{ color: 'red' }}
        />
      )}
    </div>
  );
};

export default CustomSelect;
