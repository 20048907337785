import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './ErrorPage.module.scss';
import HeaderSection from '../../components/organism/headerSection/HeaderSection';
import errorBackground from '../../assets/icons/errors/errorGradiant.svg';
import errorImage from '../../assets/images/errors/errorPage.png';
import Text from '../../components/atoms/text/Text';
import InnerButton from '../../components/atoms/innerButton/innerButton';

const ErrorPage = () => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(window.navigator.onLine);

  const handleGoBack = () => {
    if (isConnected && window.history.length > 1) {
      navigate(-1);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsConnected(window.navigator.onLine);
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  useEffect(() => {
    if (isConnected && window.history.length > 1) {
      navigate(-1);
    }
  }, [isConnected, navigate]);

  return (
    <div>
      <HeaderSection fixed />
      <div className={styles['error-page']}>
        <img
          src={errorBackground}
          className={styles.background}
          loading="lazy"
        />
        <div className={styles['error-container']}>
          <img src={errorImage} className={styles.image} loading="lazy" />
          <div className={styles['error-action']}>
            <Text
              title="common.something_wrong"
              variant="text-bold-italic-30-black"
              className={styles.text}
            />
            <InnerButton
              type="button"
              title="common.refresh_page"
              buttonClassName={styles.button}
              onClick={handleGoBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
