import React, { useState } from 'react';
import styles from './MediaCard.module.scss';
import closeSvg from '../../../assets/icons/close.svg';
import ImageWithBackdrop from '../imageWithBackdrop/ImageWithBackdrop';
import expandSVG from '../../../assets/icons/news&events/expand.svg';
import Text from '../../atoms/text/Text';
import Modal from '../../hoc/modal/Modal';
import defaultImage from '../../../assets/images/defaultNews.png';
import { sanitizeAndValidateUrl } from '../../../app/helpers/sanitizeAndValidateUrl';

function MediaCard({
  onClick,
  title,
  count,
  src,
  className,
  style,
  headingStyle,
}) {
  const sanitizedSrc = sanitizeAndValidateUrl(src) || defaultImage;

  return (
    <div
      className={`${styles['media-card']} ${className}`}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default', ...style }}
    >
      <ImageWithBackdrop
        src={sanitizedSrc}
        alt="academy in numbers"
        backdropStyle={{ borderRadius: '10px' }}
        imageStyle={{ zIndex: 0, borderRadius: '10px' }}
      />
      <div className={`${styles.headings} ${headingStyle ?? ''}`}>
        <Text title={title} variant="text-bold-20" className={styles.text} />
        <Text
          title={count}
          variant="text-bold-italic-15"
          className={styles.text}
          style={{ cursor: onClick ? 'pointer' : 'default' }}
        />
      </div>
    </div>
  );
}

function AlbumCard({ src }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const sanitizedSrc = sanitizeAndValidateUrl(src) || defaultImage;

  const handleExpandClick = () => {
    setIsExpanded(true);
    setIsAnimating(true);
  };

  const handleCloseModal = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsExpanded(false);
    }, 300);
  };

  return (
    <>
      <div className={styles['album-card']}>
        <img
          src={sanitizedSrc}
          className={styles.backImage}
          alt="Album"
          loading="lazy"
        />
        <div className={styles.heading}>
          <div className={styles.expand} onClick={handleExpandClick}>
            <img src={expandSVG} alt="Expand" loading="lazy" />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isExpanded}
        onClose={handleCloseModal}
        modalBodyClassname={styles.modalBody}
        dialogClassName={styles.dialogClassName}
      >
        <div
          className={`${styles.modalContent} ${
            isAnimating ? styles.animateIn : styles.animateOut
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={sanitizedSrc}
            alt="Expanded view"
            className={styles.expandedImage}
            loading="lazy"
          />
          <img
            src={closeSvg}
            className={styles.close}
            onClick={handleCloseModal}
          />
        </div>
      </Modal>
    </>
  );
}

export { MediaCard, AlbumCard };
