import React from 'react';
import styles from './Calendar.module.scss';
import CalendarTabs from '../../../../../../molecule/calendarTabs/CalendarTabs';
import comingSoon from '../../../../../../../assets/icons/comingsoon.png';
import comingSoonArabic from '../../../../../../../assets/icons/calendar/cominsoonArabic.svg';
import { useTranslation } from 'react-i18next';
const getComingSoonImage = (language) => {
  return language === 'ar' ? comingSoonArabic : comingSoon;
};

const Calendar = ({ type }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const calendarData = {
    general: [
      {
        title: '2023 - 2024',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/General-1.png',
      },
      {
        title: '2024 - 2025',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/General_24-25.jpg',
      },
      {
        title: '2025 - 2026',
        contentImage: getComingSoonImage(currentLanguage),
        isNull: true,
      },
    ],
    higherEducation: [
      {
        title: '2023 - 2024',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/Cadets-1.png',
      },
      {
        title: '2024 - 2025',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/HigherEducation_24-25.jpg',
      },
      {
        title: '2025 - 2026',
        contentImage: getComingSoonImage(currentLanguage),
        isNull: true,
      },
    ],
    eveningStudies: [
      {
        title: '2023 - 2024',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/EveningStudies-1.png',
      },
      {
        title: '2024 - 2025',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/EveningStudies_24-25.jpg',
      },
      {
        title: '2025 - 2026',
        contentImage: getComingSoonImage(currentLanguage),
        isNull: true,
      },
    ],
    cadetsCalendar: [
      {
        title: '2023 - 2024',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/Cadets-1.png',
      },
      {
        title: '2024 - 2025',
        contentImage:
          'https://aiducatorbloblive01.blob.core.windows.net/dpa-homepage-container/Cadets_24-25.jpg',
      },
      {
        title: '2025 - 2026',
        contentImage: getComingSoonImage(currentLanguage),
        isNull: true,
      },
    ],
  };

  const tabsData = calendarData[type] || [];

  return (
    <div className={styles['calendar-container']}>
      <CalendarTabs data={tabsData} />
    </div>
  );
};

export default Calendar;
