import React from 'react';
import styles from './RewardCardGrid.module.scss';
import RatingCard from '../../atoms/ratingCard/RatingCard';

const RewardCardGrid = ({ data }) => {
  return (
    <div className={styles['grid']}>
      <div className={styles['first-card-responsive']}>
        <RatingCard className={styles['first-div-mobile']} {...data[0]} />
      </div>
      <div className={styles['grid-container']}>
        {data.map((item, index) => (
          <RatingCard
            key={index}
            number={item.number}
            title={item.title}
            fontSize={item.fontSize}
            svg={item.svg}
          />
        ))}
      </div>
    </div>
  );
};

export default RewardCardGrid;
