import React from 'react';
import styles from './BoxButton.module.scss';
import Text from '../text/Text';

const BoxButton = ({
  fontSize,
  size,
  mode,
  content,
  subtitle,
  selected,
  onClick,
  title,
}) => {
  const buttonClassName = selected ? styles.active : styles.box;
  const textClassName = selected ? styles.active : null;
  return (
    <div className={styles['box-container']} onClick={() => onClick(mode)}>
      <div className={buttonClassName}>
        <h1 className={textClassName} style={{ fontSize: size ?? size }}>
          {content}
        </h1>
      </div>
      <div className={styles['text']}>
        <Text variant="text-bold-italic-15" title={title} />
      </div>
    </div>
  );
};

export default BoxButton;
