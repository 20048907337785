import React, { useEffect, useRef, useMemo } from 'react';
import LazyVideo from '../../../../../../components/atoms/lazyVideo/LazyVideo';
import GraduationNavigation from '../../../../../../components/organism/graduationNavigation/GraduationNavigation';
import styles from './Section.module.scss';
import historyVideo from '../../../../../../assets/video/spaceVideo.mp4';
import { useTranslation } from 'react-i18next';
const data = [
  {
    id: 0,

    history: 'cadet',
    year: '1992',
    gapTo: '1993',

    data: [
      { title: 'History.bachelor_degree_in_law', type: 'cadet', year: 1992 },
      {
        title: 'History.bachelor_degree_in_law_and_police',
        type: 'forall',
        year: 1994,
      },
      {
        title: 'History.diploma_in_police_sciences',
        type: 'cadet',
        isPrev: true,
      },
    ],
  },
  {
    id: 1,
    year: '2003',
    isNotMulty: true,
    history: 'forall',
    data: [
      { title: 'History.Master_Degree_in_criminal_sciences', type: 'forall' },
      { title: 'History.Master_degree_in_public_law', type: 'cadet' },
      {
        title: 'History.Master_degree_in_private_law',
        type: 'cadet',
      },
      // {
      //   title: 'graduation.graduating_students',
      // },
    ],
  },
  {
    id: 2,
    year: '2006',
    isNotMulty: true,
    history: 'forall',

    data: [
      {
        title: 'History.Master_degree_in_Police_science_with_focus',
        type: 'forall',
      },
      {
        title: 'History.Master_degree_in_Police_science_in_security_crises',
        type: 'cadet',
      },
      {
        title: 'History.Master_degree_internationa',
        type: 'cadet',
      },
      // {
      //   title: 'graduation.graduating_students',
      // },
    ],
  },
  {
    id: 3,
    year: '2010',
    isNotMulty: true,
    history: 'forall',

    data: [
      { title: 'History.Dectorate_in_criminal_law', type: 'cadet' },
      { title: 'History.Dectorate_in_private_law', type: 'forall' },
      {
        title: 'History.Dectorate_in_public_law',
        type: 'cadet',
      },
      // {
      //   title: 'graduation.graduating_students',
      // },
    ],
  },
  {
    id: 4,
    year: '2012',
    gapTo: '2013',

    history: 'forall',

    data: [
      {
        title: 'History.MAster_degree_in_human_right',
        type: 'cadet',
        year: 2012,
      },
      { title: 'History.MAster_degree_in_law', type: 'cadet', year: 2014 },
      // {
      //   title: 'graduation.graduating_students',
      // },
    ],
  },
  {
    id: 5,
    year: '2016',
    gapTo: '2016',
    history: 'forall',

    data: [
      { title: 'History.Launching', type: 'cadet', year: 2016 },
      { title: 'History.entrance', type: 'cadet', year: 2017 },
      {
        title: 'History.doctor',
        type: 'cadet',
        isPrev: true,
      },
      {
        title: 'History.Bachelor_Degree_in_Security_and_Criminal_Sciences',
        type: 'cadet',
        isPrev: true,
      },
      // {
      //   title: 'graduation.graduating_students',
      // },
    ],
  },
  {
    id: 6,
    year: '2020',
    gapTo: '2022',
    history: 'forall',

    data: [
      { title: 'History.the_concentrations', type: 'forall', year: 2020 },
      { title: 'History.the_academy', type: 'cadet', year: 2022 },
      // {
      //   title: 'graduation.graduating_students',
      // },
    ],
  },
];
const Section = ({ currentSection }) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const lazyVideoElement = useMemo(() => {
    return <LazyVideo src={historyVideo} isPaused={true} />;
  }, []);

  return (
    <section className={styles['our-history']}>
      {lazyVideoElement}
      <div
        style={{
          justifyContent: isRtl ? 'flex-end' : 'flex-start',
        }}
        className={styles.navContainer}
      >
        <GraduationNavigation
          title="History.specific_accreditatiom"
          data={data}
          direction={data.direction}
          fixed={true}
          active={0}
          yearsClass={styles.yearsClass}
        />
      </div>
    </section>
  );
};

export default Section;
