import React, { useState } from 'react';
import styles from './NavigatorStepper.module.scss';
import Text from '../../atoms/text/Text';

const NavigatorStepper = ({ eventsData, setSelectedStep, selectedStep }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()} ${date.getDate()}`;
  };
  const formatDay = (dateString) => {
    return dateString.split(' / ')[0];
  };

  return (
    <div className={styles.navigator}>
      {eventsData?.map((event, index) => (
        <React.Fragment key={index}>
          <div
            className={`${styles.step} ${
              index === selectedStep ? styles.selected : styles.inactive
            }`}
            onClick={() => setSelectedStep(index)}
          >
            <Text
              title={formatDate(event?.eventDate)}
              variant="text-bold-italic-16"
              style={{ textTransform: 'uppercase' }}
              className={
                index === selectedStep
                  ? styles['date-active']
                  : styles['date-inactive']
              }
            />
          </div>
          {index !== eventsData.length - 1 && (
            <div
              className={`${styles.line} ${
                index === selectedStep ? styles['active-line'] : ''
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NavigatorStepper;
