import React, { useMemo } from 'react';
import styles from './StepFive.module.scss';
import Text from '../../../../atoms/text/Text';
import List from '../../../../molecule/list/List';
import Checkbox from '../../../../atoms/checkBox/CheckBox';
import {
  IMPORTANT_NOTICES,
  PROGRAM_INFORMATION,
  getInitialValues,
  stepFiveValidationSchema,
} from './factory/formFactory';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

function StepFive({ stepRef }) {
  const storedSteps = useSelector((state) => state.steps.stepFive);
  const initialValues = useMemo(() => {
    return getInitialValues(storedSteps);
  }, [storedSteps]);

  const handleSubmit = (values) => {};
  return (
    <Formik
      innerRef={stepRef}
      initialValues={initialValues}
      validationSchema={stepFiveValidationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, errors, touched, setFieldValue, handleBlur }) => {
        return (
          <div className={styles['Step-five']}>
            <List
              title="registrationForm.StepFive.Program_Information.program_information_title"
              items={PROGRAM_INFORMATION}
              direction="column"
              style={{ gap: '4px', width: '100%' }}
              itemPaddingStart="20px"
            />
            <div>
              <Checkbox
                label="registrationForm.StepFive.Program_Information.Accept"
                checked={values.acceptedbyAcademy}
                onCheckboxChange={(isChecked) =>
                  setFieldValue('acceptedbyAcademy', isChecked)
                }
                textVariant="text-regular-16-black"
              />
              {errors?.acceptedbyAcademy && (
                <Text
                  title={errors?.acceptedbyAcademy}
                  variant="text-regular-15"
                  className={styles['text']}
                  style={{ color: 'red' }}
                />
              )}
            </div>

            <List
              title="registrationForm.StepFive.Important_Notices.Important_Notices_title"
              items={IMPORTANT_NOTICES}
              direction="column"
              style={{ gap: '4px', width: '100%' }}
              itemPaddingStart="20px"
            />
            <div>
              <Checkbox
                label="registrationForm.StepFive.Important_Notices.Accept"
                checked={values.acceptallnotices}
                onCheckboxChange={(isChecked) =>
                  setFieldValue('acceptallnotices', isChecked)
                }
                textVariant="text-regular-16-black"
              />
              {errors?.acceptallnotices && (
                <Text
                  title={errors?.acceptallnotices}
                  variant="text-regular-15"
                  className={styles['text']}
                  style={{ color: 'red' }}
                />
              )}
            </div>

            <Text
              title="registrationForm.StepFive.Reservation_ENrollment.Reservation_ENrollment_title"
              variant="text-regular-18-black"
              style={{
                textTransform: 'uppercase',
                marginTop: '2rem',
              }}
            />
            <div className={styles['checkbox-container']}>
              <div>
                <Checkbox
                  label="registrationForm.StepFive.Reservation_ENrollment.acknowledge"
                  textLink="HERE"
                  checked={values.agreementacknowledged}
                  onCheckboxChange={(isChecked) =>
                    setFieldValue('agreementacknowledged', isChecked)
                  }
                  textVariant="text-regular-16-black"
                  textColorReverse
                />
                {errors?.agreementacknowledged && (
                  <Text
                    title={errors?.agreementacknowledged}
                    variant="text-regular-15"
                    className={styles['text']}
                    style={{ color: 'red' }}
                  />
                )}
              </div>

              <div>
                <Checkbox
                  label="registrationForm.StepFive.Reservation_ENrollment.read"
                  checked={values.catalogread}
                  onCheckboxChange={(isChecked) =>
                    setFieldValue('catalogread', isChecked)
                  }
                  textVariant="text-regular-16-black"
                  textComponent={
                    <span>
                      I have read the<span> </span>
                      <span className={styles['link']}>
                        Undergraduate Catalog{' '}
                      </span>
                      online
                    </span>
                  }
                  textColorReverse
                />
                {errors?.catalogread && (
                  <Text
                    title={errors?.catalogread}
                    variant="text-regular-15"
                    className={styles['text']}
                    style={{ color: 'red' }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default StepFive;
