import React from 'react';
import styles from './MediaCenter.module.scss';
import { MediaCard } from '../../../../../../molecule/mediaCard/MediaCard';
import { useGetQuery } from '../../../../../../../services/apiCall';
import endpoints from '../../../../../../../api/endpoints';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../../../atoms/loader/Loader';
import arowDown from '../../../../../../../assets/icons/downArrow.svg';

import Text from '../../../../../../atoms/text/Text';
import Button from '../../../../../../atoms/button/Button';

const apiUrl = endpoints.news.mediaGallery;

const MediaCenter = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const { data, error, isLoading } = useGetQuery({ apiUrl });

  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (isLoading) return <Loader />;
  if (error) return <p>Failed to load galleries.</p>;

  const displayedData =
    isMobile && data ? data?.slice(0, 2) : data?.slice(0, 3);

  return (
    <div className={styles['media-container']}>
      <div className={styles['media']}>
        {displayedData &&
          displayedData.map((gallery) => (
            <MediaCard
              key={gallery.mediaGalleryId}
              title={isRtl ? gallery.galleryName_AR : gallery.galleryName_EN}
              count={`${gallery.images.length} ${isRtl ? 'صورة' : 'Pictures'}`}
              src={`${gallery.images[0]?.url}${gallery.images[0]?.imageURL}`}
              className={styles.card}
              onClick={() => navigate('/news-and-events/media-gallery')}
            />
          ))}
      </div>
      <Button
        title="home.view_all_mediaGallery"
        variant="text-regular-20"
        style={{ textTransform: 'uppercase' }}
        styleType="primary"
        className={styles['view-all-button']}
        svg={arowDown}
        svgPosition="right"
        onClick={() => navigate('/news-and-events/media-gallery')}
      />
      <div className={styles['view-all']}>
        <Text
          title="home.view_all_mediaGallery"
          variant="text-bold-italic-16"
          className={styles['text']}
          svg={arowDown}
          svgPosition="right"
          onClick={() => navigate('/news-and-events/media-gallery')}
        />
      </div>
    </div>
  );
};

export default MediaCenter;
