import React from 'react';
import styles from './TextWithSvg.module.scss';
import Text from '../../atoms/text/Text';

const TextWithSvg = ({
  svg,
  onClick,
  text,
  subText,
  variant,
  color,
  imgStyle,
  subTextVariant,
  titleClassname,
  subtitleClassname,
  type,
  textWrapperStyle,
  isClickable,
  classname,
  textContainerStyle,
  secondSubText,
  style,
}) => {
  return (
    <div
      className={`${styles['svg-container']} ${classname}`}
      onClick={onClick}
      style={{ cursor: isClickable ? 'pointer' : '', ...style }}
    >
      {svg ? (
        <img src={svg} className={`${styles.image} ${imgStyle ?? ''}`} />
      ) : null}

      <div style={textWrapperStyle} className={styles['text-wrapper']}>
        <Text
          title={text}
          variant={variant ?? 'text-bold-22'}
          color={color ?? '#fff'}
          className={titleClassname ?? ''}
          style={{ cursor: isClickable ? 'pointer' : '' }}
        />
        <div className={styles.wrapper}>
          <Text
            title={subText}
            variant={subTextVariant ?? 'text-regular-20'}
            color={color ?? '#fff'}
            textContainerStyle={{
              direction: type !== 'email' ? 'ltr' : '',
              ...textContainerStyle,
            }}
            className={subtitleClassname ?? ''}
            style={{ cursor: onClick ? 'pointer' : '' }}
          />
          {secondSubText ? (
            <Text
              title={secondSubText}
              variant={subTextVariant ?? 'text-regular-20'}
              color={color ?? '#fff'}
              textContainerStyle={{
                direction: type !== 'email' ? 'ltr' : '',
                ...textContainerStyle,
              }}
              className={subtitleClassname ?? ''}
              style={{ cursor: onClick ? 'pointer' : '' }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TextWithSvg;
