import React from 'react';
import { ToastContainer as DefaultToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.css';
import Text from '../text/Text';
import CautionIcon from '../../../assets/images/errors/caution.svg';
import checked from '../../../assets/icons/justsvg.svg';
import { useTranslation } from 'react-i18next';

const defaultToast = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  icon: false,
  theme: 'colored',
};
export const successMessage = (message) => {
  const defaultMsg = 'toast.success';

  toast.success(<Msg title={message ?? defaultMsg} success />);
};
export const errorMessage = (message) => {
  const defaultMsg = 'toast.failure';

  toast.error(<Msg title={message ?? defaultMsg} />);
};
const Msg = ({ title, success }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  return (
    <div className={isRtl ? 'taost-container-arab' : 'taost-container'}>
      <img src={success ? checked : CautionIcon} alt="icon" />
      <Text title={t(title)} variant="text-regular-16" />
    </div>
  );
};

const ToastContainer = () => {
  return <DefaultToastContainer {...defaultToast} />;
};

export default ToastContainer;
