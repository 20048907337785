import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  degreeData: [],
};

const degreeDataSlice = createSlice({
  name: 'degreeData',
  initialState,
  reducers: {
    setDegreeData: (state, action) => {
      state.degreeData = action.payload;
    },
  },
});

export const selectDegreeData = (state) => state.degreeData;
export const { setDegreeData } = degreeDataSlice.actions;
export default degreeDataSlice.reducer;
