import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from './BottomNavigation.module.scss';
import NavigationButton from '../../atoms/navigationButton/NavigationButton';
import Button from '../../atoms/button/Button';
import { setApplyNowState } from '../../../app/globals/slice/applyNowModalSlice/ApplyNowSlice';
import { useDispatch } from 'react-redux';
import BottomNavigationMobile from './mobile/BottomNavigationMobile';
import ApplyNow from '../../organism/applyNowModal/ApplyNow';
import { useTranslation } from 'react-i18next';

const BottomNavigation = ({
  isApplyNow,
  data,
  variant,
  linkStyle,
  onClick,
  active,
  containerClass,
  navigationStyle,
  ignoreLast,
}) => {
  const { i18n } = useTranslation();
  const [activeButton, setActiveButton] = useState(active ?? 1);
  const dispatch = useDispatch();
  const isRtl = i18n.language !== 'en';
  const isDesktop = useMediaQuery({ minWidth: 1199 });
  if (isApplyNow) {
    ignoreLast = true;
  }
  useEffect(() => {
    setActiveButton(active);
  }, [active]);

  const handleButtonClick = (id) => {
    if (onClick) {
      setActiveButton(id);
      onClick(id);
    }
  };

  const handleApplyNowClick = () => {
    dispatch(setApplyNowState(true));
  };

  const containerClasses = [
    styles.container,
    isRtl && 'rtl',
    containerClass || '',
  ].join(' ');
  return (
    <div>
      <div className={containerClasses} style={navigationStyle || {}}>
        <div className={styles.navigationsContainer}>
          {data?.map((item, index) => (
            <NavigationButton
              key={index}
              id={item?.id}
              label={item.label}
              isbordered={item.isbordered}
              to={item.route ?? ''}
              variant={variant}
              onClick={handleButtonClick}
              activeButton={activeButton}
              capitalised
              lastItem={!data[index + 1]}
              style={linkStyle}
              ignoreLast={ignoreLast}
            />
          ))}
          {isApplyNow && (
            <div className={styles['button-container']}>
              <Button
                title="common.Apply_Now"
                className={styles['button']}
                variant="text-regular-20"
                onClick={() => {
                  window.open(
                    'https://dubaipolice.ac.ae/dpacademy/ar/std_login.jsp',
                    '_blank'
                  );
                }}
                styleType="primary"
              />
            </div>
          )}
        </div>
      </div>
      {!isDesktop && (
        <BottomNavigationMobile
          data={data}
          variant="text-bold-italic-25"
          isApplyNow={isApplyNow}
          onClick={handleButtonClick}
          active={activeButton}
        />
      )}
      <ApplyNow />
    </div>
  );
};

export default BottomNavigation;
