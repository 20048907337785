import { usePostMutation } from '../../../../services/apiCall';
import endpoints from '../../../../api/endpoints';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './Data';
import { errorMessage, successMessage } from '../../../atoms/toast/Toast';

const apiUrl = endpoints.SubscribeNEwsLetter.subscribe;

const useSubscribeLetter = () => {
  const [post, { isLoading }] = usePostMutation();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const params = [values.email];
      try {
        await post({ apiUrl, params }).unwrap();
        successMessage();
        resetForm();
      } catch (error) {
        errorMessage();
      } finally {
        setSubmitting(false);
      }
    },
  });

  return { isLoading, formik };
};

export { useSubscribeLetter };
