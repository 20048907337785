import React from 'react';
import Text from '../text/Text';
import styles from './SearchItem.module.scss';
import { useNavigate } from 'react-router-dom/dist';
import { useDispatch } from 'react-redux';
import { toggleSearchModal } from '../../../app/globals/slice/sideModalSlice/SideModalSlice';

const SearchItem = ({ itemData, setIsSearching, isMobile, closeNav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearchClick = () => {
    dispatch(toggleSearchModal(false));
  };
  const onClick = (item, isChildren) => {
    if (isChildren) {
      closeNav && closeNav(false);
      navigate(item.route);
      isMobile &&
        setTimeout(() => {
          handleSearchClick();
        }, 300);
    } else {
      closeNav && closeNav(false);

      navigate(item);
      isMobile &&
        setTimeout(() => {
          handleSearchClick();
        }, 300);
    }
    setIsSearching(true);
  };

  return (
    <div className={styles['search-item']}>
      <div className={styles.item}>
        <div
          className={styles['text-container-title']}
          onClick={() => onClick(itemData.route)}
        >
          <Text
            title={itemData.title}
            variant="text-regular-16"
            className={styles.text}
            color={isMobile ? 'white' : null}
          />
        </div>
        {itemData.children &&
          itemData.children.map((item) => (
            <div
              className={styles['text-container']}
              onClick={() => onClick(item, true)}
            >
              <Text
                title={item.title}
                variant="text-regular-16"
                className={styles.subtext}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default SearchItem;
