import React, { Fragment, useEffect, useState } from 'react';
import MajorTemplate from '../../components/templates/majorTemplate/MajorTemplate';
import Footer from '../../components/templates/homePageSections/footer/Footer';
import HeaderSection from '../../components/organism/headerSection/HeaderSection';
import useScrollToTop from '../../app/hooks/useScrollToTop';
import Container from '../../components/hoc/container/Container';
import { navigationData } from './factory/data';
import Sections from './factory/sections';
import endpoints from '../../api/endpoints';
import { useGetQuery } from '../../services/apiCall';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loader from '../../components/atoms/loader/Loader';

function Major() {
  useScrollToTop();
  const { id } = useParams();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [section, setSection] = useState(1);
  const apiUrl = endpoints.degrees.getDegreesById;
  const { data, error, isLoading, refetch } = useGetQuery({
    apiUrl: apiUrl,
    params: [id],
  });

  const handleButtonClick = (label) => {
    setSection(label);
  };
  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <Fragment>
      <HeaderSection />
      <MajorTemplate
        handleButtonClick={handleButtonClick}
        navigationData={navigationData}
        data={data ?? []}
        currentLanguage={currentLanguage}
      />
      <Container>
        {isLoading ? (
          <Loader fullPage />
        ) : (
          <Sections
            key={data}
            id={section}
            data={data ?? []}
            currentLanguage={currentLanguage ?? []}
          />
        )}
      </Container>
      <Footer />
    </Fragment>
  );
}

export default Major;
