import { createSlice } from '@reduxjs/toolkit';

const SignInSlice = createSlice({
  name: 'applyNow',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setSignInState: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setSignInState } = SignInSlice.actions;
export default SignInSlice.reducer;
