import React, { useState } from 'react';
import Select from 'react-select';
import styles from './CustomSelect.module.scss';
import Label from '../lable/Label';
import selectArrow from '../../../assets/icons/selectArrow.svg';
import Text from '../text/Text';
import { useTranslation } from 'react-i18next';
import endpoints from '../../../api/endpoints';
import { useGetQuery } from '../../../services/apiCall';

const apiUrl = endpoints.dropdown.getDropdownByType;
const apiUrlParent = endpoints.dropdown.getDropDownFromParent;

const MYSelect = ({
  label,
  placeholder,
  type,
  required,
  style,
  error,
  name,
  value,
  onChange,
  onBlur,
  className,
  parentType,
  isSkiped,
  options,
  isDisabled,
  opacity,
  color,
  textVariant,
  onMenuToggle,
  isSearchable,
}) => {
  const selectedOption =
    options?.find((option) => option.value === value) || null;

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const boleanOptions = [
    { value: true, text_EN: 'Yes', text_AR: 'نعم' },
    { value: false, text_EN: 'No', text_AR: 'كلا' },
  ];
  const params = [type, parentType];
  const parrentParams = [type, parentType];
  const { data } = useGetQuery(
    {
      apiUrl: parentType ? apiUrlParent : apiUrl,
      params: parentType ? parrentParams : params,
    },
    { skip: isSkiped || options }
  );

  const CustomDropdownArrow = () => {
    return (
      <div className={styles['indicator']}>
        <img src={selectArrow} />
      </div>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      opacity: 0.8,
      borderColor: state.isFocused
        ? 'rgba(128, 128, 128, 0.559)'
        : error && !state.isSelected
          ? 'rgba(128, 128, 128, 0.559)'
          : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px grey' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused
          ? 'rgba(128, 128, 128, 0.559)'
          : provided.borderColor,
      },
      border: error ? '1px solid red' : '',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'grey' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'grey',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      fontFamily: '29LT Bukra Bold',
      opacity: opacity,
    }),
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    if (onMenuToggle) onMenuToggle(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    if (onMenuToggle) onMenuToggle(false);
  };

  return (
    <div
      className={`${styles['select-container']} ${className ?? ''} ${
        isRtl ? 'rtl' : ''
      }`}
      style={style}
    >
      <div className={styles['select']}>
        <Label
          label={label}
          required={required}
          color={color}
          textVariant={textVariant}
        />
        <Select
          options={options ?? data}
          styles={customStyles}
          placeholder={t(placeholder) ?? t('common.select_placeholder')}
          name={name}
          value={selectedOption}
          onChange={(selectedOption) => {
            if (onChange) {
              onChange(name, selectedOption.value);
            }
          }}
          components={{
            DropdownIndicator: CustomDropdownArrow,
          }}
          onBlur={onBlur}
          isDisabled={isDisabled}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          isSearchable={isSearchable}
        />
      </div>
      {error && (
        <Text
          title={error}
          variant="text-regular-15"
          className={styles['text']}
          style={{ color: 'red' }}
        />
      )}
    </div>
  );
};

export default MYSelect;
