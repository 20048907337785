import React, { useState, useEffect } from 'react';
import styles from './Avatar.module.scss';
import { useNavigate } from 'react-router-dom';
import up from '../../../assets/icons/arrow-down-white.svg';
import Text from '../text/Text';

const Avatar = ({ images, onAvatarClick }) => {
  const navigate = useNavigate();
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [containerClass, setContainerClass] = useState(
    styles['avatar-transition']
  );

  const numberOfAvatarsToShow = 3;
  const maxIndex = images.length - numberOfAvatarsToShow;

  useEffect(() => {
    setContainerClass(styles['avatar-exit']);

    const timeoutId = setTimeout(() => {
      setContainerClass(styles['avatar-enter']);
    }, 50);
    return () => clearTimeout(timeoutId);
  }, [visibleStartIndex]);
  const scroll = (direction) => {
    setVisibleStartIndex((prevIndex) => {
      return direction === 'up'
        ? Math.max(prevIndex - 1, 0)
        : Math.min(prevIndex + 1, maxIndex);
    });
  };

  const handleClick = (index, isLast) => {
    setActiveIndex(index);
    if (isLast) {
      navigate('/students-affairs/alumni');
    } else {
      onAvatarClick && onAvatarClick(index);
    }
  };

  return (
    <div className={styles['avatar-container']}>
      <button
        onClick={() => scroll('up')}
        className={styles.scrollButton}
        disabled={visibleStartIndex === 0}
      >
        <img
          src={up}
          className={`${styles.up} ${
            visibleStartIndex === 0 ? styles.upDisabled : ''
          }`}
        />
      </button>
      <div className={`${styles.con} ${containerClass}`}>
        {images
          .slice(visibleStartIndex, visibleStartIndex + numberOfAvatarsToShow)
          .map((slide, index) => {
            const adjustedIndex = index + visibleStartIndex;
            return (
              <div
                key={adjustedIndex}
                onClick={() => handleClick(adjustedIndex, slide.isLast)}
                className={`${styles['profile']} ${
                  adjustedIndex === activeIndex ? styles['active'] : ''
                }`}
                aria-label={`Select slide ${adjustedIndex + 1}`}
              >
                <img
                  src={slide.avatarImage}
                  alt={slide.description}
                  className={styles['profile-image']}
                />
                {/* {slide.isLast ? (
                  <Text
                    className={styles.num}
                    variant="text-bold-italic-20"
                    title={slide.description}
                  />
                ) : null} */}
              </div>
            );
          })}
      </div>
      <button
        onClick={() => scroll('down')}
        className={styles.scrollButton}
        disabled={visibleStartIndex >= maxIndex}
      >
        <img
          src={up}
          className={visibleStartIndex >= maxIndex ? styles.upDisabled : ''}
        />
      </button>
    </div>
  );
};

export default Avatar;
