import React from 'react';
import styles from './Text.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Text = ({
  id,
  title,
  style = {},
  variant = 'paragraph',
  isLineBreak = false,
  className = '',
  onClick,
  svg = null,
  svgPosition = 'left',
  link,
  component,
  colorReverse,
  svgStyle,
  onMouseEnter,
  ref,
  maxLines,
  color,
  textContainerStyle,
  fill,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage === 'ar';
  const fontSize = useSelector((state) => state.fontSize);
  const classes = `${styles['text-container']} ${
    currentLanguage !== 'en' ? styles['text-container-rtl'] : ''
  } ${styles[variant] ?? ''} ${styles[fontSize]} ${
    isLineBreak
      ? isRtl
        ? styles['has-line-break-right']
        : styles['has-line-break']
      : ''
  } ${currentLanguage !== 'en' ? 'rtl' : 'ltr'} ${className ?? ''}`;

  const containerStyles = {
    display: svg ? 'flex' : '',
    alignItems: 'center',
    gap: isRtl ? '10px' : '',
    color: color,
    ...(maxLines && {
      display: '-webkit-box',
      WebkitLineClamp: maxLines,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    ...textContainerStyle,
    ...style,
  };
  const text = t(title);
  const handleClick = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };
  return (
    <span
      id={id}
      className={classes}
      style={containerStyles}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      ref={ref}
    >
      {svg && svgPosition === 'left' && (
        <img
          src={svg}
          alt="icon"
          className={styles['svg-icon-left']}
          style={{ ...svgStyle, filter: fill ? fill : null }}
        />
      )}
      {component || text}{' '}
      {!component && link && (
        <span
          className={`${colorReverse ? styles['link-green'] : styles['link']}`}
          onMouseEnter={onMouseEnter}
          onClick={() => handleClick(link)}
        >
          {t(link)}
        </span>
      )}
      {svg && svgPosition === 'right' && (
        <img
          src={svg}
          alt="icon"
          className={styles['svg-icon-right']}
          style={{ ...svgStyle, filter: fill }}
        />
      )}
    </span>
  );
};

export default Text;
