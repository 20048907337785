import React from 'react';
import styles from './AdmissionsCard.module.scss';
import Text from '../../atoms/text/Text';
import { useTranslation } from 'react-i18next';
import formatDate from '../../../app/helpers/formatedDate';

const AdmissionsCard = ({
  title,
  subtitles,
  subtitlesAr,
  startDate,
  endDate,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const displayedSubtitles = currentLang === 'ar' ? subtitlesAr : subtitles;

  return (
    <div
      className={`${styles['admissions-card']} ${currentLang !== 'en' ? 'rtl' : ''}`}
    >
      <div className={styles['card']}>
        <Text
          title={title}
          variant="text-bold-italic-20"
          style={{ textTransform: 'uppercase' }}
        />
        <div className={styles['card-subtitles']}>
          {displayedSubtitles.map((subtitle, index) => (
            <Text
              key={index}
              title={t(subtitle)}
              variant="text-bold-18"
              style={{ textTransform: 'uppercase' }}
              className={`${styles['link']} ${currentLang === 'en' ? styles.en : ''}`}
            />
          ))}
        </div>
      </div>
      {/* <div className={styles['card-footer']}>
        <div className={styles['footer']}>
          <div className={styles['dashes-separator']}></div>
          <div className={styles['date']}>
            <Text
              title={'home.Admission_started'}
              variant="text-bold-italic-16"
              style={{ textTransform: 'uppercase' }}
            />
            <div className={styles['date-texts']}>
              <Text
                title={formatDate(startDate ?? '') ?? ''}
                variant="text-regular-16"
                style={{
                  opacity: 0.6,
                  textTransform: 'uppercase',
                }}
              />
            </div>
          </div>
          <div className={styles['date']}>
            <Text
              title={'home.Admission_ends'}
              variant="text-bold-italic-16"
              style={{ textTransform: 'uppercase' }}
            />
            <div className={styles['date-texts']}>
              <Text
                title={formatDate(endDate ?? '') ?? ''}
                variant="text-regular-16"
                style={{
                  opacity: 0.6,
                  textTransform: 'uppercase',
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AdmissionsCard;
