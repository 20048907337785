import React from 'react';
import backgroundImage from '../../../../../../assets/images/slideTwo.jpg';
import styles from './SlideThree.module.scss';
import Button from '../../../../../atoms/button/Button';
import Text from '../../../../../atoms/text/Text';
import { useNavigate } from 'react-router-dom';
import ImageWithBackdrop from '../../../../../molecule/imageWithBackdrop/ImageWithBackdrop';

const SlideThree = () => {
  const navigate = useNavigate();
  return (
    <div className={styles['slide-three']}>
      <ImageWithBackdrop
        src={backgroundImage}
        alt="degrees"
        backdropStyle={{ zIndex: -1 }}
      />
      <div className={styles['content']}>
        <Text
          title="home.be_part_of_the_campus"
          variant="text-bold-italic-50"
          style={{
            textTransform: 'uppercase',
            marginBottom: '30px',
            textAlign: 'center',
          }}
        />
        <Button
          title="home.register"
          variant="text-regular-20"
          style={{ textTransform: 'uppercase' }}
          styleType="primary"
          onClick={() => {
            window.open(
              'https://dubaipolice.ac.ae/dpacademy/ar/std_login.jsp',
              '_blank'
            );
          }}
        />
      </div>
    </div>
  );
};

export default SlideThree;
