import React from 'react';
import styles from './Backdrop.module.scss';

const Backdrop = ({ style, currentSection, backdropFade }) => {
  return (
    <div
      style={style}
      className={`${styles['backdrop']}`}
    ></div>
  );
};

export default Backdrop;
