import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import MainRouter from './routes/MainRouter';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ToastContainer from './components/atoms/toast/Toast';
import GlobalErrorHandler from './app/helpers/NavigateToError';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GlobalErrorHandler />
          <MainRouter />
        </BrowserRouter>
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
};

export default App;
