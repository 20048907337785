export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;

export const phoneRegex = /^\d{10,15}$/;

export const nameRegex = /^[a-zA-Z\u0600-\u06FF\s'-]+$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const numberRegex = /^[0-9]*$/;

export const zipCodeRegex = /^\d{5}$/;

export const poboxRegex = /^[0-9]{6,}$/;
export const emiratesIdRegex = /^784-\d{4}-\d{7}-\d{1}$/;
