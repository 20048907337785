const navigationLinks = [
  { label: 'About_The_Academy.Overview', route: '/about/overview' },
  {
    label: 'About_The_Academy.History',
    route: '/about/history',
  },
  { label: 'About_The_Academy.Team', route: '/about/team' },

  {
    label: 'About_The_Academy.Leadership',
    route: '/about/leadership',
  },
  { label: 'About_The_Academy.Graduation', route: '/about/graduation' },
];

export { navigationLinks };
