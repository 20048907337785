import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styles from './Modal.module.scss';

const Modal = ({
  isOpen,
  onClose,
  children,
  modalBodyClassname,
  dialogClassName,
}) => {
  const handleEnter = () => {
    document.body.style.overflow = 'hidden';
    window.isHoveringCalendar = true;
  };

  const handleExited = () => {
    document.body.style.overflow = 'auto';
    window.isHoveringCalendar = false;
  };

  return ReactDOM.createPortal(
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames={{
        enter: styles['fade-enter'],
        enterActive: styles['fade-enter-active'],
        exit: styles['fade-exit'],
        exitActive: styles['fade-exit-active'],
      }}
      unmountOnExit
      onEnter={handleEnter}
      onExited={handleExited}
    >
      <div className={styles['modal-overlay']} onClick={onClose}>
        <div
          className={`${styles['modal-content']} ${dialogClassName ?? ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`${styles['modal-body']} ${modalBodyClassname ?? ''}`}
          >
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
};

export default Modal;
