import React from 'react';
import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo';
import SlideThree from './SlideThree/SlideThree';

const CarouselData = [SlideOne, SlideTwo, SlideThree];
const navigationItems = [
  {
    label: 'bottomNavigation.Student_Life',
    // route: '/students-affairs/facilities',
    scrollTo: 4,
    sectionIndex: 4,
  },
  {
    label: 'bottomNavigation.Admission',
    // route: '/admissions/overview',
    scrollTo: 3,
    sectionIndex: 3,
  },
  {
    label: 'bottomNavigation.Programs',
    // route: '/degree-programs',
    scrollTo: 2,
    sectionIndex: 2,
  },

  {
    label: 'bottomNavigation.Media',
    route: '/news-and-events/media-gallery',
    scrollTo: 7,
    sectionIndex: 7,
  },
];

export { CarouselData, navigationItems };
