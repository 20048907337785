import React, { useState } from 'react';
import styles from './ExpandableCard.module.scss';
import Text from '../../atoms/text/Text';

const ExpandableCard = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`${styles['expandable-card']} ${
        isExpanded ? styles['expanded'] : ''
      }`}
    >
      <div onClick={toggleExpand} className={styles['card']}>
        <Text
          title={title}
          variant="text-bold-italic-18-black"
          style={{ color: '#1B224C', cursor: 'pointer' }}
        />

        <div
          className={`${styles['card-content']} ${
            isExpanded ? styles['expanded'] : ''
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ExpandableCard;
