import React from 'react';
import styles from './SocialMedia.module.scss';
import facebook from '../../../assets/icons/socials/facebook.svg';
import instagram from '../../../assets/icons/socials/instagram.svg';
import linkedin from '../../../assets/icons/socials/linkedin.svg';
import twitter from '../../../assets/icons/socials/twitter.svg';
import youtube from '../../../assets/icons/socials/youtube.svg';

const SocialMedia = ({ section }) => {
  // class to push the media UI down when not first section
  const containerClass =
    section !== 0
      ? `${styles['container']} ${styles['container-with-margin-top']}`
      : styles['container'];
  // class to change UI color of the media lines
  const SocialMediaLineClass =
    section !== 0
      ? `${styles['social-media-line']} ${styles['green-socials']}`
      : styles['social-media-line'];

  return (
    <div className={containerClass}>
      <div className={SocialMediaLineClass}></div>
      <div className={styles['Social-Media']}>
        <a
          href="https://www.facebook.com/dubaipolicehq.en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles['media']}>
            <img
              src={facebook}
              alt="Facebook"
              className={section !== 0 ? styles['green-media'] : ''}
            />
          </div>
        </a>
        <a
          href="https://www.instagram.com/dubaipolicehq/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles['media']}>
            <img
              src={instagram}
              alt="Instagram"
              className={section !== 0 ? styles['green-media'] : ''}
            />
          </div>
        </a>
        <a
          href="https://twitter.com/dubaipolicehq"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles['media']}>
            <img
              src={twitter}
              alt="Twitter"
              className={section !== 0 ? styles['green-media'] : ''}
            />
          </div>
        </a>
        <a
          href="https://www.youtube.com/dubaipolicehq"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles['media']}>
            <img
              src={youtube}
              alt="YouTube"
              className={section !== 0 ? styles['green-media'] : ''}
            />
          </div>
        </a>
        <a
          href="https://www.linkedin.com/school/dubai-police-academy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles['media']}>
            <img
              src={linkedin}
              alt="LinkedIn"
              className={section !== 0 ? styles['green-media'] : ''}
            />
          </div>
        </a>
      </div>

      <div className={SocialMediaLineClass}></div>
    </div>
  );
};

export default SocialMedia;
