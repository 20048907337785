import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetQuery } from '../services/apiCall';
import { setDegreeData } from '../app/globals/slice/degreeSlice/DegreeSlice';
import { errorMessage } from '../components/atoms/toast/Toast';
import endpoints from '../api/endpoints';
import useOnlineStatus from '../app/hooks/useOnlineStatus';
import {
  cadetsStudentsCouncil,
  facultyMemberCouncil,
  parentCouncilData,
  studentsCouncilData,
} from './factory/data';
import defaultImage from '../assets/images/defaultNews.png';
import HomePage from '../features/homePage/HomePage';
import Major from '../features/major/Major';
import ErrorPage from '../features/errorPage/ErrorPage';
import StudentProfile from '../features/studentProfile/StudentProfile';
import PageNotFound from '../features/PageNotFound/PageNotFound';
import PageHeader from '../components/templates/pageHeader/PageHeader';
import HistoryNew from '../features/aboutTheAcademy/outlets/historyNew/HistoryNew';
import MediaGallery from '../components/templates/newsAndEventsTemplate/mediaGallery/MediaGallery';
import Register from '../features/register/Register';
import CommingSoonPage from '../components/templates/CommingSoon/CommingSoonPage';
import RegistrationApp from '../features/registrationApplication/RegistrationApp';

import {
  AcademicCalendar,
  ResearchAndPublications,
  ResearchGuideline,
  SecurityAndLaw,
  Policies,
  Research,
  SubmitResearch,
  ResearchOportunity,
  TrainingCenter,
  AcademyAdvizingQuiz,
  AcademyInNumber,
  AcademyEcoMagazine,
  AboutTheAcademy,
  Overview,
  Graduation,
  OrganizationalChart,
  Team,
  Academics,
  AcademicsOverview,
  CollegePostGraduateStudies,
  CollegeOfLaw,
  AcademicSupport,
  AcademicAdvising,
  NewsAndEvents,
  NewsPage,
  EventPage,
  NewsLetterPage,
  // MediaGallery,
  Admissions,
  AdmissionOverview,
  TuitionFees,
  UnderGraduate,
  Graduate,
  ScholarshipFinancial,
  StudentAffairs,
  Facilities,
  Alumni,
  Tour,
  StudentServices,
  // AccessibilityServices,
  Council,
  CouncilOverview,
  StudentsCouncil,
  CouncilCalendar,
  DegreeAndPrograms,
} from './factory/LazyImports';
import FlipBookPDF from '../features/pdf-view/FlipBookPDF';

const MainRouter = () => {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const navigate = useNavigate();
  const fontSize = useSelector((state) => state.fontSize);

  const apiUrl = endpoints.degrees.getDegrees;
  const params = [100, 1];

  const { data, error } = useGetQuery({ apiUrl, params });

  useEffect(() => {
    if (data) {
      dispatch(setDegreeData(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    let multiplier;
    switch (fontSize) {
      case 'small':
        multiplier = 0.9;
        break;
      case 'medium':
        multiplier = 1;
        break;
      case 'large':
        multiplier = 1.05;
        break;
      case 'bigger':
        multiplier = 1.1;
        break;
      default:
        multiplier = 1;
    }

    document.body.style.setProperty('--font-size-multiplier', multiplier);
  }, [fontSize]);

  useEffect(() => {
    if (error) {
      errorMessage('error while loading data');
    }
  }, [error]);

  useEffect(() => {
    if (!isOnline) {
      navigate('/error');
    }
  }, [isOnline, navigate]);
  const header = { img: defaultImage };
  return (
    <Suspense fallback={<PageHeader data={header} isLoaded />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/degree-programs" element={<DegreeAndPrograms />} />
        <Route path="/degree-programs/:type/:name/:id" element={<Major />} />
        <Route path="/about" element={<AboutTheAcademy />}>
          <Route index element={<Navigate replace to="overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route path="history" element={<HistoryNew />} />
          <Route path="graduation" element={<Graduation />} />
          <Route path="leadership" element={<OrganizationalChart />} />
          <Route path="team" element={<Team />} />
        </Route>
        <Route path="/academics" element={<Academics />}>
          <Route index element={<Navigate replace to="overview" />} />
          <Route path="overview" element={<AcademicsOverview />} />
          <Route
            path="college-postgraduate"
            element={<CollegePostGraduateStudies />}
          />
          <Route path="college-law-police" element={<CollegeOfLaw />} />
          <Route path="academic-support" element={<AcademicSupport />} />
          <Route path="academy-advising" element={<AcademicAdvising />} />
        </Route>
        <Route path="/news-and-events" element={<NewsAndEvents />}>
          <Route index element={<Navigate replace to="news" />} />
          <Route path="news" element={<NewsPage />} />
          <Route path="events" element={<EventPage />} />
          <Route path="news-letter" element={<NewsLetterPage />} />
          <Route path="media-gallery" element={<MediaGallery />} />
        </Route>
        <Route path="/admissions" element={<Admissions />}>
          <Route index element={<Navigate replace to="overview" />} />
          <Route path="overview" element={<AdmissionOverview />} />
          <Route path="tuition-fees" element={<TuitionFees />} />
          <Route path="undergraduate" element={<UnderGraduate />} />
          <Route path="graduate" element={<Graduate />} />
          <Route
            path="scholarships-financial-aid"
            element={<ScholarshipFinancial />}
          />
        </Route>
        <Route path="/students-affairs" element={<StudentAffairs />}>
          <Route index element={<Navigate replace to="facilities" />} />
          <Route path="facilities" element={<Facilities />} />
          <Route path="alumni" element={<Alumni />} />
          <Route path="tour" element={<Tour />} />
          <Route path="student-services" element={<StudentServices />} />
          {/* <Route
            path="student-accessibility-services"
            element={<AccessibilityServices />}
          /> */}
        </Route>
        <Route path="/council" element={<Council />}>
          <Route index element={<Navigate replace to="overview" />} />
          <Route path="overview" element={<CouncilOverview />} />
          <Route
            path="students-council"
            element={<StudentsCouncil data={studentsCouncilData} id={0} />}
          />
          <Route
            path="parents-council"
            element={<StudentsCouncil data={parentCouncilData} id={1} />}
          />
          <Route
            path="cadets-students-council"
            element={<StudentsCouncil data={cadetsStudentsCouncil} id={2} />}
          />
          <Route
            path="faculty-member-council"
            element={<StudentsCouncil data={facultyMemberCouncil} id={3} />}
          />
          <Route path="council-calendar" element={<CouncilCalendar id={5} />} />
        </Route>
        <Route path="/academic-calendar" element={<AcademicCalendar />} />
        <Route path="/academy-eco-magazine" element={<AcademyEcoMagazine />} />
        <Route path="/security-and-law" element={<SecurityAndLaw />} />
        <Route
          path="/academic-research-and-publications"
          element={<ResearchAndPublications />}
        />
        <Route path="/policies" element={<Policies />} />
        <Route path="/application/:type" element={<RegistrationApp />} />
        <Route path="/research" element={<Research />} />
        <Route path="/research/submit-research" element={<SubmitResearch />} />
        <Route
          path="/research/oportunity/:id"
          element={<ResearchOportunity />}
        />
        <Route path="/training-center" element={<TrainingCenter />} />
        <Route path="/research/guideline" element={<ResearchGuideline />} />
        <Route
          path="/academics/academy-advising/quiz"
          element={<AcademyAdvizingQuiz />}
        />
        <Route path="/academy-in-number" element={<AcademyInNumber />} />
        <Route path="/student/profile/:id" element={<StudentProfile />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/pdf-view" element={<FlipBookPDF />} />
        <Route path="/coming-soon" element={<CommingSoonPage />} />
      </Routes>
    </Suspense>
  );
};

export default MainRouter;
