import React from 'react';
import Backdrop from '../../atoms/backdrop/Backdrop';
import styles from './ImageWithBackdrop.module.scss';

const ImageWithBackdrop = ({
  src,
  alt,
  backdropStyle,
  imageStyle,
  className,
  backdropFade,
  isAnimated,
}) => {
  const imageClasses = `${styles.backdropImg} ${className || ''} ${isAnimated ? styles.animated : ''}`;

  return (
    <>
      <Backdrop
        backdropFade={backdropFade}
        style={{ zIndex: 0, ...backdropStyle }}
      />
      <img
        src={src}
        alt={alt}
        className={imageClasses}
        style={imageStyle}
        loading="lazy"
      />
    </>
  );
};

export default ImageWithBackdrop;
