import React, { useState } from 'react';
import styles from './Messaging.module.scss';
import chat from '../../../assets/icons/messaging/Chat.svg';
import chatBot from '../../../assets/icons/messaging/chatBot.svg';
import toTop from '../../../assets/icons/messaging/Subtraction7.svg';
import { useDispatch } from 'react-redux';
import { setCustomerFaceState } from '../../../app/globals/slice/customerFaceModalSlice/CustomerFaceModalSlice';
import HappyCustomerModal from '../../organism/happyCustomerModal/HappyCustomerModal';
import { useMediaQuery } from 'react-responsive';

const Messaging = ({
  section,
  disableScrollToTop = false,
  handleSlideTopSection,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const DesktopMessaging = () => {
    return (
      <div className={styles['messaging-container']}>
        <img
          onClick={handleCustomerForm}
          className={
            section === 0
              ? styles['default-img']
              : `${styles['default-img']} ${styles['green-img']}`
          }
          src={chatBot}
          alt=""
        />
        {/* <img
        className={
          section === 0
            ? styles['default-img']
            : `${styles['default-img']} ${styles['green-img']}`
        }
        src={chat}
        alt=""
      /> */}

        <img
          onClick={handleSlideTop}
          className={
            section === 0
              ? styles['default-img']
              : `${styles['default-img']} ${styles['green-img']}`
          }
          src={toTop}
          alt=""
        />
        <HappyCustomerModal />
      </div>
    );
  };
  const dispatch = useDispatch();

  const handleCustomerForm = () => {
    dispatch(setCustomerFaceState(true));
  };
  const handleSlideTop = () => {
    if (!disableScrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (handleSlideTopSection) {
      handleSlideTopSection();
    }
  };

  const MobileMessaging = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className={styles['messaging-container-mobile']}>
        <div
          className={`${styles['m-mobile']} ${isExpanded ? styles['m-mobile-dnone'] : ''}`}
        >
          <img
            onClick={handleCustomerForm}
            className={`${styles['default-img']} ${section === 0 ? '' : styles['green-img']} ${styles.image1} ${isExpanded && styles.showImage1}`}
            src={chatBot}
            alt="Chat bot"
          />

          <img
            onClick={handleSlideTop}
            className={`${styles['default-img']} ${section === 0 ? '' : styles['green-img']} ${styles.image2}  ${isExpanded && styles.showImage2}`}
            src={toTop}
            alt="Scroll to top"
          />
        </div>

        <div
          className={styles['messaging']}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className={styles.button}>
            {isExpanded ? (
              <>
                <div className={`${styles.linerX} ${styles.first}`}></div>
                <div className={`${styles.linerX} ${styles.second}`}></div>
                <div
                  className={`${styles.linerX} ${isExpanded && styles.third}`}
                ></div>
              </>
            ) : (
              <>
                <div className={styles.liner}></div>
                <div className={styles.liner}></div>
                <div className={styles.liner}></div>
              </>
            )}
          </div>
        </div>
        <HappyCustomerModal />
      </div>
    );
  };
  return !isMobile ? <DesktopMessaging /> : <MobileMessaging />;
};

export default Messaging;
