import React, { useEffect } from 'react';
import styles from './HomePage.module.scss';
import Header from '../../components/templates/header/Header';
import SocialMedia from '../../components/atoms/socialMedia/SocialMedia';
import sections from './factory/HomePageSections';
import Messaging from '../../components/atoms/messaging/Messaging';
import VerticalLine from '../../components/atoms/vertivalLine/VerticalLine';
import useScrollToTop from '../../app/hooks/useScrollToTop';
import useSmoothScroll from '../../app/hooks/useSmoothScroll';
import HomeButtonNav from '../../components/molecule/bottomNavigation/homeButtonNav/HomeButtonNav';
import { navigationItems } from '../../components/templates/homePageSections/home/homeCarousel/data';

const lastSlideIndex = 9;

const HomePage = () => {
  useScrollToTop();
  const {
    currentSection,
    sectionRefs,
    scrollToSection,
    setCurrentSectionExternally,
  } = useSmoothScroll(sections);

  const handleSlideTopSection = () => {
    scrollToSection(0);
    setCurrentSectionExternally(0);
  };
  const handleNavSectionChange = (sectionIndex) => {
    setCurrentSectionExternally(sectionIndex);
    scrollToSection(sectionIndex);
    if (sectionIndex === lastSlideIndex) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  useEffect(() => handleSlideTopSection(), []);

  return (
    <div className={styles['homePage']}>
      {currentSection !== lastSlideIndex ? (
        <Header section={currentSection} fixed isHomePage />
      ) : null}
      <SocialMedia section={currentSection} />
      {currentSection === 0 ? <VerticalLine /> : null}
      <Messaging
        section={currentSection}
        handleSlideTopSection={handleSlideTopSection}
        disableScrollToTop={true}
      />
      {sections.map((SectionComponent, index) => (
        <section key={index} ref={sectionRefs[index]}>
          <SectionComponent currentSection={currentSection} />
        </section>
      ))}
      <HomeButtonNav
        data={navigationItems}
        variant="text-bold-20"
        active={currentSection}
        onClick={handleNavSectionChange}
        scrollToSection={scrollToSection}
        isApplyNow
        ignoreLast
      />
    </div>
  );
};

export default HomePage;
