import * as Yup from 'yup';
import { useMemo } from 'react';

import {
  emailRegex,
  phoneRegex,
  nameRegex,
  numberRegex,
} from '../../../../Validation';

const translations = {
  en: ['Bachelors', 'Masters', 'PhD'],
  ar: ['البكالوريوس', 'الماجستير', 'الدكتوراه'],
};

const useDegreeData = (currentLanguage) => {
  return useMemo(() => {
    const degreeLabels = translations[currentLanguage] || translations['en'];

    return degreeLabels.map((label, index) => ({
      value: index,
      label: label,
    }));
  }, [currentLanguage]);
};

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: null,
  // degreeId: null,
  // programId: null,
  description: '',
};
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, 'validation.invalid_format')
    .required('registrationPage.FirstName_required'),
  lastName: Yup.string()
    .matches(nameRegex, 'validation.invalid_format')
    .required('registrationPage.LastName_required'),
  email: Yup.string()
    .email('registrationPage.invalid_email')
    .matches(emailRegex, 'validation.invalid_format')
    .required('registrationPage.Email_required'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Phone number is not valid')
    .required('registrationPage.PhoneNumber_required'),
  // degreeId: Yup.string().required('registrationPage.degree_required'),
  // programId: Yup.string().required('registrationPage.Program_required'),
  description: Yup.string(),
});

export { validationSchema, initialValues, useDegreeData };
