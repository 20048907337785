import studentBackground from '../../assets/images/contact-us.jpg';
import studentBackgroundMobile from '../../assets/images/contact-us-mobile.jpg';
import parentBackground from '../../assets/images/contact-us.jpg';

const studentsCouncilData = {
  headers: {
    img: studentBackground,
    mobileImg: studentBackgroundMobile,
    title: 'students_council.students_council',
    subtext: 'students_council.header_desc',
  },

  cardsGrid: 'grid-3-wrapped',
  about: {
    title: 'students_council.about',
    newsTitle: 'students_council.latest_students_title',
    subtext: 'students_council.about_text',
    visionTitle: 'students_council.vision',
    visionText: 'students_council.about_vision',
    missionTitle: 'students_council.mission',
    missionText: 'students_council.about_mission',
    goalTitle: 'students_council.goal',
    goals: [
      'students_council.communication',
      'students_council.improvement',
      'students_council.opportunities',
      'students_council.environment',
      'students_council.participation',
    ],
  },

  contact: {
    title: 'academics.GET_IN_CONTACT_WITH_US',
    info: [
      {
        title: 'academics.GET_IN_CONTACT_WITH_US',
        contacts: [
          {
            text: 'academics.SEND_US_AN_EMAIL',
            subText: 'contact@studentcouncil.com',
            type: 'email',
          },
        ],
      },
    ],
  },
};
const parentCouncilData = {
  headers: {
    img: parentBackground,
    mobileImg: studentBackgroundMobile,

    title: 'parents_council.parents_council',
    subtext: 'parents_council.about_text',
  },
  cardsGrid: 'grid-3',

  about: {
    title: 'parents_council.about',
    newsTitle: 'parents_council.council_members_latest',

    subtext: 'parents_council.about_text',
    visionTitle: 'students_council.vision',
    visionText: 'parents_council.about_vision',
    missionTitle: 'students_council.mission',
    missionText: 'parents_council.about_mission',
    goalTitle: 'students_council.goal',
    goals: [
      'parents_council.goals.goal1',
      'parents_council.goals.goal2',
      'parents_council.goals.goal3',
      'parents_council.goals.goal4',
      'parents_council.goals.goal5',
    ],
  },
  contact: {
    title: 'academics.GET_IN_CONTACT_WITH_US',
    info: [
      {
        title: 'accessibility_services.career_services',
        contacts: [
          {
            text: 'academics.SEND_US_AN_EMAIL',
            subText: 'DPAcareers@dubaipolice.ac.ae',
            type: 'email',
          },
        ],
      },
    ],
  },
};
const cadetsStudentsCouncil = {
  headers: {
    img: parentBackground,
    mobileImg: studentBackgroundMobile,

    title: 'cadet_students_council.cadet_students_council',
    subtext: 'cadet_students_council.about_text',
  },
  cardsGrid: 'grid-3',
  about: {
    title: 'cadet_students_council.about',
    newsTitle: 'cadet_students_council.council_members_latest',

    subtext: 'cadet_students_council.about_text',
    visionTitle: 'students_council.vision',
    visionText: 'cadet_students_council.about_vision',
    missionTitle: 'students_council.mission',
    missionText: 'cadet_students_council.about_mission',
    goalTitle: 'students_council.goal',
    goals: [
      'cadet_students_council.goals.goal1',
      'cadet_students_council.goals.goal2',
      'cadet_students_council.goals.goal3',
      'cadet_students_council.goals.goal4',
      'cadet_students_council.goals.goal5',
    ],
  },
  contact: {
    title: 'academics.GET_IN_CONTACT_WITH_US',
    info: [
      {
        title: 'accessibility_services.career_services',
        contacts: [
          {
            text: 'academics.SEND_US_AN_EMAIL',
            subText: 'contact@faculty.council.com',
            type: 'email',
          },
        ],
      },
    ],
  },
};
const facultyMemberCouncil = {
  headers: {
    img: parentBackground,
    mobileImg: studentBackgroundMobile,
    title: 'council.faculty_members_council',
    subtext: 'cadet_students_council.about_text',
  },
  cardsGrid: 'grid-3',
  about: {
    title: 'cadet_students_council.about',
    newsTitle: 'council_faculty_latest',

    subtext: 'cadet_students_council.about_text',
    visionTitle: 'students_council.vision',
    visionText: 'cadet_students_council.about_vision',
    missionTitle: 'students_council.mission',
    missionText: 'cadet_students_council.about_mission',
    goalTitle: 'students_council.goal',
    goals: [
      'cadet_students_council.goals.goal1',
      'cadet_students_council.goals.goal2',
      'cadet_students_council.goals.goal3',
      'cadet_students_council.goals.goal4',
      'cadet_students_council.goals.goal5',
    ],
  },
  contact: {
    title: 'academics.GET_IN_CONTACT_WITH_US',
    info: [
      {
        title: 'accessibility_services.career_services',
        contacts: [
          {
            text: 'academics.SEND_US_AN_EMAIL',
            subText: 'contact@faculty.council.com',
            type: 'email',
          },
        ],
      },
    ],
  },
};
export {
  parentCouncilData,
  studentsCouncilData,
  cadetsStudentsCouncil,
  facultyMemberCouncil,
};
