import React, { Fragment, useMemo, useState } from 'react';
import styles from './Degrees.module.scss';
import Carousel from '../../../molecule/carousel/Carousel';
import { CarouselData } from './degreesCarousel/CarouselData';
import LazyVideo from '../../../atoms/lazyVideo/LazyVideo';
import spaceVideo from '../../../../assets/video/spaceVideo.mp4';

const SECTION_ID = 3;

const Degrees = ({ currentSection }) => {
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    setEndX(e.changedTouches[0].clientX);
  };

  const swipeAction = endX - startX;
  const lazyVideoElement = useMemo(() => {
    return (
      <LazyVideo src={spaceVideo} isPaused={currentSection !== SECTION_ID} />
    );
  }, [currentSection === SECTION_ID]);

  return (
    <div
      className={styles['degrees-contaner']}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {lazyVideoElement}
      <Carousel
        components={CarouselData}
        dots={styles['desktop-dots']}
        swipeAction={swipeAction}
        hideArrowsOnMobile
      />
    </div>
  );
};

export default Degrees;
