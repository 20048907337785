import React, { useEffect, useState } from 'react';
import styles from './UploadImage.module.scss';
import Text from '../text/Text';
import dummy from '../../../assets/icons/admissionRequirments/Balance.svg';
import cross from '../../../assets/icons/crossGreen.svg';
import { useTranslation } from 'react-i18next';
import Label from '../lable/Label';

const isValidFileExtension = (file, allowedExtensions) => {
  if (!file) return false;
  const fileExtension = file.name.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};

const UploadImage = ({
  label,
  style,
  fontSize,
  required,
  svg,
  title,
  name,
  value,
  onChange,
  onBlur,
  error,
  key,
  allowedExtensions = [],
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setFileError(t('validation.image_fileSize_limit'));
        setSelectedFile(null);
        onChange(name, undefined);
      } else if (!isValidFileExtension(file, allowedExtensions)) {
        const message = `${t('validation.invalid_file_extension')} ${allowedExtensions.join(', ')}`;
        setFileError(message);
        setSelectedFile(null);
        onChange(name, undefined);
      } else {
        setSelectedFile(file);
        onChange(name, file);
        setFileError('');
      }
    } else {
      setSelectedFile(null);
      onChange(name, undefined);
    }
  };

  const handleRemoveFile = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    onChange(name, undefined);
    document.getElementById(`file-input-${name}`).value = '';
  };

  useEffect(() => {
    if (value) {
      setSelectedFile(value);
      setFileError('');
    } else {
      setSelectedFile(null);
    }
  }, [value]);

  return (
    <div className={styles['image-upload']} style={style}>
      {label && (
        <div className={styles['label']}>
          <Label label={label} required={required} style={{ opacity: '0.7' }} />
        </div>
      )}
      <div></div>
      <label
        className={styles['custom-file-input']}
        style={{ border: error ? '1px solid red' : '' }}
      >
        <input
          id={`file-input-${name}`}
          key={key}
          type="file"
          accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
          onChange={handleFileChange}
          onBlur={onBlur}
        />
        <img className={styles['image']} src={svg ?? dummy} alt="Upload icon" />
        <div className={styles['file']}>
          <Text
            title={
              title
                ? ` ${t('upload')} ${t(title)}`
                : ` ${t('upload')} ${t(label)}`
            }
            variant="text-regular-18-black"
            fontSize={fontSize}
            className={styles['text']}
            style={{ cursor: 'pointer', textTransform: 'capitalized' }}
          />
          {selectedFile && (
            <Text
              title={selectedFile.name ?? selectedFile.fileName}
              variant="text-regular-18-black"
              fontSize={fontSize}
              className={styles['text']}
              style={{ cursor: 'pointer' }}
            />
          )}
          {selectedFile && (
            <button
              onClick={handleRemoveFile}
              className={`${isRtl ? styles['left-button'] : styles['right-button']}`}
              type="button"
            >
              <img className={styles.img} src={cross} alt="Remove icon" />
            </button>
          )}
        </div>
      </label>
      {(error || fileError) && (
        <Text
          title={error || fileError}
          variant="text-regular-15"
          className={styles['text']}
          color="red"
        />
      )}
    </div>
  );
};

export default UploadImage;
