import { createSlice } from '@reduxjs/toolkit';

const volumeSlice = createSlice({
  name: 'volume',
  initialState: 'off',
  reducers: {
    setVolume: (state, action) => {
      return action.payload;
    },
  },
});

export const { setVolume } = volumeSlice.actions;
export default volumeSlice.reducer;
