import React, { useEffect, useMemo, useState } from 'react';
import styles from './StepTwo.module.scss';
import UploadImage from '../../../../atoms/uploadImage/UploadImage';
import Text from '../../../../atoms/text/Text';
import Separator from '../../../../atoms/separator/Separator';
import InnerButton from '../../../../atoms/innerButton/innerButton';
import profile from '../../../../../assets/icons/imageUploads/profilePictureBlack.svg';
import people from '../../../../../assets/icons/imageUploads/peopleBlack.svg';
import diploma from '../../../../../assets/icons/imageUploads/diploma.svg';
import papers from '../../../../../assets/icons/imageUploads/papers.svg';
import plus from '../../../../../assets/icons/close.svg';
import { Formik, Form } from 'formik';
import { stepTwoValidationSchema } from './factory/formFactory';
import Label from '../../../../atoms/lable/Label';
import endpoints from '../../../../../api/endpoints';
import { useGetQuery } from '../../../../../services/apiCall';
import Loader from '../../../../atoms/loader/Loader';
import { errorMessage } from '../../../../atoms/toast/Toast';

function StepTwo({ stepRef, activeStep }) {
  const [sections, setSections] = useState(1);
  const [init, setInit] = useState({
    Passport_File: '',
    EmiratesId_File: '',
    FamilyBook_File: '',
    ProfilePicture_File: '',
    CV_File: '',
    NationalService_Files: [
      {
        nationalService_File: '',
        index: 0,
      },
    ],
  });

  const apiUrl = endpoints.registrationForm.getStage2;
  const applicantId = localStorage.getItem('applicantId');
  const applicationId = localStorage.getItem('applicationId');
  const {
    data,
    error: apiError,
    isLoading,
    refetch,
  } = useGetQuery({
    apiUrl: apiUrl,
    params: [applicantId, applicationId],
  });
  const handleSubmit = (values) => {};
  // const initialValues = useMemo(() => {
  //   return getInitialValues(storedSteps);
  // }, [storedSteps]);

  useEffect(() => {
    const nationalServiceFiles = data?.applicantFiles.filter(
      (item) => item.fileType === 13
    );

    const nationalServiceArray = nationalServiceFiles?.map((file, index) => ({
      'national-file': file,
      index: index,
    }));

    const initialValues = {
      Passport_File: data?.applicantFiles.find((item) => item.fileType === 1),
      EmiratesId_File: data?.applicantFiles.find((item) => item.fileType === 2),
      FamilyBook_File: data?.applicantFiles.find((item) => item.fileType === 3),
      ProfilePicture_File: data?.applicantFiles.find(
        (item) => item.fileType === 14
      ),
      CV_File: data?.applicantFiles.find((item) => item.fileType === 5),
      NationalService_Files:
        nationalServiceArray?.length > 0
          ? nationalServiceArray?.map((test) => ({
              nationalService_File:
                test['national-file'] && test['national-file']
                  ? test['national-file']
                  : null,
              index: 0,
            }))
          : [
              {
                nationalService_File: '',
                index: 0,
              },
            ],
    };

    setInit(initialValues);
  }, [data]);

  const handleAddMore = (setFieldValue, values) => {
    const initialUploads = [...values.NationalService_Files, null];
    setFieldValue('NationalService_Files', initialUploads);
  };
  const handleDelete = (index, values, setFieldValue) => {
    if (values.NationalService_Files.length === 1) {
      // If it's the only item in the array, only remove the value
      const updatedImages = [...values.NationalService_Files];
      updatedImages[index] = null;
      setFieldValue('NationalService_Files', updatedImages);
    } else {
      // If there is more than one item, remove both value and UI
      const updatedImages = [...values.NationalService_Files];
      updatedImages.splice(index, 1);
      setFieldValue('NationalService_Files', updatedImages);
    }
  };
  const addSection4 = ({ values, setFieldValue }) => {
    const newSection = {
      nationalService_File: '',
      index: 0,
    };
    setFieldValue('NationalService_Files', [
      ...(values && values.NationalService_Files
        ? values.NationalService_Files
        : []),
      newSection,
    ]);
    setSections(sections + 1);
  };
  useEffect(() => {
    refetch();
  }, []);
  if (apiError) {
    errorMessage();
  }
  return (
    <Formik
      innerRef={stepRef}
      initialValues={init}
      enableReinitialize={true}
      validationSchema={stepTwoValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue, handleBlur }) => {
        return (
          <Form>
            {isLoading ? <Loader fullPage /> : null}
            <div className={styles['Step-two']}>
              <Text
                title="registrationForm.StepTwo.PERSONAL_DOCUMENTS"
                variant="text-regular-18-black"
                style={{
                  textTransform: 'uppercase',
                }}
              />
              <div className={styles['grid-container']}>
                <div className={styles['grid-item']}>
                  <UploadImage
                    label={'registrationForm.StepTwo.Passport_Picture'}
                    svg={papers}
                    name="Passport_File"
                    value={values.Passport_File}
                    onChange={(name, file) => {
                      setFieldValue(name, file);
                    }}
                    onBlur={handleBlur}
                    error={touched.Passport_File && errors.Passport_File}
                    required
                    allowedExtensions={['jpg', 'jpeg', 'png']}
                  />
                </div>
                <div className={styles['grid-item']}>
                  <UploadImage
                    label={'registrationForm.StepTwo.Emirates_Id'}
                    name="EmiratesId_File"
                    value={values.EmiratesId_File}
                    onChange={(name, file) => {
                      setFieldValue(name, file);
                    }}
                    onBlur={handleBlur}
                    error={touched.EmiratesId_File && errors.EmiratesId_File}
                    required
                    allowedExtensions={['jpg', 'jpeg', 'png']}
                  />
                </div>
                <div className={styles['grid-item']}>
                  <UploadImage
                    label={'registrationForm.StepTwo.The_Family_Book'}
                    svg={people}
                    name="FamilyBook_File"
                    value={values.FamilyBook_File}
                    onChange={(name, file) => {
                      setFieldValue(name, file);
                    }}
                    onBlur={handleBlur}
                    error={touched.FamilyBook_File && errors.FamilyBook_File}
                    required
                    allowedExtensions={['jpg', 'jpeg', 'png']}
                  />
                </div>
                <div className={styles['grid-item']}>
                  <UploadImage
                    label={'registrationForm.StepTwo.Profile_Picture'}
                    svg={profile}
                    name="ProfilePicture_File"
                    value={values.ProfilePicture_File}
                    onChange={(name, file) => {
                      setFieldValue(name, file);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.ProfilePicture_File && errors.ProfilePicture_File
                    }
                    required
                    allowedExtensions={['jpg', 'jpeg', 'png']}
                  />
                </div>
              </div>
              <Separator direction="horizontal" />
              <Text
                title={'registrationForm.StepTwo.CV'}
                variant="text-bold-italic-18-black"
              />
              <UploadImage
                label={'registrationForm.StepTwo.Attach_Document'}
                title={'registrationForm.StepTwo.CV'}
                svg={diploma}
                name="CV_File"
                value={values.CV_File}
                onChange={(name, file) => {
                  setFieldValue(name, file);
                }}
                onBlur={handleBlur}
                error={touched.CV_File && errors.CV_File}
                allowedExtensions={['pdf', 'doc', 'docx']}
                required
              />
              {/* <Separator direction="horizontal" /> */}
              {/* <Text
                title={
                  'registrationForm.StepTwo.NATIONAL_SERVICE_ACCOMPLISHMENT_(optional)'
                }
                variant="text-regular-18-black"
                style={{
                  textTransform: 'uppercase',
                }}
              /> */}
              {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={styles['multi-image-label']}>
                  <Label
                    label={'registrationForm.StepTwo.Attach_Document_If_Needed'}
                  />
                </div>

                {values &&
                  values?.NationalService_Files?.map((section, index) => (
                    <UploadImage
                      label={''}
                      title={'document'}
                      svg={papers}
                      name={`NationalService_Files[${index}].nationalService_File`}
                      value={section.nationalService_File}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={
                        touched.NationalService_Files &&
                        errors.NationalService_Files
                      }
                      allowedExtensions={['pdf', 'docx']}
                    />
                  ))}
              </div> */}

              {/* <div className={styles.onPhone}>
                <InnerButton
                  title={'registrationForm.StepTwo.Add_more'}
                  reverse
                  svg={plus}
                  type="button"
                  onClick={() => addSection4({ setFieldValue, values })}
                />
              </div> */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default StepTwo;
