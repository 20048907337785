import * as Yup from 'yup';

import { emailRegex, nameRegex, phoneRegex } from '../../../Validation';

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  // degree: null,
  // program: '',
  gender: '',
  dob: '',
};
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('registrationPage.invalid_email')
    .matches(emailRegex, 'validation.invalid_format')
    .required('registrationPage.Email_required'),
  firstName: Yup.string()
    .matches(nameRegex, 'validation.invalid_format')
    .required('registrationPage.FirstName_required'),
  lastName: Yup.string()
    .matches(nameRegex, 'validation.invalid_format')
    .required('registrationPage.LastName_required'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'validation.invalid_format')
    .min(7, 'registrationPage.PhoneNumber_tooShort')
    .required('registrationPage.PhoneNumber_required'),
  // degree: Yup.string().required('registrationPage.degree_required'),
  // program: Yup.string().required('registrationPage.Program_required'),
  dob: Yup.date().required('registrationPage.dob_required'),
  gender: Yup.string().required('registrationPage.gender_required'),
});

export { validationSchema, initialValues };
