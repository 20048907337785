import React, { useState } from 'react';
import rightArrow from '../../../../../assets/icons/linkRightArrow.svg';
import styles from './Factory.module.scss';
import Text from '../../../../atoms/text/Text';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeAllModals } from '../../../../../app/globals/slice/sideModalSlice/SideModalSlice';

const MobileMenu = ({ data, className, isRtl }) => {
  const [activeLink, setActiveLink] = useState(null);
  const handleCLick = (index) => {
    setActiveLink(activeLink === index ? null : index);
  };
  return (
    <div
      className={`${styles.menu} ${className ?? ''} ${
        isRtl ? styles['rtl-menu'] : ''
      }`}
    >
      {data?.map((item, index) => {
        if (item.hidden) return null;

        const textClass = `${styles.link} ${
          activeLink === index ? styles['active-link'] : ''
        }`;

        return (
          <React.Fragment key={index}>
            <Text
              variant="text-bold-italic-18"
              title={item.title}
              style={{ textTransform: 'uppercase' }}
              svg={item.children ? rightArrow : null}
              svgPosition="right"
              className={textClass}
              onClick={() => handleCLick(index)}
            />
            {activeLink === index && (
              <Navigation data={item?.children} item={item} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
const Navigation = ({ data, item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //class for the grid when no chilrens or with childrens
  let gridClass = styles['sub-menu'];

  //change the class according to the childrens
  if (data && data[2]?.children) {
    gridClass = `${styles['sub-menu']} ${styles['flexed-row']}`;
  }
  const navigateTo = (item) => {
    dispatch(closeAllModals());
    setTimeout(() => {
      navigate(item.route, {
        state: item.routeState,
        replace: item.shouldReplace,
      });
    }, 500);
  };
  return (
    <div className={styles.navigation}>
      <Text
        variant="text-bold-italic-15"
        title={item?.title}
        style={{ textTransform: 'uppercase' }}
        className={styles['link-copy']}
        onClick={() => navigateTo(item)}
      />
      <div className={gridClass}>
        {data?.map((child, index) => {
          if (child?.hidden) return null;
          if (child.children) {
            return (
              <div className={styles['inner-child-container']}>
                <Text
                  key={index}
                  variant="text-regular-16"
                  title={child?.title}
                  className={styles['child-link']}
                  style={{ textTransform: 'uppercase' }}
                  onClick={() => navigateTo(child)}
                />
                {child?.children?.map((sub, index) => (
                  <div key={index} className={styles['inner-subs-container']}>
                    <Text
                      key={index}
                      variant="text-regular-14"
                      title={sub?.title}
                      className={styles['child-link']}
                      onClick={() => navigateTo(child.children[index])}
                    />
                  </div>
                ))}
              </div>
            );
          } else {
            return (
              <Text
                key={index}
                variant="text-bold-italic-16"
                title={child?.title}
                style={{ textTransform: 'uppercase' }}
                className={styles['main-child-link']}
                onClick={() => navigateTo(child)}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export { MobileMenu, Navigation };
