import React, { useEffect, useState } from 'react';
import styles from './GraduationNavigation.module.scss';
import adjustSign from '../../../assets/icons/graduation/adjustSign.svg';
import { useMediaQuery } from 'react-responsive';
import {
  SvgCard,
  YearRangeSVG,
  YearRangeTitle,
  YearRangeVertical,
} from '../../atoms/yearRangeTitle/YearRangeTitle';
import { useTranslation } from 'react-i18next';

const GraduationNavigation = ({
  data,
  active,
  direction = 'ltr',
  fixed,
  titleHasLine,
  titleHasVerticalLine,
  title,
  handleSlideChange,
  yearsClass,
}) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';

  const isSmallScreen = useMediaQuery({ maxWidth: 668 });
  const isTablet = useMediaQuery({ maxWidth: 1200 });

  const [activeItem, setActiveItem] = useState(data[active]);
  const [isFixed, setFixed] = useState(isTablet ? true : fixed);
  const handleItemClick = (item) => {
    if (handleSlideChange) {
      handleSlideChange(item.id);
    } else setActiveItem(item);
  };
  const from = data.length > 0 ? data[0].year : '';
  const to =
    data.length > 0
      ? (parseInt(data[data.length - 1].year) + 1).toString()
      : '';
  return (
    <div
      className={`${styles['graduation-navigation']} ${isRtl ? 'rtl' : ''}`}
      style={{
        // direction: isTablet ? 'ltr' : direction,
        marginTop: direction === 'rtl' ? '2%' : '2%',
      }}
    >
      <YearRangeTitle
        from={title ? '' : from}
        to={title ? '' : to}
        title={title ?? 'About_The_Academy.Graduation'}
        isTitled={title ? true : false}
        direction={isRtl ? 'rtl' : ''}
        islined={titleHasLine}
        hasVerticalLine={titleHasVerticalLine}
      />
      <div className={styles.years}>
        <div
          className={`${styles['years-grid']} ${yearsClass ? yearsClass : ''}`}
        >
          {data.map((item) =>
            item?.id === activeItem?.id ? (
              <YearRangeSVG
                key={item.year}
                from={item.year}
                to={
                  item.isNotMulty
                    ? ''
                    : (parseInt(item.gapTo ?? item.year) + 1).toString()
                }
                gapTo={item.gapTo}
                isNotMulty={item.isNotMulty}
                title="About_The_Academy.Graduation"
                svg={adjustSign}
                history={item.history}
                svgPosition="left"
                className={
                  direction === isRtl ? styles.active : styles.activeRtl
                }
                Children={
                  !isFixed ? (
                    <div className={styles['card-container']}>
                      {activeItem?.data?.map((val, index) => (
                        <SvgCard
                          key={index}
                          subTitle={val.info}
                          title={val.title}
                          svg={adjustSign}
                          svgPosition="left"
                          direction={direction}
                          type={val.type}
                          year={val.year}
                          isPrev={val.isPrev}
                        />
                      ))}
                    </div>
                  ) : null
                }
              />
            ) : (
              <YearRangeVertical
                key={item.year}
                from={item.year}
                to={
                  item.isNotMulty
                    ? ''
                    : (parseInt(item.gapTo ?? item.year) + 1).toString()
                }
                title="About_The_Academy.Graduation"
                svg={adjustSign}
                svgPosition="left"
                onClick={() => handleItemClick(item)}
                isNotMulty={item.isNotMulty}
                gapTo={item.gapTo}
              />
            )
          )}
        </div>
      </div>
      {isFixed ? (
        <div className={styles['card-container']}>
          {activeItem?.data?.map((val, index) => (
            <SvgCard
              key={index}
              subTitle={val.info}
              title={val.title}
              svg={adjustSign}
              svgPosition="left"
              isActive={activeItem && activeItem.year === activeItem.year}
              type={val.type}
              year={val.year}
              isPrev={val.isPrev}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default GraduationNavigation;
