import React, { useEffect, useMemo, useState } from 'react';
import styles from './StepFour.module.scss';
import { useSelector } from 'react-redux';
import Text from '../../../../atoms/text/Text';
import List from '../../../../molecule/list/List';
import Separator from '../../../../atoms/separator/Separator';
import diploma from '../../../../../assets/icons/imageUploads/diploma.svg';
import UploadImage from '../../../../atoms/uploadImage/UploadImage';
import Input from '../../../../atoms/input/Input';
import TextArea from '../../../../atoms/textArea/TextArea';
import InnerButton from '../../../../atoms/innerButton/innerButton';
import RadioButton from '../../../../atoms/radioButton/RadioButton';
import ExpandableCard from '../../../../molecule/expandableCard/ExpandableCard';
import plus from '../../../../../assets/icons/plus.svg';
import { DatePickerFormField } from '../../../../atoms/datepicker/DatePicker';
import CustomSelect from '../../../../atoms/select/CustomSelect';
import { Formik } from 'formik';
import {
  // getInitialValues,
  items,
  optionsArray,
  stepFourValidationSchema,
} from './factory/formFactory';
import endpoints from '../../../../../api/endpoints';
import { useGetQuery } from '../../../../../services/apiCall';
import Loader from '../../../../atoms/loader/Loader';
import { useParams } from 'react-router-dom';

function StepFour({ stepRef, activeStep }) {
  const { type } = useParams();

  // const storedSteps = useSelector((state) => state.steps.stepFour);
  // const initialValues = useMemo(() => {
  //   return getInitialValues(storedSteps);
  // }, [storedSteps]);
  const [sections, setSections] = useState(1);
  const [init, setInit] = useState({
    AcademicInformation: [
      {
        academicDegreeFile: '',
        countryId: '',
        EducationalInstitution: '',
        degreeId: '',
        OtherDegree: '',
        graduationYear: '',
        advancedCourses: '',
        lastActivities: '',
      },
    ],
    TestFiles: [
      {
        testType: '',
        academicDocument: '',
        datetaken: '',
        registrationNumber: '',
        totalscore: '',
      },
    ],
    SATDocument: '',
    SATScore: '',
    personalResearch: [
      {
        personalResearchFile: '',
        personalResearchText: '',
      },
    ],
    ReferanceFile1: '',
    ReferanceFile2: '',
    PersonalStatement: '',
    PersonalStatementText: '',
  });
  const apiUrl = endpoints.registrationForm.getStage4;
  const applicantId = localStorage.getItem('applicantId');
  const applicationId = localStorage.getItem('applicationId');
  const {
    data,
    error: apiError,
    isLoading,
    refetch,
  } = useGetQuery({
    apiUrl: apiUrl,
    params: [applicantId, applicationId],
  });
  const handleSubmit = (values) => {};

  const handleAddMore = (setFieldValue, values) => {
    const initialUploads = [...values.uploadtheacademydocument, null];
    setFieldValue('uploadtheacademydocument', initialUploads);
  };

  // const handleDelete = (index, values, setFieldValue) => {
  //   if (values.uploadtheacademydocument.length === 1) {
  //     // If it's the only item in the array, only remove the value
  //     const updatedImages = [...values.uploadtheacademydocument];
  //     updatedImages[index] = null;
  //     setFieldValue('uploadtheacademydocument', updatedImages);
  //   } else {
  //     // If there is more than one item, remove both value and UI
  //     const updatedImages = [...values.uploadtheacademydocument];
  //     updatedImages.splice(index, 1);
  //     setFieldValue('uploadtheacademydocument', updatedImages);
  //   }
  // };
  const addSection = ({ values, setFieldValue }) => {
    const newSection = {
      academicDegreeFile: '',
      countryId: '',
      EducationalInstitution: '',
      degreeId: '',
      graduationYear: '',
      advancedCourses: '',
      lastActivities: '',
    };
    setFieldValue('AcademicInformation', [
      ...(values && values.AcademicInformation
        ? values.AcademicInformation
        : []),
      newSection,
    ]);
    setSections(sections + 1);
  };
  
  useEffect(() => {
    const initialValues = {
      AcademicInformation:
        data?.acadamicInformation && data?.acadamicInformation.length > 0
          ? data?.acadamicInformation?.map((test) => ({
              countryId: test.countryId,
              academicDegreeFile:
                test.files && test.files[0] ? test.files[0] : null,
              EducationalInstitution: test.EducationalInstitution || '',
              degreeId: test.degreeId || '',
              OtherDegree: test.OtherDegree,
              graduationYear: test.graduationYear || '',
              advancedCourses: test.advancedCourses || '',
              lastActivities: test.lastActivities || '',
            }))
          : [
              {
                academicDegreeFile: '',
                countryId: '',
                EducationalInstitution: '',
                degreeId: '',
                graduationYear: '',
                advancedCourses: '',
                lastActivities: '',
              },
            ],
      TestFiles:
        data?.applicantTest && data?.applicantTest.length > 0
          ? data?.applicantTest?.map((test) => ({
              testType: test.testType.toString(),
              academicDocument:
                test.files && test.files[0] ? test.files[0] : null,
              datetaken: test.dateTaken?.substring(0, 10),
              registrationNumber: test.registrationNumber || '',
              totalscore: test.totalScore || '',
            }))
          : [
              {
                testType: '',
                academicDocument: '',
                datetaken: '',
                registrationNumber: '',
                totalscore: '',
              },
            ],
      SATDocument: data?.applicantFiles?.find((item) => item.fileType === 7),
      SATScore: data?.application?.satScore || '',
      personalResearch:
        data?.applicantReferance && data?.applicantReferance.length > 0
          ? data?.applicantReferance?.map((test) => ({
              personalResearchText: test.personalResearchText,
              personalResearchFile:
                test.files && test.files[0] ? test.files[0] : null,
            }))
          : [
              {
                personalResearchText: '',
                personalResearchFile: '',
              },
            ],
      ReferanceFile1:
        data?.applicantFiles?.find((item) => item.fileType === 10) || '',
      ReferanceFile2:
        data?.applicantFiles?.find((item) => item.fileType === 11) || '',
      PersonalStatement:
        data?.applicantFiles?.find((item) => item.fileType === 8) || '  ',
      PersonalStatementText: data?.stage2?.personalStatement,
    };

    // Set the updated initial values
    setInit(initialValues);
  }, [data]);
  const addSection2 = ({ values, setFieldValue }) => {
    const newSection = {
      testType: '',
      academicDocument: '',
      schoolname: '',
      datetaken: '',
      registrationNumber: '',
      totalscore: '',
    };
    setFieldValue('TestFiles', [
      ...(values && values.TestFiles ? values.TestFiles : []),
      newSection,
    ]);
    setSections(sections + 1);
  };

  const addSection4 = ({ values, setFieldValue }) => {
    const newSection = {
      personalResearchFile: '',
      personalResearchText: '',
    };
    setFieldValue('personalResearch', [
      ...(values && values.personalResearch ? values.personalResearch : []),
      newSection,
    ]);
    setSections(sections + 1);
  };

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {}, [init]);
  return (
    <Formik
      innerRef={stepRef}
      initialValues={init}
      enableReinitialize={true}
      validationSchema={stepFourValidationSchema(type)}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, errors, touched, setFieldValue, handleBlur }) => {
        isLoading && <Loader fullPage />;
        console.log('cdsbcd', values,errors);
        return (
          <div className={styles['Step-four']}>
            <div className={styles['list-container']}>
              <Text
                title="registrationForm.StepFour.After_Submission_Scan"
                variant="text-bold-italic-18-black"
                style={{
                  marginTop: '3rem',
                  textTransform: 'uppercase',
                }}
              />
              <List items={items} direction="column" itemPaddingStart="20px" />
            </div>
            <Separator direction="horizontal" />
            <Text
              title="registrationForm.StepFour.Academic_Information"
              variant="text-regular-18-black"
              style={{
                textTransform: 'uppercase',
              }}
            />
            {values &&
              values?.AcademicInformation?.map((section, index) => {
                return (
                  <>
                    <UploadImage
                      label="registrationForm.StepFour.Upload_Document"
                      title="registrationForm.StepFour.Academic_Information"
                      name={`AcademicInformation[${index}].academicDegreeFile`}
                      value={section.academicDegreeFile}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={
                        touched?.AcademicInformation?.[index]
                          ?.academicDegreeFile &&
                        errors?.AcademicInformation?.[index]?.academicDegreeFile
                      }
                      svg={diploma}
                      required
                      allowedExtensions={['pdf', 'doc', 'docx']}
                    />
                    <div className={styles['input-row']}>
                      <CustomSelect
                        label="registrationForm.StepFour.Country_You_Graduated_From"
                        styleType="formField"
                        required
                        type="8"
                        name={`AcademicInformation[${index}].countryId`}
                        value={section.countryId}
                        onChange={(name, selectedOption) =>
                          setFieldValue(name, selectedOption)
                        }
                        onBlur={handleBlur}
                        error={
                          touched?.AcademicInformation?.[index]?.countryId &&
                          errors?.AcademicInformation?.[index]?.countryId
                        }
                      />
                      {/* <CustomSelect
                        label="registrationForm.StepFour.School_Name"
                        styleType="formField"
                        required
                        type="10"
                        parent={section.countryId}
                        name={`AcademicInformation[${index}].EducationalInstitution`}
                        value={section.EducationalInstitution}
                        onChange={(name, selectedOption) =>
                          setFieldValue(name, selectedOption)
                        }
                        onBlur={handleBlur}
                        error={
                          touched?.AcademicInformation?.[index]
                            ?.EducationalInstitution &&
                          errors?.AcademicInformation?.[index]
                            ?.EducationalInstitution
                        }
                      /> */}
                      <Input
                        label="registrationForm.StepFour.School_Name"
                        styleType="formField"
                        required
                        name={`AcademicInformation[${index}].EducationalInstitution`}
                        value={section.EducationalInstitution}
                        onChangeHandler={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched?.AcademicInformation?.[index]
                            ?.EducationalInstitution &&
                          errors?.AcademicInformation?.[index]
                            ?.EducationalInstitution
                        }
                      />
                      <CustomSelect
                        label="registrationForm.StepFour.HS_Diploma_Type"
                        styleType="formField"
                        required
                        type="7"
                        name={`AcademicInformation[${index}].degreeId`}
                        value={section.degreeId}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched?.AcademicInformation?.[index]?.degreeId &&
                          errors?.AcademicInformation?.[index]?.degreeId
                        }
                      />
                      {console.log('asdasdassccccc', section.degreeId)}
                      {section.degreeId ===
                        '40615b05-60ca-4d06-9586-944c3eba7b15' && (
                        <Input
                          label="registrationForm.StepFour.HS_Diploma_Type"
                          styleType="formField"
                          name={`AcademicInformation[${index}].OtherDegree`}
                          value={section.OtherDegree}
                          onChangeHandler={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          onBlur={handleBlur}
                          error={
                            touched?.AcademicInformation?.[index]
                              ?.OtherDegree &&
                            errors?.AcademicInformation?.[index]?.OtherDegree
                          }
                          required
                        />
                      )}
                      <Input
                        label="registrationForm.StepFour.Graduation_YEar"
                        styleType="formField"
                        name={`AcademicInformation[${index}].graduationYear`}
                        value={section.graduationYear}
                        onChangeHandler={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched?.AcademicInformation?.[index]
                            ?.graduationYear &&
                          errors?.AcademicInformation?.[index]?.graduationYear
                        }
                        required
                      />
                    </div>

                    <div className={styles['text-area-container']}>
                      <TextArea
                        label="registrationForm.StepFour.Please_LISt_Advanced_Courses"
                        name={`AcademicInformation[${index}].advancedCourses`}
                        value={section.advancedCourses}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                      />
                      <TextArea
                        label="registrationForm.StepFour.Activities_Not_Enrolled"
                        name={`AcademicInformation[${index}].lastActivities`}
                        value={section.lastActivities}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    </div>
                  </>
                );
              })}
            <div>
              <div className={styles.onPhone}>
                <InnerButton
                  title="registrationForm.StepFour.Add_More"
                  reverse
                  svg={plus}
                  className={styles.addMoreButton}
                  onClick={() => addSection({ setFieldValue, values })}
                />
              </div>
            </div>
            <Separator direction="horizontal" />
            <div>
              <Text
                title="registrationForm.StepFour.ACADEMIC_IELTS"
                variant="text-bold-italic-18-black"
                style={{
                  textTransform: 'uppercase',
                }}
              />
              <ExpandableCard
                title={'registrationForm.StepFour.Further_Details'}
              >
                <List
                  items={items}
                  direction="column"
                  containerStyle={{ width: '100%', marginTop: '1rem' }}
                  listStyle={{ gap: '0.5rem' }}
                />
              </ExpandableCard>
              {values &&
                values?.TestFiles?.map((section, index) => {
                  return (
                    <>
                      <RadioButton
                        label="registrationForm.StepFour.Choose_Test"
                        name={`TestFiles[${index}]testType`}
                        value={section.testType}
                        onOptionChange={(name, newOption) =>
                          setFieldValue(name, newOption)
                        }
                        textStyle={{ opacity: 0.7 }}
                        direction="row"
                        options={optionsArray}
                        onBlur={handleBlur}
                        style={{ marginTop: '2rem', flexDirection: 'row' }}
                      />
                      {touched?.TestFiles?.[index]?.testType &&
                        errors?.TestFiles?.[index]?.testType && (
                          <Text
                            title={errors?.TestFiles?.[index]?.testType}
                            variant="text-regular-15"
                            className={styles['text']}
                            style={{ color: 'red' }}
                          />
                        )}
                      <UploadImage
                        svg={diploma}
                        label="registrationForm.StepFour.Upload_Document"
                        title="registrationForm.StepFour.Upload_Academic_Document"
                        style={{ marginTop: '3rem' }}
                        name={`TestFiles[${index}].academicDocument`}
                        value={section.academicDocument}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        error={
                          touched?.TestFiles?.[index]?.academicDocument &&
                          errors?.TestFiles?.[index]?.academicDocument
                        }
                        required
                        allowedExtensions={['pdf', 'doc', 'docx']}
                      />

                      <div
                        className={`${styles['input-row']} ${styles['m-top-1']}`}
                      >
                        <DatePickerFormField
                          label="registrationForm.StepFour.Date_Taken"
                          required
                          name={`TestFiles[${index}].datetaken`}
                          value={section.datetaken}
                          onChange={(name, selectedDate) =>
                            setFieldValue(name, selectedDate)
                          }
                          onBlur={handleBlur}
                          error={
                            touched?.TestFiles?.[index]?.datetaken &&
                            errors?.TestFiles?.[index]?.datetaken
                          }
                        />
                        <Input
                          label="registrationForm.StepFour.Registration_No"
                          styleType="formField"
                          name={`TestFiles[${index}].registrationNumber`}
                          value={section.registrationNumber}
                          onChangeHandler={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          onBlur={handleBlur}
                          error={
                            touched?.TestFiles?.[index]?.registrationNumber &&
                            errors?.TestFiles?.[index]?.registrationNumber
                          }
                          required
                        />
                        <Input
                          label="registrationForm.StepFour.Total_Score"
                          styleType="formField"
                          name={`TestFiles[${index}].totalscore`}
                          value={section.totalscore}
                          onChangeHandler={(name, value) => {
                            setFieldValue(name, value);
                          }}
                          onBlur={handleBlur}
                          error={
                            touched?.TestFiles?.[index]?.totalscore &&
                            errors?.TestFiles?.[index]?.totalscore
                          }
                          required
                        />
                      </div>
                    </>
                  );
                })}

              <div>
                <InnerButton
                  title="registrationForm.StepFour.Add_More"
                  reverse
                  svg={plus}
                  className={styles.addMoreButton}
                  onClick={() => addSection2({ setFieldValue, values })}
                />
              </div>
            </div>
            <Separator direction="horizontal" />
            <div>
              <Text
                title="registrationForm.StepFour.SAT_SCORE"
                variant="text-bold-italic-18-black"
                style={{
                  textTransform: 'uppercase',
                }}
              />

              <UploadImage
                svg={diploma}
                label="registrationForm.StepFour.Upload_Document"
                title="registrationForm.StepFour.Upload_Academic_Document"
                style={{ marginTop: '3rem' }}
                name="SATDocument"
                value={values.SATDocument}
                onChange={setFieldValue}
                onBlur={handleBlur}
                error={touched.SATDocument && errors.SATDocument}
                required
                allowedExtensions={['pdf', 'doc', 'docx']}
              />
              <div
                className={`${styles['input-row-flex-3']} ${styles['m-top-1']}`}
              >
                <Input
                  label="registrationForm.StepFour.Score"
                  styleType="formField"
                  name="SATScore"
                  value={values.SATScore}
                  onChangeHandler={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  onBlur={handleBlur}
                  error={touched.SATScore && errors.SATScore}
                  required
                />
              </div>
            </div>
            {type === 'graduate' ? (
              <div>
                <Separator direction="horizontal" />
                <Text
                  title="registrationForm.StepFour.Personal_Research"
                  variant="text-bold-italic-18-black"
                  style={{
                    textTransform: 'uppercase',
                  }}
                />
                {values &&
                  values?.personalResearch?.map((section, index) => {
                    return (
                      <>
                        <UploadImage
                          svg={diploma}
                          label="registrationForm.StepFour.Upload_Document"
                          title="registrationForm.StepFour.Upload_Academic_Document"
                          style={{ marginTop: '3rem' }}
                          name={`personalResearch[${index}].personalResearchFile`}
                          value={section.personalResearchFile}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          error={
                            touched?.personalResearch?.[index]
                              ?.personalResearchFile &&
                            errors?.personalResearch?.[index]
                              ?.personalResearchFile
                          }
                          required
                          allowedExtensions={['pdf', 'doc', 'docx']}
                        />
                        <div className={`${styles['m-top-1']}`}>
                          <TextArea
                            label="registrationForm.StepFour.A_Brief_Description"
                            name={`personalResearch[${index}].personalResearchText`}
                            value={section.personalResearchText}
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                            }}
                          />
                        </div>
                      </>
                    );
                  })}
                <div>
                  <InnerButton
                    title="registrationForm.StepFour.Add_More"
                    reverse
                    svg={plus}
                    className={styles.addMoreButton}
                    onClick={() => addSection4({ setFieldValue, values })}
                  />
                </div>
              </div>
            ) : null}
            {type === 'graduate' ? (
              <>
                <Separator direction="horizontal" />
                <div>
                  <Text
                    title="registrationForm.StepFour.Reference_Letter"
                    variant="text-bold-italic-18-black"
                    style={{
                      textTransform: 'uppercase',
                    }}
                  />

                  <div className={styles['input-row-2']}>
                    <UploadImage
                      required
                      label="registrationForm.StepFour.First_Reference_Letters"
                      svg={diploma}
                      title="registrationForm.StepFour.Upload_Academic_Document"
                      name="ReferanceFile1"
                      value={values.ReferanceFile1}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={touched.ReferanceFile1 && errors.ReferanceFile1}
                      allowedExtensions={['jpg', 'jpeg', 'png']}
                    />
                    <UploadImage
                      required
                      label="registrationForm.StepFour.Second_Reference_Letters"
                      svg={diploma}
                      title="registrationForm.StepFour.Upload_Academic_Document"
                      name="ReferanceFile2"
                      value={values.ReferanceFile2}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={touched.ReferanceFile2 && errors.ReferanceFile2}
                      allowedExtensions={['jpg', 'jpeg', 'png']}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <Separator direction="horizontal" />{' '}
            <div>
              <Text
                title="registrationForm.StepFour.Personal_Statement"
                variant="text-bold-italic-18-black"
                style={{
                  textTransform: 'uppercase',
                }}
              />
              <UploadImage
                required
                label="registrationForm.StepTwo.Attach_Document"
                svg={diploma}
                title="registrationForm.StepFour.Personal_Statement"
                name="PersonalStatement"
                value={values.PersonalStatement}
                onChange={setFieldValue}
                onBlur={handleBlur}
                error={touched.PersonalStatement && errors.PersonalStatement}
                allowedExtensions={['jpg', 'jpeg', 'png']}
              />
              <TextArea
                label="registrationForm.StepFour.Personal_Statement__Opportunity"
                style={{ marginTop: '2rem' }}
                name="PersonalStatementText"
                value={values.PersonalStatementText}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
                // onBlur={handleBlur}
                error={
                  touched.PersonalStatementText && errors.PersonalStatementText
                }
                required
                allowedExtensions={['pdf', 'doc', 'docx']}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default StepFour;
