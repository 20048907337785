import React, { useState } from 'react';
import styles from './ToggleList.module.scss';
import Text from '../../atoms/text/Text';
import { useNavigate } from 'react-router-dom';

const ToggleList = ({ fontSize, data, isNavigable = false, ref }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (route) => {
    navigate(route.route, {
      state: { state: { type: route.type } },
    });
  };
  const handleTitleClick = (itemData, index) => {
    if (isNavigable && itemData.route) {
      handleItemClick(itemData);
    } else {
      setActiveIndex(index === activeIndex ? null : index);
    }
  };
  return data?.map((itemData, index) => (
    <div
      key={index}
      className={`${styles['toggle-list']} ${styles[itemData.direction]}`}
    >
      <Text
        title={itemData.title}
        variant="text-bold-italic-30"
        style={{ textTransform: 'uppercase' }}
        onClick={() => handleTitleClick(itemData, index)}
        fontSize={fontSize}
        className={`${styles['toggle-text']} ${
          index === activeIndex ? styles['active'] : ''
        }`}
        ref={ref}
      />

      {!itemData.isLink ? (
        <ul
          className={`${styles['list']} ${
            index === activeIndex ? styles['show'] : ''
          }`}
        >
          {itemData.items?.map((item, index) => (
            <li key={index}>
              <Text
                title={item.label}
                variant="text-regular-20"
                style={{ textTransform: 'uppercase' }}
                fontSize={fontSize}
                className={styles['text-hover']}
                onClick={() => handleItemClick(item)}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  ));
};
const ToggleListMobile = ({ fontSize, data, isNavigable = false }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (route) => {
    navigate(route.route, {
      state: { state: { type: route.type } },
    });
  };
  const handleTitleClick = (itemData, index) => {
    if (isNavigable && itemData.route) {
      handleItemClick(itemData);
    } else {
      setActiveIndex(index === activeIndex ? null : index);
    }
  };
  return (
    <div className={`${styles.mobile}`}>
      <Text
        title={data[0].title}
        variant="text-bold-italic-30"
        style={{ textTransform: 'uppercase' }}
        onClick={() => navigate('')}
        className={`${styles['toggle-text']}`}
      />
      {data[0].items?.map((item, index) => (
        <Text
          key={index}
          title={item.label}
          variant="text-regular-20"
          style={{ textTransform: 'uppercase' }}
          fontSize={fontSize}
          className={styles['text-hover']}
          onClick={() => handleItemClick(item)}
        />
      ))}
      <Text
        title={data[1].title}
        variant="text-bold-italic-30"
        style={{ textTransform: 'uppercase' }}
        onClick={() => navigate('')}
        className={`${styles['toggle-text']}`}
      />
      {data[1].items?.map((item, index) => (
        <Text
          key={index}
          title={item.label}
          variant="text-regular-20"
          style={{ textTransform: 'uppercase' }}
          fontSize={fontSize}
          className={styles['text-hover']}
          onClick={() => handleItemClick(item)}
        />
      ))}
    </div>
  );
};
export { ToggleList, ToggleListMobile };
