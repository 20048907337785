import React from 'react';
import Text from '../../atoms/text/Text';
import styles from './ContactForm.module.scss';
import Input from '../../atoms/input/Input';
import Button from '../../atoms/button/Button';
import backgroundImage from '../../../assets/images/campusLandscape.png';
import ImageWithBackdrop from '../../molecule/imageWithBackdrop/ImageWithBackdrop';
import { useSubscribeLetter } from './factory/Factory';
import { useTranslation } from 'react-i18next';
import { HeadingWithSubtext } from '../../molecule/headingWithSubtext/HeadingWithSubtext';

const ContactForm = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const { handleSubmit, formik, isLoading } = useSubscribeLetter();

  return (
    <div className="dflex-container">
      <HeadingWithSubtext
        variant="small"
        title="academics.GET_IN_CONTACT_WITH_US"
      />
      <div className={styles.contactContainer}>
        <ImageWithBackdrop
          src={backgroundImage}
          alt="Contact"
          backdropStyle={{ zIndex: -2, borderRadius: '10px' }}
          imageStyle={{ borderRadius: '10px' }}
        />
        <div className={styles.form}>
          {/* <Text
                    title="home.Contact_Us"
                    variant="text-bold-italic-25-green"
                    className={styles['text-title']}
                    className={styles.text}
                    isLineBreak
                  /> */}
          <div className={styles['contact-form']}>
            <div
              className={styles['content-container']}
              style={{
                borderRight: isRtl ? '0px' : '',
                borderLeft: isRtl ? '1px solid white' : '',
              }}
            >
              <div className={styles['contact-info']}>
                <Text
                  title="Major.Office_hours"
                  variant="text-bold-italic-30"
                  ScholarShipView
                  className={styles.text}
                />
                <Text
                  title="Major.Monday_to_Friday"
                  variant="text-regular-16"
                />
                <Text title="8:30 AM - 10:00 PM" variant="text-regular-16" />
              </div>
              <div className={styles['contact-info']}>
                <Text
                  title="home.Contact_Us"
                  variant="text-bold-italic-30"
                  className={styles.text}
                />
                <Text title="+971501234567" variant="text-regular-16" />
                <Text
                  title="amet@dubaipolice.ac.ae"
                  variant="text-regular-16"
                />{' '}
              </div>
            </div>
            <div className={styles['subscribe-container']}>
              <div className={styles['subscribe']}>
                <Text
                  title="Major.Subscribe_to_our_newsletter"
                  variant="text-bold-italic-30"
                  className={styles.text}
                />
                <form
                  onSubmit={formik.handleSubmit}
                  className={styles['actions']}
                  style={{ direction: isRtl ? 'rtl' : '' }}
                >
                  <div className={styles['actions']}>
                    <Input
                      label="registrationPage.Email_Label"
                      // placeholder="Major.enter_email"
                      styleType="primary"
                      textVariant="text-regular-16"
                      labelTextStyle={{ color: 'white' }}
                      // required
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && formik.errors.email}
                    />

                    <Button
                      title="Admission.Overview.Join-our-news-letter"
                      variant="text-regular-16-black"
                      className={styles['button']}
                      styleType="secondary"
                      onClick={handleSubmit}
                      isLoading={isLoading}
                      type="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
