import React, { useState } from 'react';
import styles from './Navigator.module.scss';
import NavigationMobile from './mobile/NavigationMobile';
import { Menu, Navigation } from './factory/Factory';

const Navigator = ({ data, isRtl }) => {
  const [hoveredItem, setHoveredItem] = useState(data[0]);

  const handleMouseEnter = (index) => {
    setHoveredItem(data[index]);
  };

  const containerClass = isRtl ? `${styles.nav} rtl` : styles.nav;

  return (
    <nav>
      <div className={containerClass}>
        <div className={styles.container}>
          <Menu
            className={styles.menu}
            data={data}
            onMouseEnter={handleMouseEnter}
            isRtl={isRtl}
          />
          {hoveredItem && <Navigation data={hoveredItem?.children} />}
        </div>
      </div>
      <div className={styles['mobile-nav']}>
        <NavigationMobile data={data} isRtl={isRtl} />
      </div>
    </nav>
  );
};

export default Navigator;
