import React from 'react';
import styles from './RatingCard.module.scss';
import Text from '../text/Text';

const RatingCard = ({ number, title, fontSize, svg, className }) => {
  return (
    <div className={`${styles['card-container']} ${className}`}>
      <div className={styles['content']}>
        <div className={styles['rating']}>
          <Text
            title={number}
            variant="text-bold-italic-50"
            style={{ textTransform: 'uppercase' }}
            fontSize={fontSize}
          />
          <Text
            title={title}
            variant="text-bold-italic-16"
            style={{ textTransform: 'uppercase' }}
            fontSize={fontSize}
          />
        </div>

        <div className={styles['dashes-separator']}></div>
        <div className={styles['svg-icon']}>
          <img src={svg} />
        </div>
      </div>
    </div>
  );
};

export default RatingCard;
