import React from 'react';
import styles from './innerButton.module.scss';
import Text from '../text/Text';
import close from '../../../assets/icons/close.svg';
import plus from '../../../assets/icons/close.svg';

const InnerButton = ({
  fontSize,
  title,
  style,
  onClick,
  reverse,
  svg,
  toUppercase,
  type,
  className,
  buttonClassName,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.button} ${reverse ? styles.reverse : ''} ${
        buttonClassName ?? ''
      } ${disabled ? styles.disabled : ''}`}
      style={style}
      type={type}
      disabled={disabled}
    >
      <Text
        title={title}
        fontSize={fontSize}
        variant={reverse ? 'text-regular-16' : 'text-bold-italic-16-black'}
        style={{
          textTransform: toUppercase ? 'uppercase' : '',
          cursor: 'pointer',
        }}
        className={className}
        svg={reverse ? svg : ''}
        textContainerStyle={{ textAlign: 'center' }}
      />
    </button>
  );
};

export default InnerButton;
