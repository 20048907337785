import { useState, useRef, useCallback, useEffect } from 'react';

const easeInOutCubic = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t * t + b;
  t -= 2;
  return (c / 2) * (t * t * t + 2) + b;
};

const useSmoothScroll = (sections, scrollDelayTime = 1200) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [prevSection, setPrevSection] = useState(null);

  const lastScrollTimeRef = useRef(0);
  const sectionRefs = sections.map(() => useRef());
  const touchStartY = useRef(0);
  const isScrollingRef = useRef(false);
  const [isScrollEnabled, setIsScrollEnabled] = useState(true);

  const smoothScrollTo = useCallback((element, duration) => {
    let start = window.scrollY;
    let end = element.getBoundingClientRect().top;
    let startTime = null;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const position = easeInOutCubic(progress, start, end, duration);
      window.scrollTo(0, position);

      if (progress < duration) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, start + end);
      }
    };

    window.requestAnimationFrame(step);
  }, []);

  const scrollToSection = useCallback(
    (sectionIndex, forceScroll = false) => {
      if (!forceScroll && isScrollingRef.current) return;

      const targetSection = sectionRefs[sectionIndex]?.current;
      if (targetSection) {
        isScrollingRef.current = true;
        smoothScrollTo(targetSection, 1200);
        setPrevSection(currentSection);
        setCurrentSection(sectionIndex);

        setTimeout(() => {
          isScrollingRef.current = false;
        }, scrollDelayTime);
      }
    },
    [smoothScrollTo, scrollDelayTime, sectionRefs, currentSection]
  );

  const setCurrentSectionExternally = useCallback(
    (sectionIndex) => {
      scrollToSection(sectionIndex, true);
    },
    [scrollToSection]
  );

  const handleEvent = useCallback(
    (event) => {
      if (!isScrollEnabled || window.isHoveringCalendar) return;

      const currentTime = Date.now();
      if (currentTime - lastScrollTimeRef.current < scrollDelayTime) {
        return;
      }

      lastScrollTimeRef.current = currentTime;
      let direction = 0;
      if (event.type === 'wheel') {
        direction = event.deltaY > 0 ? 1 : -1;
      } else if (event.type === 'keydown') {
        if (event.key === 'ArrowUp') {
          direction = -1;
        } else if (event.key === 'ArrowDown') {
          direction = 1;
        }
      }

      const nextSection = Math.min(
        Math.max(currentSection + direction, 0),
        sections.length - 1
      );

      scrollToSection(nextSection);
    },
    [
      scrollToSection,
      currentSection,
      sections.length,
      isScrollEnabled,
      scrollDelayTime,
    ]
  );

  const handleTouchStart = useCallback((event) => {
    touchStartY.current = event.touches[0].clientY;
  }, []);

  const handleTouchMove = useCallback(
    (event) => {
      if (!isScrollEnabled) return;

      const touchEndY = event.changedTouches[0].clientY;
      const touchDistance = touchStartY.current - touchEndY;
      const touchThreshold = 50;

      if (Math.abs(touchDistance) < touchThreshold) return;

      const direction = touchDistance > 0 ? 1 : -1;
      const nextSection = Math.min(
        Math.max(currentSection + direction, 0),
        sections.length - 1
      );

      if (nextSection !== currentSection) {
        scrollToSection(nextSection);
      }
    },
    [currentSection, scrollToSection, sections.length, isScrollEnabled]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        handleEvent(event);
      }
    };
    const options = { passive: true };
    document.body.style.overflow = 'hidden';
    window.addEventListener('wheel', handleEvent, options);
    window.addEventListener('touchstart', handleTouchStart, options);
    window.addEventListener('touchmove', handleTouchMove, options);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.style.overflow = '';
      window.removeEventListener('wheel', handleEvent);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouchStart, options);
      window.removeEventListener('touchmove', handleTouchMove, options);
    };
  }, [handleEvent, handleTouchStart, handleTouchMove]);

  const enableScroll = useCallback(() => {
    setIsScrollEnabled(true);
  }, []);

  const disableScroll = useCallback(() => {
    setIsScrollEnabled(false);
  }, []);

  return {
    currentSection,
    scrollToSection,
    sectionRefs,
    handleEvent,
    handleTouchStart,
    handleTouchMove,
    enableScroll,
    disableScroll,
    prevSection,
    setCurrentSectionExternally,
  };
};

export default useSmoothScroll;
