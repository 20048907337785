import * as Yup from 'yup';

const stepThreeValidationSchema = Yup.object().shape({
  ProgramOfChoice: Yup.string().required(
    'registrationForm.StepThree.required.ProgramOfChoice'
  ),
  SelectedTerm: Yup.string().required(
    'registrationForm.StepThree.required.SelectedTerm'
  ),
  IsVisa: Yup.string().required('registrationForm.StepThree.required.IsVisa'),
  ApplyingAs: Yup.number()
    .typeError('registrationForm.StepFour.required.type_error_number')
    .required('registrationForm.StepThree.required.ApplyingAs'),
});

const getInitialValues = (storedSteps) => {
  if (Object.keys(storedSteps).length > 0) {
    return storedSteps;
  }

  return {
    ProgramOfChoice: '',
    SelectedTerm: '',
    IsVisa: '',
    ApplyingAs: '',
  };
};

export { stepThreeValidationSchema, getInitialValues };
