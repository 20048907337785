import React from 'react';
import styles from './Numbers.module.scss';
import Text from '../text/Text';

const Numbers = ({ data, style }) => {
  return (
    <div style={style} className={styles['numbers-container']}>
      <div className={styles['numbers']}>
        <Text
          title={data?.bachelorCount ?? '0'}
          variant="text-bold-italic-50"
          style={{ textTransform: 'uppercase' }}
        />
        <Text
          title={'home.bachelor_graduates'}
          variant="text-bold-italic-20"
          style={{ textTransform: 'uppercase', textAlign: 'center' }}
        />
      </div>
      <div className={styles['numbers']}>
        <Text
          title={data?.mastersCount ?? '0'}
          variant="text-bold-italic-50"
          style={{ textTransform: 'uppercase' }}
        />
        <Text
          title={'home.masters_graduates'}
          variant="text-bold-italic-20"
          style={{ textTransform: 'uppercase', textAlign: 'center' }}
        />
      </div>
      <div className={styles['numbers']}>
        <Text
          title={data?.phdCount ?? '0'}
          variant="text-bold-italic-50"
          style={{ textTransform: 'uppercase' }}
        />
        <Text
          title={'home.phd_graduates'}
          variant="text-bold-italic-20"
          style={{ textTransform: 'uppercase', textAlign: 'center' }}
        />
      </div>
    </div>
  );
};

export default Numbers;
