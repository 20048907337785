import * as Yup from 'yup';

import { emailRegex } from '../../../../Validation';

const initialValues = {
  email: '',
};
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('registrationPage.invalid_email')
    .matches(emailRegex, 'validation.invalid_format')
    .required('registrationPage.Email_required'),
});

export { validationSchema, initialValues };
