import React from 'react';
import styles from './YearRangeTitle.module.scss';
import Text from '../text/Text';
import location from '../../../assets/icons/graduation/location-pin.svg';
import student from '../../../assets/icons/graduation/student.svg';
import adjust from '../../../assets/icons/graduation/adjustSign.svg';
import handDegree from '../../../assets/icons/graduation/handDegree.svg';
import employee from '../../../assets/icons/employee.svg';
import forAll from '../../../assets/icons/majorCard/forAll.svg';
import path from '../../../assets/icons/graduation/Path.svg';

const selectSvg = (title) => {
  switch (title) {
    case 'graduation.location':
      return location;
    case 'graduation.batch':
      return student;
    case 'graduation.in_honor_of':
      return handDegree;
    case 'graduation.graduating_students':
      return path;
    default:
      return adjust;
  }
};
const YearRangeTitle = ({
  from,
  to,
  title,
  onClick,
  direction = 'ltr',
  islined,
  hasVerticalLine,
  isNotMulty,
  isTitled,
}) => {
  const additionalClass =
    direction === 'rtl' ? styles.rtlClass : styles.ltrClass;
  const hasLine = islined ? styles.hasLine : '';

  return (
    <div
      onClick={onClick}
      className={`${styles['year-range-title']} ${additionalClass} ${hasLine} ${
        isTitled && styles.noGap
      }`}
    >
      {hasVerticalLine ? hasVerticalLine : null}

      <Text title={from} variant="text-bold-italic-40" />
      <Text
        title={isNotMulty || isTitled ? '' : '~'}
        variant="text-bold-italic-40"
      />
      <Text
        title={isNotMulty || isTitled ? '' : to}
        variant="text-bold-italic-40"
      />
      <Text
        title={title}
        variant="text-bold-italic-40"
        style={{ textTransform: 'uppercase' }}
      />
    </div>
  );
};

const YearRangeSVG = ({
  from,
  to,
  title,
  svg,
  svgPosition = 'right',
  onClick,
  className,
  direction,
  isNotMulty,
  Children,
  gapTo,
  history,
}) => {
  return (
    <div className={styles['year-range-svg-container']}>
      <div
        onClick={onClick}
        className={`${styles['year-range-svg']} ${className}`}
        style={{ cursor: onClick ? 'pointer' : '' }}
      >
        {svgPosition === 'left' ? <img src={svg} alt={title} /> : null}
        <div
          className={`${styles['texts-container']} ${
            styles.direction === 'rtl' ? styles.rtlClass : ''
          }`}
        >
          <Text title={from} variant="text-bold-italic-40" />
          <Text
            title={isNotMulty ? '' : gapTo ? '&' : '~'}
            variant="text-bold-italic-40"
          />
          <Text title={to} variant="text-bold-italic-40" />
        </div>

        {svgPosition === 'right' ? (
          <img src={svg} alt={'text-container'} />
        ) : null}
      </div>
      {Children}
    </div>
  );
};
const YearRangeVertical = ({ from, to, title, onClick, isNotMulty, gapTo }) => {
  return (
    <div
      onClick={onClick}
      className={styles['year-range-vertical']}
      style={
        isNotMulty
          ? {
              flexDirection: 'row',
              justifyContent: 'center',
              cursor: onClick ? 'pointer' : '',
            }
          : { cursor: onClick ? 'pointer' : '' }
      }
    >
      <Text
        title={from}
        variant="text-bold-italic-15"
        className={styles.text}
        style={{ cursor: 'pointer' }}
      />
      <Text
        title={isNotMulty ? '' : gapTo ? '&' : '~'}
        variant="text-bold-italic-15"
        className={styles.text}
        style={{ cursor: 'pointer' }}
      />
      <Text
        title={to}
        variant="text-bold-italic-15"
        className={styles.text}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

const SvgCard = ({
  subTitle,
  numbers,
  title,
  onClick,
  history,
  type,
  year,
  isPrev,
}) => {
  let svg = {};
  if (type) {
    if (type === 'cadet') svg = employee;
    if (type === 'forall') svg = forAll;
  } else svg = selectSvg(title);
  return (
    <div onClick={onClick} className={styles['svg-card']}>
      <div
        style={
          year || isPrev
            ? {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
              }
            : {}
        }
      >
        {!isPrev ? <Text title={year} variant="text-bold-italic-20" /> : null}
        <img
          src={svg}
          alt={title}
          style={year || isPrev ? { width: '40px' } : {}}
        />
      </div>
      <div
        className={styles['text-container']}
        style={
          year || isPrev
            ? { justifyContent: 'flex-end', marginBottom: '15px' }
            : {}
        }
      >
        <Text
          title={title}
          variant="text-bold-italic-20"
          className={styles.title}
        />
        <Text title={subTitle} className={styles.text} variant="text-bold-22" />
        {numbers ? <Text title={numbers} className={styles.text} /> : null}
      </div>
    </div>
  );
};
export { YearRangeTitle, YearRangeSVG, YearRangeVertical, SvgCard };
