import * as Yup from 'yup';

const stepFourValidationSchema = (type) => {
  return Yup.object().shape({
    AcademicInformation: Yup.array().of(
      Yup.object().shape({
        academicDegreeFile: Yup.mixed().required(
          'registrationForm.StepFour.required.academicdocument'
        ),
        countryId: Yup.string().required(
          'registrationForm.StepFour.required.Country_You_Graduated_From'
        ),
        EducationalInstitution: Yup.string().required(
          'registrationForm.StepFour.required.schoolname'
        ),
        degreeId: Yup.string().required(
          'registrationForm.StepFour.required.hsdiplomatype'
        ),
        graduationYear: Yup.number()
          .typeError('registrationForm.StepFour.required.type_error_number')
          .required('registrationForm.StepFour.required.graduationyear'),
        advancedCourses: Yup.string(),
        lastActivities: Yup.string(),
      })
    ),
    TestFiles: Yup.array().of(
      Yup.object().shape({
        testType: Yup.string().required(
          'registrationForm.StepFour.required.choosetest'
        ),
        academicDocument: Yup.mixed().required(
          'registrationForm.StepFour.required.academicdoc'
        ),
        datetaken: Yup.date().required(
          'registrationForm.StepFour.required.datetaken'
        ),
        registrationNumber: Yup.number()
          .typeError('registrationForm.StepFour.required.type_error_number')
          .required('registrationForm.StepFour.required.registrationno'),
        totalscore: Yup.number()
          .typeError('registrationForm.StepFour.required.type_error_number')
          .required('registrationForm.StepFour.required.totalscore'),
      })
    ),
    SATDocument: Yup.mixed().required(
      'registrationForm.StepFour.required.satdocument'
    ),
    SATScore: Yup.string().required(
      'registrationForm.StepFour.required.satscore'
    ),
    personalResearch: Yup.array().of(
      Yup.object().shape({
        personalResearchText: Yup.string(),
        personalResearchFile: Yup.mixed().when([], {
          is: () => type === 'graduate',
          then: (schema) =>
            schema.required(
              'registrationForm.StepFour.required.personalresearchdoc'
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
      })
    ),
    ReferanceFile1: Yup.mixed().when([], {
      is: () => type === 'graduate',
      then: (schema) =>
        schema.required(
          'registrationForm.StepFour.required.firstreferenceletters'
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
    ReferanceFile2: Yup.mixed().when([], {
      is: () => type === 'graduate',
      then: (schema) =>
        schema.required(
          'registrationForm.StepFour.required.secondreferenceletters'
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
    PersonalStatement: Yup.mixed().required(
      'registrationForm.StepFour.required.personalstatementdocument'
    ),
    PersonalStatementText: Yup.string().required(
      'registrationForm.StepFour.required.personalstatementdescription'
    ),
  });
};
const getInitialValues = (storedData) => {
  if (Object.keys(storedData).length > 0) {
    return storedData;
  }

  return {
    AcademicInformation: [
      {
        academicDegreeFile: '',
        countryId: '',
        educationalInstitutionId: '',
        degreeId: '',
        graduationYear: '',
        advancedCourses: '',
        lastActivities: '',
      },
    ],
    TestFiles: [
      {
        testType: '',
        academicDocument: '',
        datetaken: '',
        registrationNumber: '',
        totalscore: '',
      },
    ],
    SATDocument: '',
    SATScore: '',
    personalResearch: [
      {
        personalResearchFile: '',
        personalResearchText: '',
      },
    ],
    ReferanceFile1: '',
    ReferanceFile2: '',
    PersonalStatement: '',
    PersonalStatementText: '',
  };
};
const items = [
  'registrationForm.StepFour.Official_transcripts',
  'registrationForm.StepFour.Official_copies',
  'registrationForm.StepFour.transcripts',
  'registrationForm.StepFour.Institution_Issues',
  'registrationForm.StepFour.Prior_attendance',
];
const optionsArray = [
  { label: 'IELTS', value: '0' },
  { label: 'TOEFL', value: '1' },
  { label: 'EMSAT', value: '2' },
  { label: 'PTE', value: '3' },
];
export { stepFourValidationSchema, getInitialValues, items, optionsArray };
