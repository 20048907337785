import React from 'react';
import styles from './MobileInput.module.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Text from '../text/Text';
import Label from '../lable/Label';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

const MobileInput = ({
  label,
  required,
  name,
  value,
  onChange,
  onBlur,
  error,
  style,
  color,
  labelTextStyle,
  styleType,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const handleChange = (phone) => {
    const sanitizedValue = DOMPurify.sanitize(phone);

    if (onChange) {
      onChange(name, sanitizedValue);
    }
  };

  const inputStyle = {
    border: error ? '1px solid red' : '',
  };
  return (
    <div className={styles['mobile-input']} style={{ ...style }}>
      {label ? (
        <Label
          label={label}
          required={required}
          color={color}
          style={labelTextStyle}
          type="phone"
        />
      ) : null}
      <PhoneInput
        country="ae"
        value={value}
        name={name}
        onChange={handleChange}
        inputClass={`${styles['input']} ${styles[styleType]}`}
        containerClass={styles['form-field']}
        onBlur={onBlur}
        inputStyle={inputStyle}
      />
      {error ? (
        <Text
          title={error}
          variant="text-regular-15"
          className={styles['text']}
          color="red"
        />
      ) : null}
    </div>
  );
};

export default MobileInput;
