import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { stepThreeValidationSchema } from './factory/formFactory';
import CustomSelect from '../../../../atoms/select/CustomSelect';
import RadioButton from '../../../../atoms/radioButton/RadioButton';
import endpoints from '../../../../../api/endpoints';
import { useGetQuery } from '../../../../../services/apiCall';
import styles from './StepThree.module.scss';
import Text from '../../../../atoms/text/Text';
import Loader from '../../../../atoms/loader/Loader';
import { useParams } from 'react-router-dom';

const optionsArray = [
  {
    label: 'registrationForm.StepThree.New_Student',
    value: 0,
  },
  {
    label: 'registrationForm.StepThree.Transfer',
    value: 1,
  },
];

function StepThree({ stepRef, activeStep }) {
  const { type } = useParams();
  const degreeTypes = type === 'undergraduate' ? [0] : [1, 2];
  const [init, setInit] = useState({});

  const apiUrl = endpoints.registrationForm.getStage3;
  const applicantId = localStorage.getItem('applicantId');
  const applicationId = localStorage.getItem('applicationId');
  const {
    data,
    error: apiError,
    isLoading,
    refetch,
  } = useGetQuery({
    apiUrl: apiUrl,
    params: [applicantId, applicationId],
  });
  const handleSubmit = (values) => {};
  useEffect(() => {
    const initialValues = {
      ProgramOfChoice: data?.application?.programOfInterest || '',
      SelectedTerm: data?.application?.term || '',
      IsVisa: data?.stage2?.studentVisa || '',
      ApplyingAs: data?.application?.applyingAs,
    };

    setInit(initialValues);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [activeStep == 2]);

  return (
    <div className={styles['Step-three']}>
      <Formik
        innerRef={stepRef}
        initialValues={init}
        validationSchema={stepThreeValidationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleBlur }) => {
          return (
            <Form>
              {isLoading && <Loader fullPage />}
              <div className={styles['input-grid']}>
                <div className={styles['input-row']}>
                  <CustomSelect
                    label="registrationForm.StepThree.Program_Of_Choice"
                    styleType="formField"
                    required
                    isDegree={true}
                    degreeTypes={degreeTypes}
                    name="ProgramOfChoice"
                    value={values.ProgramOfChoice}
                    onChange={(name, selectedOption) =>
                      setFieldValue(name, selectedOption)
                    }
                    onBlur={handleBlur}
                    error={touched.ProgramOfChoice && errors.ProgramOfChoice}
                  />
                  <CustomSelect
                    label="registrationForm.StepThree.Selected_Term"
                    styleType="formField"
                    required
                    isAcademicYear={true}
                    name="SelectedTerm"
                    value={values.SelectedTerm}
                    onChange={(name, selectedOption) =>
                      setFieldValue(name, selectedOption)
                    }
                    onBlur={handleBlur}
                    error={touched.SelectedTerm && errors.SelectedTerm}
                  />
                  <CustomSelect
                    label="registrationForm.StepThree.Is_Visa"
                    styleType="formField"
                    isSkiped={true}
                    required
                    name="IsVisa"
                    value={values.IsVisa}
                    onChange={(name, selectedOption) =>
                      setFieldValue(name, selectedOption)
                    }
                    onBlur={handleBlur}
                    error={touched.IsVisa && errors.IsVisa}
                  />
                </div>
                <div>
                  <RadioButton
                    label="registrationForm.StepThree.Applying_As"
                    name="ApplyingAs"
                    labelClass={styles.radioLabelClass}
                    value={values.ApplyingAs}
                    onOptionChange={(name, newOption) =>
                      setFieldValue(name, newOption)
                    }
                    style={{ marginTop: '1.5rem' }}
                    textStyle={{ opacity: 0.7 }}
                    direction="row"
                    options={optionsArray}
                    onBlur={handleBlur}
                  />
                  {errors?.ApplyingAs && (
                    <Text
                      title={errors?.ApplyingAs}
                      variant="text-regular-15"
                      className={styles['text']}
                      style={{ color: 'red' }}
                    />
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default StepThree;
