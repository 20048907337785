import React, { useMemo } from 'react';
import LazyVideo from '../../../../../../components/atoms/lazyVideo/LazyVideo';
import styles from './AcademySection.module.scss';
import historyVideo from '../../../../../../assets/video/spaceVideo.mp4';
import Text from '../../../../../../components/atoms/text/Text';
import { useTranslation } from 'react-i18next';
import logo1 from '../../../../../../assets/images/history/logos/logo1.png';
import logo2 from '../../../../../../assets/images/history/logos/logo2.png';
import logo3 from '../../../../../../assets/images/history/logos/logo3.png';
import logo4 from '../../../../../../assets/images/history/logos/logo4.png';

const AcademySection = ({ currentSection }) => {
  const { i18n } = useTranslation();
  const lazyVideoElement = useMemo(() => {
    return <LazyVideo src={historyVideo} isPaused={true} />;
  }, []);

  return (
    <div className={styles['section']}>
      {lazyVideoElement}

      <div className={styles['card-container']}>
        <div className={styles.card}>
          <img src={logo1} alt="police training schoool" />
          <div className={styles['text-container']}>
            <Text
              title="History.title1"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
            <Text
              title="1978"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
          </div>
        </div>
        <div className={styles.card}>
          <img src={logo2} alt="police academy" />
          <div className={styles['text-container']}>
            <Text
              title="History.title2"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
            <Text
              title="1988"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
          </div>
        </div>
        <div className={styles.card}>
          <img src={logo3} alt="police academy dubai" />
          <div className={styles['text-container']}>
            <Text
              title="History.title3"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
            <Text
              title="2001"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
          </div>
        </div>
        <div className={styles.card}>
          <img src={logo4} alt="police academy dubai" />
          <div className={styles['text-container']}>
            <Text
              title="History.title4"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
            <Text
              title="2018"
              variant="text-bold-italic-20"
              className={styles.countryName}
              color="black"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademySection;
