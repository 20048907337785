import React, { useEffect, useRef } from 'react';
import styles from './FindYourProgram.module.scss';
import ScrollReveal from 'scrollreveal';
import Text from '../../../atoms/text/Text';
import {
  ToggleList,
  ToggleListMobile,
} from '../../../molecule/ToggleList/ToggleList';
import image from '../../../../assets/images/home/find-your-program/find-your-program-LATEST.jpg';
import Backdrop from '../../../atoms/backdrop/Backdrop';
import ImageWithBackdrop from '../../../molecule/imageWithBackdrop/ImageWithBackdrop';
import { useTranslation } from 'react-i18next';

const SECTION = 2;

const FindYourProgram = ({ currentSection }) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language !== 'en';
  const textRef = useRef(null);
  const toggleRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      ScrollReveal().reveal(textRef.current, {
        delay: 100,
        duration: 500,
        easing: 'ease-in-out',
        opacity: 0,
        scale: 0.9,
        distance: '20px',
        reset: true,
      });
    }
    if (toggleRef.current) {
      ScrollReveal().reveal(toggleRef.current, {
        delay: 500,
        duration: 500,
        easing: 'ease-in-out',
        opacity: 0,
        scale: 0.9,
        distance: '20px',
        reset: true,
      });
    }
  }, []);

  const toggleData = [
    {
      id: 1,
      title: 'home.undergraduate_programs',
      items: [
        { label: 'home.bachelor_programs', route: '/degree-programs', type: 2 },
      ],
      direction: 'left',
    },
    {
      id: 2,
      title: 'home.graduate_programs',
      items: [
        { label: 'home.master_programs', route: '/degree-programs', type: 3 },
        { label: 'home.phd_programs', route: '/degree-programs', type: 4 },
      ],
      direction: 'right',
    },
  ];

  return (
    <div className={styles['Find-your-program']}>
      <ImageWithBackdrop
        src={image}
        alt="degrees"
        // backdropStyle={{ zIndex: -1 }}
        className={styles.imageClass}
        backdropStyle={{ zIndex: -1 }}
        currentSection={currentSection === SECTION}
        isAnimated
      />
      <div ref={textRef} className="slide-in">
        <Text
          title="findYourProgram.Find_Your_Program"
          variant="text-bold-italic-80"
          style={{ textTransform: 'uppercase' }}
          isLineBreak
          className={styles.text}
        />
      </div>
      <div className={styles.program}>
        <div
          ref={toggleRef}
          className={styles['toggle-container']}
          style={{ maxWidth: isRtl ? '650px' : '' }}
        >
          <ToggleList data={toggleData} />
        </div>
        <div ref={toggleRef} className={`${styles['toggle-mobile']}`}>
          <ToggleListMobile data={toggleData} />
        </div>
      </div>
    </div>
  );
};

export default FindYourProgram;
