import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { apiSlice, rtkQueryErrorLogger } from '../http/HttpRequest';
import fontSizeReducer from './globals/slice/fontSizeSlice/FontSizeSlice';
import ApplyNowSlice from './globals/slice/applyNowModalSlice/ApplyNowSlice';
import SignInSlice from './globals/slice/signInModalSlice/SignInSlice';
import StepSlice from './globals/slice/formSteps/FormSteps';
import SideModalSlice from './globals/slice/sideModalSlice/SideModalSlice';
import CustomerFaceModalSlice from './globals/slice/customerFaceModalSlice/CustomerFaceModalSlice';
import ApplyScolarshipSlice from './globals/slice/applyScolarshipModalSlice/ApplyScolarshipSlice';
import DegreeSlice from './globals/slice/degreeSlice/DegreeSlice';
import ScrollControl from './globals/slice/scrollControl/ScrollControl';
import VolumeState from './globals/slice/volume/VolumeState';
import NavigationSlice from './globals/slice/navigationSlice/NavigationSlice';
import SlideToSection from './globals/slice/slideToSection/SlideToSection';

// configure persistor
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [''],
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  fontSize: fontSizeReducer,
  applyNow: ApplyNowSlice,
  applyScolarship: ApplyScolarshipSlice,
  signIn: SignInSlice,
  customerFace: CustomerFaceModalSlice,
  steps: StepSlice,
  sideModal: SideModalSlice,
  degreeData: DegreeSlice,
  scrollControl: ScrollControl,
  volume: VolumeState,
  navigation: NavigationSlice,
  slideTo: SlideToSection,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware
    ),
  // .concat(process.env.REACT_APP_ENV !== 'PROD' ? rtkQueryErrorLogger : []),
});

export const persistor = persistStore(store);

export const setupListeners = (dispatch) => {
  apiSlice.internalActions.enqueueRequest.subscribe(() => {
    dispatch(apiSlice.internalActions.updateQueryDataTimestamp());
  });
};
