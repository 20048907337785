import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import endpoints from '../api/endpoints';

const baseQuery = fetchBaseQuery({
  baseUrl: endpoints.baseUrl,
  prepareHeaders: async (headers) => {
    // headers.set('Content-Security-Policy', 'default-src "self"');
    // headers.set('X-Frame-Options', 'DENY');
    // headers.set('X-Content-Type-Options', 'nosniff');
    // headers.set('Referrer-Policy', 'no-referrer');
    // headers.set('Permissions-Policy', 'geolocation=(self)');

    // Content Security Policy (CSP) - Controls the resources the browser is allowed to load
    headers.set(
      'Content-Security-Policy',
      'default-src "self"; script-src "self" ; object-src "none"; frame-ancestors "none";'
    );

    // X-Frame-Options - Prevents the site from being embedded in iframes
    headers.set('X-Frame-Options', 'DENY');

    // X-Content-Type-Options - Prevents MIME type sniffing
    headers.set('X-Content-Type-Options', 'nosniff');

    // Referrer-Policy - Controls how much referrer information should be included with requests
    headers.set('Referrer-Policy', 'no-referrer');

    // Permissions-Policy - Controls which features and APIs can be used in the browser
    headers.set(
      'Permissions-Policy',
      'geolocation=(self), microphone=(), camera=(), autoplay=()'
    );

    // Strict-Transport-Security (HSTS) - Enforces secure (HTTPS) connections to the server
    headers.set(
      'Strict-Transport-Security',
      'max-age=31536000; includeSubDomains; preload'
    );

    // X-XSS-Protection - Enables the cross-site scripting filter built into most browsers
    headers.set('X-XSS-Protection', '1; mode=block');

    // Expect-CT - Ensures that any misissued certificates are quickly discovered
    headers.set('Expect-CT', 'max-age=86400, enforce');

    // Cache-Control - Controls how documents are cached, stored, and revalidated by browsers
    headers.set(
      'Cache-Control',
      'no-store, no-cache, must-revalidate, proxy-revalidate'
    );

    return headers;
  },
});

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  refetchOnReconnect: true,
  refetchOnFocus: true,
});

export const rtkQueryErrorLogger = () => (next) => (action) => {
  return next(action);
};
