import React from 'react';
import volume from '../../../../assets/icons/volume.svg';
import mute from '../../../../assets/icons/mute.svg';
import brightness from '../../../../assets/icons/brightness.svg';
import night from '../../../../assets/icons/night.svg';

const fontsData = [
  {
    id: 1,
    mode: 'small',
    size: '20px',
    content: 'A',
    title: 'accessibility.small',
  },
  {
    id: 2,
    mode: 'medium',
    size: '28px',
    content: 'A',
    title: 'accessibility.medium',
  },
  {
    id: 3,
    mode: 'large',
    size: '38px',
    content: 'A',
    title: 'accessibility.big',
  },
  {
    id: 4,
    mode: 'bigger',
    size: '53px',
    content: 'A',
    title: 'accessibility.bigger',
  },
];

const volumeData = [
  {
    size: '20px',
    content: <img src={volume} />,
    subtitle: 'Volume On',
    mode: 'on',
    title: 'accessibility.volume_on',
  },
  {
    size: '28px',
    content: <img src={mute} />,
    subtitle: 'Volume Off',
    mode: 'off',
    title: 'accessibility.volume_off',
  },
];
const webMode = [
  {
    size: '20px',
    content: <img src={brightness} />,
    subtitle: 'Day Colors',
    title: 'accessibility.day_mode',
  },
  {
    size: '28px',
    content: <img src={night} />,
    subtitle: 'Night Colors',
    title: 'accessibility.night_mode',
  },
];

export { webMode, fontsData, volumeData };
