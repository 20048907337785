import React, { useEffect, useMemo, useRef } from 'react';
import styles from './OurGraduation.module.scss';
import historySvg from '../../../../../../assets/icons/history/history-circle.svg';
import historyVideo from '../../../../../../assets/video/graduation.mp4';
import Text from '../../../../../atoms/text/Text';
import LazyVideo from '../../../../../atoms/lazyVideo/LazyVideo';
import Backdrop from '../../../../../atoms/backdrop/Backdrop';
import useAnimation from '../../../../../../app/hooks/useAnimation';

const SECTION_INDEX = 1;

const OurGraduation = ({
  before,
  currentSection,
  videoUrl,
  isAnimated = true,
  title,
  singleWord,
}) => {
  const historySectionRef = useRef(null);
  const historySvgElementRef = useRef(null);

  const refs = [historySectionRef, historySvgElementRef];
  useAnimation(currentSection, SECTION_INDEX, refs, styles);

  const lazyVideoElement = useMemo(() => {
    return (
      <LazyVideo
        src={videoUrl ?? historyVideo}
        isPaused={currentSection === SECTION_INDEX ? false : true}
      />
    );
  }, [currentSection === SECTION_INDEX]);

  return (
    <div ref={historySectionRef} className={styles['our-history']}>
      {lazyVideoElement}
      <Backdrop />
      <div className={styles.container}>
        <div
          ref={isAnimated ? historySvgElementRef : null}
          className={styles['history-svg']}
          style={{ marginTop: !isAnimated ? '10rem' : '' }}
        >
          <img src={historySvg} />
          <div className={styles['text-container']}>
            <Text
              title={title ?? 'About_The_Academy.Graduation'}
              variant="text-bold-italic-40"
              style={{ textTransform: 'uppercase' }}
              className={styles.text}
            />
            {!singleWord ? (
              <Text
                title="About_The_Academy.over_the_years"
                variant="text-bold-italic-40"
                style={{ textTransform: 'uppercase' }}
                className={styles.text}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGraduation;
