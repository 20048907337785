import React, { useEffect } from 'react';
import styles from './HeadingWithSubtext.module.scss';
import Text from '../../atoms/text/Text';
import { useNavigate } from 'react-router-dom';
import he from 'he';
import def from '../../../assets/icons/admissionRequirments/Balance.svg';
const HeadingWithSubtext = ({
  title,
  subComponents,
  subtext,
  variant = 'default',
  className,
  grid,
  isSeparated,
  extraText,
  component,
  ishtml = false,
  onClick,
  subtextStyle,
  maxLines,
  isImages,
  isLineBreak = true,
  isRtl,
}) => {
  const navigate = useNavigate();
  const titleVariant =
    variant === 'small' ? 'text-bold-italic-25-green' : 'text-bold-italic-40';
  const subtextVariant =
    variant === 'small' ? 'text-regular-16-black' : 'text-regular-20';

  const renderSubtext = () => {
    if (ishtml) {
      if (typeof subtext === 'string') {
        return (
          <Text
            variant={subtextVariant}
            component={
              <div
                style={{ lineHeight: 2 }}
                dangerouslySetInnerHTML={{ __html: he.decode(subtext) }}
              />
            }
            style={subtextStyle}
          />
        );
      } else {
        return null;
      }
    }

    if (Array.isArray(subtext)) {
      return subtext.map((item, index) => (
        <Text
          key={index}
          title={item}
          variant={subtextVariant}
          className={styles['text']}
          style={{ ...subtextStyle, lineHeight: 2 }}
        />
      ));
    } else if (typeof subtext === 'string') {
      return (
        <Text
          title={subtext}
          variant={subtextVariant}
          className={styles['text']}
          style={{ ...subtextStyle, lineHeight: 2 }}
          maxLines={maxLines}
        />
      );
    } else {
      return null;
    }
  };
  // marginLeft: isRtl ? 'auto' : '10%' , marginRight: isRtl ? '10%' : 'auto'
  return (
    <div
      className={`${styles['heading']} ${className ?? ''}`}
      style={{ gap: subtext ? '1.1rem' : '1.1rem' }}
    >
      {title ? (
        <Text
          title={title}
          variant={titleVariant}
          style={{ textTransform: 'uppercase' }}
          isLineBreak={isLineBreak}
        />
      ) : null}
      {component}
      {subComponents ? (
        <>
          {subtext && (
            <Text
              title={subtext}
              variant={subtextVariant}
              className={styles['text']}
              style={{ lineHeight: 2 }}
            />
          )}
          <div className={styles[grid]}>
            {subComponents.map((val, index) => (
              <div
                key={index}
                className={`${styles['heading-card']} ${
                  onClick ? styles.cardHover : ''
                }`}
                onClick={onClick ? () => navigate(val.route) : undefined}
              >
                <div
                  className={`${styles['svg']} ${
                    isImages ? styles.images : ''
                  }`}
                >
                  {val.svg ? (
                    <img
                      src={val.svg ?? def}
                      alt={''}
                      onError={(e) => (e.target.style.display = 'none')}
                    />
                  ) : null}
                </div>
                <div className={styles['text-container']}>
                  {isSeparated && (
                    <div className={styles['dashes-separator']}></div>
                  )}
                  <div className={styles['text']}>
                    <Text
                      title={val.text}
                      variant={subtextVariant}
                      style={{ lineHeight: 2 }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          {renderSubtext()}
          {extraText}
        </>
      )}
    </div>
  );
};

const Card = ({
  className,
  svg,
  text,
  title,
  variant,
  imageStyle,
  subtextStyle,
}) => {
  const titleVariant =
    variant === 'small' ? 'text-bold-italic-40-black' : 'text-regular-bold-40';
  const subtextVariant =
    variant === 'small' ? 'text-regular-16-black' : 'text-bold-20';

  return (
    <div className={`${styles['card']} ${className}`}>
      <div className={styles['svg']}>
        <img src={svg} className={imageStyle} alt={title} />
      </div>
      <div className={styles['text-container']}>
        <Text title={title} variant={titleVariant} />
        <Text
          title={text}
          variant={subtextVariant}
          textContainerStyle={{ textAlign: 'center' }}
          style={subtextStyle}
        />
      </div>
    </div>
  );
};

export { Card, HeadingWithSubtext };
