import React from 'react';
import FindYourProgram from '../../../components/templates/homePageSections/findYourProgram/FindYourProgram';
import Home from '../../../components/templates/homePageSections/home/Home';
import Latest from '../../../components/templates/homePageSections/latest/Latest';
import Degrees from '../../../components/templates/homePageSections/degrees/Degrees';
import CampusLife from '../../../components/templates/homePageSections/campusLife/CampusLife';
import AboutTheAcademy from '../../../components/templates/homePageSections/aboutTheAcademy/AboutTheAcademy';
import UniversalMap from '../../../components/templates/homePageSections/universalMap/UniversalMap';
import AcademyInNumber from '../../../components/templates/homePageSections/academyInNumber/AcademyInNumber';
import Testimonials from '../../../components/templates/homePageSections/testimonials/Testimonial';
import Footer from '../../../components/templates/homePageSections/footer/Footer';

const sectionComponents = [
  Home,
  Latest,
  FindYourProgram,
  Degrees,
  CampusLife,
  AboutTheAcademy,
  UniversalMap,
  AcademyInNumber,
  Testimonials,
  Footer,
];

export default sectionComponents;
