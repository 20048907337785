import React, { useEffect } from 'react';
import styles from './StepSix.module.scss';
import Text from '../../../../atoms/text/Text';
import Separator from '../../../../atoms/separator/Separator';
import dubai from '../../../../../assets/icons/digital dubai payment gateway (1).svg';
import transaction from '../../../../../assets/icons/transactionDetails.svg';
import animationData from '../../../../../assets/gifs/animation_lo2urm4w.json';
import Lottie from 'lottie-web';

function StepSix() {
  useEffect(() => {
    const options = {
      container: document.querySelector('.lottie-container'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    };

    const animation = Lottie.loadAnimation(options);

    return () => {
      animation.destroy();
    };
  }, []);
  return (
    <div className={styles['Step-six']}>
      {/* <div className={styles['container']}> */}
      <div className={styles['payment-container']}>
        <div className={styles['payment']}>
          <Text
            title="registrationForm.StepSix.PaymentStep"
            variant="text-bold-italic-20-black"
            styles={{ textTransform: 'uppercase' }}
          />
          <Text
            title="registrationForm.StepSix.Redirect_Sentence"
            variant="text-bold-italic-16-black"
            style={{ opacity: '0.7' }}
          />
        </div>
        <div className={styles['dubai-numbers']}>
          <img src={dubai} />
          <Text
            title="registrationForm.StepSix.Pay_Now_Through"
            variant="text-bold-italic-16-black"
            style={{ opacity: '0.7' }}
          />
          <div
            className="lottie-container"
            style={{ width: '200px', height: '200px' }}
          />
        </div>
      </div>
      <Separator direction="vertical" />
      <div className={styles['invoice-container']}>
        <img src={transaction} />
        <div className={styles['invoice']}>
          <div className={styles['details']}>
            <Text
              title="registrationForm.StepSix.Payment_Invoice"
              variant="text-bold-italic-20-black"
              style={{
                textTransform: 'uppercase',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingTop: '2rem',
                color: '#1B224C',
              }}
            />
            <div className={styles['amount']}>
              <div className={styles['info']}>
                <Text
                  title="registrationForm.StepSix.Application_Fee"
                  variant="text-regular-20-black"
                  style={{
                    color: '#1B224C',
                  }}
                />
                <Text
                  title="220.00 AED"
                  variant="text-bold-italic-20-black"
                  style={{
                    color: '#1B224C',
                  }}
                />
              </div>
              <div className={styles['info']}>
                <Text
                  title="registrationForm.StepSix.VAT"
                  variant="text-regular-20-black"
                  style={{
                    color: '#1B224C',
                  }}
                />
                <Text
                  title="10.00 AED"
                  variant="text-bold-italic-20-black"
                  style={{
                    color: '#1B224C',
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles['footer']}>
            <div className={styles['info']}>
              <Text
                title="registrationForm.StepSix.Application_Fee"
                variant="text-regular-20-black"
                style={{
                  color: '#1B224C',
                }}
              />
              <Text
                title="230.00 AED"
                variant="text-bold-italic-30-black"
                style={{
                  color: '#1B224C',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default StepSix;
