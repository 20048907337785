import * as Yup from 'yup';
const stepFiveValidationSchema = Yup.object().shape({
  acceptedbyAcademy: Yup.bool().oneOf(
    [true],
    'registrationForm.StepFive.Program_Information.required.acceptedbyAcademy'
  ),
  acceptallnotices: Yup.bool().oneOf(
    [true],
    'registrationForm.StepFive.Program_Information.required.acceptallnotices'
  ),
  agreementacknowledged: Yup.bool().oneOf(
    [true],
    'registrationForm.StepFive.Program_Information.required.agreementacknowledged'
  ),
  catalogread: Yup.bool().oneOf(
    [true],
    'registrationForm.StepFive.Program_Information.required.catalogread'
  ),
});

const getInitialValues = (storedData) => {
  if (Object.keys(storedData).length > 0) {
    return storedData;
  }

  return {
    acceptedbyAcademy: false,
    acceptallnotices: false,
    agreementacknowledged: false,
    catalogread: false,
  };
};

const PROGRAM_INFORMATION = [
  'registrationForm.StepFive.Program_Information.regulations_policies',
  'registrationForm.StepFive.Program_Information.certify',
];
const IMPORTANT_NOTICES = [
  'registrationForm.StepFive.Important_Notices.regulations_policies',
  'registrationForm.StepFive.Important_Notices.certify',
];
export {
  PROGRAM_INFORMATION,
  IMPORTANT_NOTICES,
  stepFiveValidationSchema,
  getInitialValues,
};
