import React, { useEffect, useState } from 'react';
import styles from './DatePicker.module.scss';
import Label from '../lable/Label';
import { useTranslation } from 'react-i18next';
import Text from '../text/Text';
import DOMPurify from 'dompurify';

const DatePickerFormField = ({
  label,
  name,
  required,
  value,
  onChange,
  onBlur,
  error,
  lableStyle,
  disableFuture = false,
}) => {
  const [selectedDate, setSelectedDate] = useState(value || '');
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';

  const today = new Date().toISOString().split('T')[0];

  const handleChange = (event) => {
    const newValue = event.target.value;

    const [year, month, day] = newValue.split('-');
    if (year.length > 4) {
      return;
    }

    const sanitizedValue = DOMPurify.sanitize(newValue);

    setSelectedDate(sanitizedValue);
    if (onChange) {
      onChange(name, sanitizedValue);
    }
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <div className={styles['date-picker-field']}>
      {label ? (
        <Label label={label} required={required} style={lableStyle} />
      ) : null}
      <input
        id="datePicker"
        type="date"
        className={styles['input']}
        name={name}
        value={selectedDate ?? null}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder=""
        max={disableFuture ? today : ''}
        style={{ border: error ? '1px solid red' : ' ' }}
      />
      {error ? (
        <Text
          title={error}
          variant="text-regular-15"
          className={styles.error}
        />
      ) : null}
    </div>
  );
};

export { DatePickerFormField };
