import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './FlipBookPDF.module.scss';
import HTMLFlipBook from 'react-pageflip';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';
import Loader from '../../components/atoms/loader/Loader';
import leftArrow from '../../assets/icons/carousel/left-arrow-head (1).svg';
import rightArrow from '../../assets/icons/carousel/left-arrow-head.svg';

const FlipBookPDF = () => {
  const location = useLocation();
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [singlePage, setSinglePage] = useState(true);
  const [error, setError] = useState(null);
  const flipBook = useRef(null);

  const getPdfUrlFromParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get('pdfUrl');
  };

  const pdfUrl = getPdfUrlFromParams();

  useEffect(() => {
    if (pdfUrl) {
      const loadPdf = async () => {
        try {
          const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
          const numPages = pdf.numPages;
          const loadedPages = [];
          for (let i = 1; i <= numPages; i++) {
            const page = await pdf.getPage(i);
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport }).promise;
            loadedPages.push(canvas.toDataURL());
          }
          setPages(loadedPages);
          setLoading(false);
        } catch (err) {
          console.error('Error loading PDF:', err);
          setError('Failed to load PDF. Please check the file and try again.');
          setLoading(false);
        }
      };
      loadPdf();
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (currentPage === 0 || currentPage === pages.length - 1) {
      setSinglePage(true);
    } else {
      setSinglePage(false);
    }
  }, [currentPage, pages.length]);

  if (!pdfUrl) {
    return <p>No PDF URL provided.</p>;
  }

  if (loading) {
    return (
      <div className={styles['loading-container']}>
        <Loader fullPage />
        <p>Loading flipbook...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles['error-container']}>
        <p>{error}</p>
      </div>
    );
  }

  const handleNextPage = () => {
    if (flipBook.current) {
      flipBook.current.pageFlip().flipNext();
    }
  };

  const handlePrevPage = () => {
    if (flipBook.current) {
      flipBook.current.pageFlip().flipPrev();
    }
  };

  const onFlip = (e) => {
    setCurrentPage(e.data);
  };

  return (
    <div className={styles['flipbook-container']}>
      <button onClick={handlePrevPage} className={styles['prev-button']}>
        <img src={rightArrow} alt="Previous page" />
      </button>
      <HTMLFlipBook
        width={window.innerWidth > 600 ? 600 : window.innerWidth - 20}
        height={window.innerHeight > 800 ? 800 : window.innerHeight - 20}
        ref={flipBook}
        startPage={0}
        onFlip={onFlip}
        className={styles['flipbook']}
        showCover
      >
        {pages.map((page, index) => (
          <div key={index} className={styles['page']}>
            <img
              src={page}
              alt={`Page ${index}`}
              className={styles['page-image']}
            />
          </div>
        ))}
      </HTMLFlipBook>
      <button onClick={handleNextPage} className={styles['next-button']}>
        <img src={leftArrow} alt="Next page" />
      </button>
    </div>
  );
};

export default FlipBookPDF;
