import React, { useEffect, useState } from 'react';
import styles from './HappyCustomerModal.module.scss';
import Modal from '../../hoc/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setApplyNowState } from '../../../app/globals/slice/applyNowModalSlice/ApplyNowSlice';
import { useNavigate } from 'react-router-dom';
import { setCustomerFaceState } from '../../../app/globals/slice/customerFaceModalSlice/CustomerFaceModalSlice';
import GreenFace from '../../../assets/icons/happyMeter/greenFace.svg';
import yellowFace from '../../../assets/icons/happyMeter/yellowFace.svg';
import redFace from '../../../assets/icons/happyMeter/redFace.svg';
import logoMeter from '../../../assets/images/happinessLogo.png';
import Text from '../../atoms/text/Text';
import { usePostMutation } from '../../../services/apiCall';
import endpoints from '../../../api/endpoints';
import { errorMessage, successMessage } from '../../atoms/toast/Toast';

const HappyCustomerModal = ({ fontSize }) => {
  const isOpen = useSelector((state) => state.customerFace.isOpen);
  const dispatch = useDispatch();
  const [post, { isLoading, isSuccess, isError, error }] = usePostMutation();

  const closeModal = () => {
    dispatch(setCustomerFaceState(false));
  };

  const recordHappiness = async (id) => {
    const apiUrl = endpoints.happinessMeter.recordHappiness;
    let payload = { apiUrl: apiUrl, params: [id] };
    try {
      await post(payload).unwrap();
      successMessage();
    } catch (error) {
      errorMessage();
    }
  };
  useEffect(() => {
    if (isSuccess) {
      window.isHoveringCalendar = false;
      dispatch(setCustomerFaceState(false));
    }
  }, [isSuccess]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      dialogClassName={styles.modalClass}
    >
      <div className={styles['customer-modal']}>
        <div className={styles['customer-submodal']}>
          <div className={styles.logo}>
            <img src={logoMeter} />
          </div>
          <div className={styles['grid-3']}>
            <div className={styles.face} onClick={() => recordHappiness(1)}>
              <img src={redFace} />
            </div>
            <div className={styles.face} onClick={() => recordHappiness(2)}>
              <img src={yellowFace} />
            </div>
            <div className={styles.face} onClick={() => recordHappiness(3)}>
              <img src={GreenFace} />
            </div>
          </div>
          <div className={styles.text}>
            <Text
              title="home.How_was_your_experience"
              variant="text-bold-25-black"
              color="#281E4C"
            />
          </div>
        </div>
        <div>
          <p className={styles.subText}>
            A <span>Digital Dubai</span> initiative
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default HappyCustomerModal;
