import * as Yup from 'yup';

const stepTwoValidationSchema = Yup.object().shape({
  Passport_File: Yup.mixed().required(
    'registrationForm.StepTwo.required.Passport_File'
  ),
  EmiratesId_File: Yup.mixed().required(
    'registrationForm.StepTwo.required.EmiratesId_File'
  ),
  FamilyBook_File: Yup.mixed().required(
    'registrationForm.StepTwo.required.FamilyBook_File'
  ),
  ProfilePicture_File: Yup.mixed().required(
    'registrationForm.StepTwo.required.ProfilePicture_File'
  ),
  CV_File: Yup.mixed().required('registrationForm.StepTwo.required.CV_File'),
  NationalService_Files: Yup.array().of(
    Yup.object().shape({
      nationalService_File: Yup.mixed(),
      index: Yup.number().typeError(
        'registrationForm.StepFour.required.type_error_number'
      ),
    })
  ),
});

const getInitialValues = (storedSteps) => {
  if (Object.keys(storedSteps).length > 0) {
    return storedSteps;
  } else {
    return {
      Passport_File: '',
      EmiratesId_File: '',
      FamilyBook_File: '',
      ProfilePicture_File: '',
      CV_File: '',
      NationalService_Files: [
        {
          nationalService_File: '',
          index: 0,
        },
      ],
    };
  }
};

export { stepTwoValidationSchema, getInitialValues };
