import React, { useState } from 'react';
import styles from './BoxButtonGroup.module.scss';
import BoxButton from '../../atoms/boxButton/BoxButton';
import Text from '../../atoms/text/Text';

const BoxButtonGroup = ({ data, title, onItemClick, state }) => {
  const [selectedItem, setSelectedItem] = useState(state);

  const handleItemClick = (mode) => {
    setSelectedItem(mode);
    onItemClick(mode);
  };

  return (
    <div className={styles['groupBox-container']}>
      <Text
        variant="text-bold-italic-16"
        title={title}
        style={{ textTransform: 'uppercase' }}
      />
      <div className={styles['group-box']}>
        {data?.map((item, index) => (
          <BoxButton
            key={index}
            {...item}
            selected={item.mode === selectedItem}
            onClick={handleItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default BoxButtonGroup;
