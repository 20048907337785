import * as Yup from 'yup';

import {
  emailRegex,
  phoneRegex,
  nameRegex,
  numberRegex,
  emiratesIdRegex,
} from '../../../../../../Validation';

const stepOneValidationSchema = (isOther, employed) =>
  Yup.object().shape({
    // TitleId: Yup.string().required('registrationForm.StepOne.required.TitleId'),
    Email: Yup.string()
      .email('registrationPage.invalid_email')
      .matches(emailRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.Email'),
    StreetName: Yup.string()
      .matches(nameRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.street_name'),
    FullName_AR: Yup.string()
      .matches(nameRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.full_name_ar'),
    FullName_EN: Yup.string()
      .matches(nameRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.full_name_en'),
    PlaceOfBirth: Yup.string()
      .matches(nameRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.place_of_birth'),
    EmiratesId: Yup.string()
      .matches(emiratesIdRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.nationality_emirates_id'),
    // Address1: Yup.string().required(
    //   'registrationForm.StepOne.required.Address1'
    // ),
    CityState: Yup.string().required(
      'registrationForm.StepOne.required.CityState'
    ),
    // ZipCode: Yup.string()
    //   .matches(/^\d{5}$/, 'registrationForm.StepOne.required.zip_min')
    //   .required('registrationForm.StepOne.required.ZipCode'),
    DOB: Yup.date().required('registrationForm.StepOne.required.DOB'),
    Gender: Yup.string().required('registrationForm.StepOne.required.Gender'),
    Nationality: Yup.mixed().required(
      'registrationForm.StepOne.required.Nationality'
    ),
    PreferedWayToCommunicate: Yup.mixed().required(
      'registrationForm.StepOne.required.PreferedWayToCommunicate'
    ),
    HearAboutUsId: Yup.mixed().required(
      'registrationForm.StepOne.required.HearAboutUsId'
    ),
    ApplicantTelephone: Yup.string()

      .matches(
        phoneRegex,
        'registrationForm.StepOne.required.ApplicantTelephone'
      ),
    // RequireAssistance: Yup.mixed().required(
    //   'registrationForm.StepOne.required.RequireAssistance'
    // ),
    Pobox: Yup.string()
      .min(6, 'registrationForm.StepOne.minLengthpo')
      .max(15, 'registrationForm.StepOne.maxLengthpo')
      .matches(/^[0-9]{6,}$/, 'registrationForm.StepOne.pattern'),
    Country: Yup.string().required('registrationForm.StepOne.required.Country'),
    IsEmployed: Yup.boolean().required(
      'registrationForm.StepOne.required.IsEmployed'
    ),
    IsPeopleOfDetermination: Yup.boolean().required(
      'registrationForm.StepOne.required.IsPeopleOfDetermination'
    ),
    Authorize_FatherName: Yup.string().required(
      'registrationForm.StepOne.required.Authorize_FatherName'
    ),
    Authorize_MothersName: Yup.string().required(
      'registrationForm.StepOne.required.Authorize_MothersName'
    ),
    // Authorize_OthersName: Yup.string().required(
    //   'registrationForm.StepOne.required.Authorize_OthersName'
    // ),
    Authorize_Address: Yup.string().required(
      'registrationForm.StepOne.required.Authorize_Address'
    ),
    Authorize_Email: Yup.string()
      .email('Invalid family email address')
      .matches(emailRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.Authorize_Email'),
    Mobile: Yup.string()
      .matches(phoneRegex, 'validation.invalid_format')
      .required('registrationForm.StepOne.required.Mobile'),
    Authorize_Telephone: Yup.string(),
    ApplicantId: Yup.string(),
    ApplicationId: Yup.string(),
    AuthorizeToReleaseRecord: Yup.boolean().oneOf(
      [true],
      'registrationForm.StepOne.required.AuthorizeToReleaseRecord'
    ),
    EmploymentType: Yup.string().when([], {
      is: () => employed === true,
      then: (schema) =>
        schema.required('validation.employment_type_is_required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    OtherEmployment: Yup.string().when([], {
      is: () => isOther === true,
      then: (schema) => schema.required('validation.other_required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export { stepOneValidationSchema };
