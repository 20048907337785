import React from 'react';
import styles from './AvatarGroup.module.scss';
import Text from '../text/Text';

const AvatarGroup = ({ images, fontSize }) => {
  return (
    <div className={styles['profile-group']}>
      {images.slice(0, 2).map((src, index) => (
        <img
          key={index}
          alt={`Profile ${index + 1}`}
          src={src}
          className={styles['profile-image']}
        />
      ))}

      {images.length > 2 && (
        <div className={styles['remaining-count']}>
          {/* +{images.length - 2}{' '} */}
          <Text
            title="50+"
            variant="text-bold-italic-30"
            style={{ textTransform: 'uppercase', textAlign: 'center' }}
            fontSize={fontSize}
          />
        </div>
      )}
    </div>
  );
};

export default AvatarGroup;
