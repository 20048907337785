import React, { useState } from 'react';
import styles from './Home.module.scss';
import Carousel from '../../../molecule/carousel/Carousel';
import { CarouselData } from './homeCarousel/data';
const SECTION_ID = 0;
const SECTION_ID_NEXT = 1;

const Home = ({ currentSection }) => {
  const [startX, setStartX] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };
  const loadCarousel =
    currentSection === SECTION_ID || currentSection === SECTION_ID_NEXT;
  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const swipeLength = startX - endX;

    if (swipeLength > 50) {
      setCurrentSlide((prev) => (prev + 1) % CarouselData.length);
    } else if (swipeLength < -50) {
      setCurrentSlide(
        (prev) => (prev - 1 + CarouselData.length) % CarouselData.length
      );
    }
  };

  return (
    <div
      className={styles.home}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {loadCarousel && (
        <Carousel
          components={CarouselData.map((SlideComponent, index) => (
            <SlideComponent
              key={index}
              currentSection={currentSection}
              slideIndex={index}
            />
          ))}
          activeIndex={currentSlide}
          dots={styles.homeDots}
          timeout={10000}
        />
      )}
    </div>
  );
};

export default Home;
