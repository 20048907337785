import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageToggle.module.scss';

function LanguageToggle({ className }) {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    const languageInStorage = localStorage.getItem('language');
    if (languageInStorage) {
      i18n.changeLanguage(languageInStorage);
      setSelectedLanguage(languageInStorage);
    }
  }, [i18n]);

  const toggleLanguage = () => {
    const newLanguage = selectedLanguage === 'en' ? 'ar' : 'en';
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <div className={`${styles['language-toggle']} ${className}`}>
      <div className={styles['toggle']}>
        <button
          className={`${styles['language-button']} ${
            selectedLanguage === 'en' ? styles.active : ''
          }`}
          onClick={toggleLanguage}
        >
          {t('EN')}
        </button>
        <button
          className={`${styles['language-button']} ${
            selectedLanguage === 'ar' ? styles.active : ''
          } ${styles['ar']}`}
          onClick={toggleLanguage}
        >
          {t('عربي')}
        </button>
      </div>
    </div>
  );
}

export default LanguageToggle;
