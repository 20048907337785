import { createSlice } from '@reduxjs/toolkit';

const ApplyNowSlice = createSlice({
  name: 'applyNow',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setApplyNowState: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setApplyNowState } = ApplyNowSlice.actions;
export default ApplyNowSlice.reducer;
