import React from 'react';
import styles from './SlideTwo.module.scss';
import mastersBoy from '../../../../../../assets/icons/students-degrees/Mastersboy.png';
import mastersGirl from '../../../../../../assets/icons/students-degrees/Mastersgirl.png';
import Text from '../../../../../atoms/text/Text';
import degreeStyles from '../../Degrees.module.scss';
import MajorCard from '../../../../../molecule/bachelorCard/MajorCard';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const SlideTwo = () => {
  const { i18n } = useTranslation();
  const isPhoneScreen = useMediaQuery({ maxWidth: 767 });

  const currentLanguage = i18n.language;

  const degreeData = useSelector((state) => state?.degreeData?.degreeData);
  const bachelorsData = degreeData?.items
    ?.filter((item) => item?.degreeType === 1)
    .slice(0, 8);
  const displayedData = isPhoneScreen
    ? bachelorsData?.slice(0, 6)
    : bachelorsData;
  return (
    <div className={styles['slide-two']}>
      <img className={degreeStyles.student1} src={mastersBoy} loading="lazy" />
      <img className={degreeStyles.student2} src={mastersGirl} loading="lazy" />

      <div className={styles['content']}>
        <Text
          title="home.master_degree"
          variant="text-bold-italic-50"
          style={{ textTransform: 'uppercase', marginBottom: '50px' }}
          textContainerStyle={{ textAlign: 'center' }}
          isLineBreak
        />
        <div className={styles['grid']}>
          {displayedData?.map((item) => (
            <MajorCard
              key={item.degreeId}
              id={item.degreeId}
              titleEN={item.degreeNameEn}
              className={styles.item}
              title={
                currentLanguage === 'en' ? item.degreeNameEn : item.degreeNameAr
              }
              isCadet={item.forAllOrCadets}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SlideTwo;
