import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import styles from './Loader.module.scss';
import loaderSvg from '../../../assets/gifs/Loader.json';

const Loader = ({ fullPage = false, style, background }) => {
  const LoaderContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: LoaderContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loaderSvg,
    });
    if (fullPage) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (fullPage) {
        document.body.style.overflow = '';
      }
      anim.destroy();
    };
  }, []);

  const loaderClass = fullPage
    ? `${styles['loader-container']} ${styles['full-page']} ${background ? styles['background'] : ''}`
    : `${styles['loader-container']} ${styles['in-container']}`;

  return (
    <div className={loaderClass}>
      <div className={styles.loader} style={style} ref={LoaderContainer}></div>
    </div>
  );
};

export default Loader;
