import React, { useState } from 'react';
import styles from './RoundedButton.module.scss';
import Text from '../text/Text';

const RoundedButton = ({
  isbordered,
  svg,
  onClick,
  variant,
  title,
  animated,
  classname,
  buttonClass,
  maxline,
}) => {
  let svgStyle = isbordered
    ? `${styles['svg-container']} ${styles['bordered-class']} ${classname} ${!animated ? styles.unanimated : ''}`
    : `${styles['svg-container']} ${classname} ${!animated ? styles.unanimated : ''}`;

  if (animated) {
    svgStyle = `${svgStyle} ${styles['animated-class']}`;
  }

  return svg ? (
    <div className={svgStyle} onClick={onClick}>
      <img src={svg} alt="" />
    </div>
  ) : (
    <div
      className={`${styles['text-container']} ${buttonClass}`}
      onClick={onClick}
    >
      <Text
        onClick={onClick}
        title={title}
        variant={variant}
        style={{ cursor: 'pointer' }}
        className={classname}
        maxLines={maxline}
      />
    </div>
  );
};

export default RoundedButton;
