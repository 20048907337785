import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Fees.module.scss';
import Text from '../../../../components/atoms/text/Text';
import { FeesTable } from '../../../../components/molecule/table/Table';

const data = [
  {
    title: 'Major.annual_fees',
    major: [
      {
        name: 'Major.The_Arabic_language',
        type: 'Major.General',
        credits: 3,
      },
      {
        name: 'Major.Introduction_to_the_study_of_law',
        type: 'Major.Specialized',
        credits: 4,
      },
      {
        name: 'Major.Introduction_to_the_study_of_Islamic_culture_and_Islamic_jurisprudence',
        type: 'Major.General',
        credits: 3,
      },
      {
        name: 'Major.Studies_in_Emirati_society',
        type: 'Major.Specialized',
        credits: 3,
      },
    ],
  },
];
const Fees = ({ fees, knowledge }) => {
  const fontSize = useSelector((state) => state.fontSize);

  return (
    <div className={styles['fees-financing-container']}>
      <Text
        title="Major.Fees_And_Financing"
        variant="text-bold-italic-25-green"
        style={{ textTransform: 'uppercase' }}
        isLineBreak
      />
      <div className={styles['table']}>
        <Text
          title="Major.Regular_Fees"
          variant="text-regular-18-black"
          style={{ textTransform: 'uppercase' }}
          className={styles['text']}
        />
        <FeesTable data={data} fees={fees} knowledge={knowledge} />
        {/* <HeadingWithSubtext

          variant="small"
          title="Major.Fees_Policy"
          subtext=""
          className={styles['heading-classname']}
          extraText={
            <div className={styles['inner-link']}>
              <Text
                title="Major.IN_THE_LINK_BELOW"
      
                variant="text-regular-16-black"
                style={{ textTransform: 'uppercase' }}
              />
              <NavLink></NavLink>
              <div>
                <InnerButton title="Major.Apply_For_Scholarship" />
              </div>
            </div>
          }
        /> */}
      </div>
    </div>
  );
};

export default Fees;
